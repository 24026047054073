/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import {
    Box,
    useDisclosure
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the TwoColumnHathaTeachersBlock Section component
 *
 * @param {object} sectionContent - Data for section
 * @param sectionContent.section
 * @param {string} region - Region for the section
 * @param {string} my - Margin from top and bottom
 * @param {string} lang - Language for the section
 * @param sectionContent.region
 * @param sectionContent.lang
 * @param sectionContent.my
 */
const TwoColumnHathaTeachersBlock = ({ section, region, lang, my }) => {
  const [checked, setChecked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      display="flex"
      width="full"
      my={my || '100px'}
      mx="auto"
      px="15px"
      gridGap="30px"
      flexDirection={{ base: 'column', md: 'row' }}
    >
      <Box width={{ base: '100%', lg: '50%' }} textAlign="right">
        <Box
          fontFamily="FedraSansStd-A-medium"
          fontSize={{
            base: '24px',
            md: '30px',
          }}
          lineHeight="1.1"
          color={'#02026c'}
        >
          <Box as="h2" mb={1}>
            {section?.sectionContent[0]?.titleText}
          </Box>
        </Box>

        <Box
          className="event-text"
          fontFamily="FedraSansStd-book"
          fontSize="18px"
        >
          <StructuredTextParser
            str={render(section?.sectionContent[1]?.body)}
            textAlign="right"
          />
        </Box>
        <Box display="flex" justifyContent={{ base: 'center', md: 'flex-end' }}>
          <SimpleButton
            style={section?.sectionContent[2]}
            region={region}
            lang={lang}
            onClick={onOpen}
          />
        </Box>
      </Box>
      <Box
        width={{ base: '100%', lg: '50%' }}
        maxW={{ base: '480px', lg: '90%' }}
        mx="auto"
        objectFit="contain"
      >
        <LazyLoadImageComponent
          src={section?.sectionContent[3]?.image?.url}
          alt={section?.sectionContent[3]?.image?.alt}
          title={section?.sectionContent[3]?.image?.title}
          width="100%"
        />
      </Box>
    </Box>
  );
};

export default TwoColumnHathaTeachersBlock;
