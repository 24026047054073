/* Built In Imports */
import { useRouter } from 'next/router';
/* External Imports */
import { Box, Link } from '@chakra-ui/react';

import React, { useEffect, useState } from 'react';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import SectionTitle from '@components/Headings/SectionTitle';
import BrownSpiralBullets from '@components/Text/BrownSpiralBullets';
import SingleLineText from '@components/UI/SingleLineText';
import MidContainer from '@Layout/MidContainer';
import ProgramCenterPopup from '@sections/yoga-sandhya/ProgramCenterPopup';

/**
 *
 * @param section
 * @param region
 * @param lang
 */

const ProgDetailYs = ({ section, region, lang }) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const { others } = router.query;
  const formElement = {
    "title" : "Select the Location",
    "submitButton": "Continue"
  }
  //Check if page is PBK online
  let pName = ''
  let width = ''
  let height = ''
  let padding = ''
  const isPbkOnlinePage =
    others === 'pancha-bhuta-kriya-online-with-sadhguru-mahashivratri';
  return (
    <Box
      w="100%"
      background="linear-gradient(180deg, #FFF7E2 0%, #FFFCF4 100%)"
      pt={{ md: isPbkOnlinePage ? '0' : '23.44px', base: '21px' }}
      mt={{ base: isPbkOnlinePage ? '50px' : '0' }}
      // https://www.datocms-assets.com/46272/1689699735-brown-flower-bullet.png
    >
      <SectionTitle titleObj={section.sectionContent?.[0]} />
      <Box mb="30px">
        <BrownSpiralBullets sectionContent={section.sectionContent[1]} />
      </Box>
      <MidContainer minW={{base:'90%', md:'300px'}} w={{base:'90%', md:'100%'}} id="program-register">
        <SingleLineText
          sectionContent={section.sectionContent[2]}
          fontFamily="FedraSansStd-book"
          fontSize="16px"
          textAlign="center"
          fontStyle="italic"
          my="0"
          mb={{ base: '40px', md: '31px' }}
        />
        <SingleLineText
          fontFamily="FedraSansStd-book"
          sectionContent={section.sectionContent[3]}
          textAlign="center"
          fontWeight="bold"
          fontSize="22px"
          mb={{ base: '21px', md: '34px' }}
          pb="0"
          my="0"
        />
        <Box
          pt={{ md: '0', base: '0' }}
          pb={{ md: '25px', base: '20px' }}
          d="flex"
          placeContent="center"
        >
            <Link
            width="188px"
            minW={width ? width : pName === "hytt" ? "200px" : { base: '188px', md: '188px' }}
            height={height ? height : pName === "hytt" ? "58px" : "46px"}
            fontStyle="normal"
            fontFamily="FedraSansStd-medium"
            fontSize={pName === "hytt" ? "20px" : "16px"}
            lineHeight="48px"
            background={section?.sectionContent[4]?.linkUrl != '' ? '#FFAE27' : '#909090'}
            borderRadius="5px"
            color={'#000'}
            textAlign="center"
            marginTop="20px"
            p={padding || 5}
            // pt={padding ? 'auto' : '0'}
            pt={pName === "hytt" ? '1' : padding ? 'auto' : '0'}
            cursor={section?.sectionContent[4]?.linkUrl == '' ? 'not-allowed' : 'pointer'}
            _hover={{
              textDecoration: 'none',
              bgColor: section?.sectionContent[4]?.linkUrl != '' ? '#cf4520' : '#909090',
              color: section?.sectionContent[4]?.linkUrl != '' ? '#fff' : '#000',
            }}
            _focus={{
              background: '#cf4520',
              border: '1px solid #F5E3B5',
              color: '#000000',
            }}
            _active={{
              background: '#cf4520',
              border: '1px solid #F5E3B5',
              color: '#000000',
            }}
            onClick={openModal}
            //target={isOpenInNewTab(currentUrl) ? '_blank' : ''}
            //{...props}
          >
            { section?.sectionContent[4]?.buttonText}
          </Link>

          <ProgramCenterPopup isOpen={isOpen} onClose={closeModal} formElement={formElement} lang={lang} region={region} />
        </Box>
      </MidContainer>

      {section?.sectionContent[5]?.titleText != '' && (
        <SectionTitle titleObj={section?.sectionContent[5]} />
      )}
      {section?.sectionContent[6]?.body?.[0]?.value != '' && (
        <Box pb="0px">
          <BrownSpiralBullets sectionContent={section?.sectionContent[6]} />
        </Box>
      )}
    </Box>
  );
};

export default ProgDetailYs;
