/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import ContentArea from '@components/Layout/ContentArea';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the About Sadhguru Section component
 *
 * @param {object} sectionContent - Data for section
 * @param sectionContent.section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} About Sadhguru Section component
 */
const AboutSadhguru = ({ section, region, lang }) => {
  // consoleLog('section==>', section);
  return (
    <MidContainer>
      <Box
        display="flex"
        gridGap="20px"
        flexDir={{ base: 'column', md: 'row' }}
        id="card3in"
        mx="15px"
      >
        <Box maxW={{ base: '302px', md: '330px' }} mx="auto">
          <Box
            _after={{
              content: '" "',
              display: 'block',
              height: '25px',
              backgroundColor: '#7C0514',
              position: 'relative',
              width: '80%',
              margin: '0 auto',
            }}
          >
            <LazyLoadImageComponent
              src={section?.sectionContent[0]?.image?.url}
              alt={section?.sectionContent[0]?.image?.alt}
              title={section?.sectionContent[0]?.image?.title}
            />
          </Box>
        </Box>
        <Box className="event-text">
          <ContentArea
            mainContent={section?.sectionContent[1]?.body}
            region={region}
            lang={lang}
          />
        </Box>
      </Box>
    </MidContainer>
  );
};

export default AboutSadhguru;
