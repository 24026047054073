/* Built In Imports */
import moment from 'moment-timezone';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Image } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';
import LandingPageTopBanner from '@components/HeaderBanner/LandingPageTopBanner';
import TopKolam from '@components/Headings/TopKolam';
import QuoteSymbolImageSignature from '@components/Quotes/QuoteSymbolImageSignature';
import YsBenefitsTitle from '@components/Text/YsBenefitsTitle';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import HighlightBlockPBK from '@sections/pbkonline/HighlightBlockPBK';

const MediaEmbedV2 = dynamic(() => import('@components/Embeds/MediaEmbedV2'));

/**
 *
 * @param section
 * @param region
 * @param lang
 */

const TopBannerYs = ({ section, region, lang, csrData, currentRegion }) => {
  const [timezone, setTimezone] = useState(null);
  const router = useRouter();
  const others = router.asPath;
  //Check if page is PBK online
  const isPbkOnlinePage = router.asPath.includes(
    'pancha-bhuta-kriya-online-with-sadhguru-mahashivratri'
  );
  // PBK timing based on user device timezone
  let deferHours = '';
  let deferMins = '';
  const prgTiming = section;
  const prgTimingData = prgTiming?.sectionContent[7];

  const updateTimes = el => {
    const time = el.split('.');
    setDeferHours(time[0]);
    setDeferMins(time[1] === '5' ? '30' : '');
  };

  const getDeferredTime = (date, deferredHours, deferredMins) => {
    let prgDate = new Date(date);
    prgDate = prgDate.toLocaleString('en-US');

    prgDate = new Date(prgDate);
    if (deferredHours) {
      prgDate.setHours(prgDate.getHours() + parseInt(deferredHours));
    }
    if (deferredMins) {
      prgDate.setMinutes(prgDate.getMinutes() + parseInt(deferredMins));
    }
    return prgDate;
  };

  let startTime = getDeferredTime(
    prgTimingData?.data[0]?.StartTime,
    deferHours,
    deferMins
  );
  let endTime = getDeferredTime(
    prgTimingData?.data[0]?.EndTime,
    deferHours,
    deferMins
  );
  let month = startTime.toLocaleString('default', {
    month: 'long',
  });
  let year = startTime.toLocaleString('default', {
    year: 'numeric',
  });
  let day = startTime.toLocaleString('default', {
    day: 'numeric',
  });
  let prgDate = `${day + ' ' + month + ' ' + year}`;
  startTime =
    startTime.toString() !== 'Invalid Date'
      ? `${startTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })} - `
      : '';
  endTime =
    endTime.toString() !== 'Invalid Date'
      ? endTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
      : '';

  useEffect(() => {
    const userTimezone = moment.tz(moment.tz.guess()).zoneAbbr();
    setTimezone(userTimezone);
  }, []);
  return (
    <Box
      height="100%"
      w="100%"
      maxW={1330}
      mx="auto"
      id="top-header"
      background={
        !isPbkOnlinePage
          ? 'linear-gradient(180deg, #681809 50%, #BA501A 100%)'
          : 'linear-gradient(180deg, #131313 50%, #000000 100%)'
      }
      backgroundSize={{ base: '100% 79%', md: '100% 70%' }}
      backgroundRepeat="no-repeat"
    >
      <Box
        w="100%"
        mb="-75px"
        sx={{
          h1: {
            lineHeight: {
              base: '45px',
              md: !isPbkOnlinePage ? '87px' : '58px',
            },
          },
          '.sub-text': {
            fontSize: {
              base: isPbkOnlinePage ? '18px' : '22px',
              md: isPbkOnlinePage ? '22px' : '35px',
            },
            color: isPbkOnlinePage ? '#ffffff' : '#FFCBA0',
            marginTop: {
              base: isPbkOnlinePage ? '10px' : '0',
              md: isPbkOnlinePage ? '20px' : '0',
            },
          },
        }}
      >
        <LandingPageTopBanner
          sectionContent={section.sectionContent[0]}
          language={lang}
          region={region}
          startTime={startTime}
          endTime={endTime}
          timezone={timezone}
          csrData={csrData}
          prgDate={prgDate}
          currentRegion={currentRegion}
          pNameAlias={isPbkOnlinePage}
        />
      </Box>
      {region !== 'us' && region !== 'ca' && (
        <>
          <MidContainer
            pt="95px"
            w="100%"
            px={{ base: '0', md: 'auto' }}
            mt="-30px"
            mb={{ base: '40px', md: '50px' }}
            background={{
              base: isPbkOnlinePage ? 'linear-gradient(#131313 94%, #fff 0%)' : 'none',
              md: isPbkOnlinePage ? 'linear-gradient(#131313 76%, #fff 0%)' : 'none',
            }}
          >
            {isPbkOnlinePage && section?.sectionContent[3]?._modelApiKey === 'benefits_group' && section?.sectionContent[3].benefits[0].text != '' && (
              <Box
                mt="15px"
                background={
                  'linear-gradient(180deg, #131313 50%, #000000 100%)'
                }
              >
                <YsBenefitsTitle
                  sectionContent={section?.sectionContent[1]}
                  pt="10px"
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <TopKolam
                    margin="30px auto 45px"
                    sectionContent={section?.sectionContent[2]}
                  />
                </Box>
                <HighlightBlockPBK section={section?.sectionContent[3]} />
              </Box>
            )}
            {section?.sectionContent[8] != undefined && (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignContent="center"
                >
                  <TopKolam
                    margin="30px auto 45px"
                    sectionContent={section.sectionContent[2]}
                  />
                </Box>
                <MidContainer w="590px" className="ys-white-text">
                  <StructuredTextParser
                    str={render(section.sectionContent[8]?.body)}
                    region={region}
                    lang={lang}
                    color="white"
                    lineHeight="30px"
                    fontFamily="'FedraSansStd-book', sans-serif"
                    fontSize={{ base: '16px', md: '18px' }}
                    mt="15px"
                    mb="30px"
                  />
                </MidContainer>
              </>
            )}
            {!isPbkOnlinePage && <>
              <TopKolam margin="30px auto 45px" sectionContent={section.sectionContent[1]} />
              <MidContainer w={{base:'90%', md:'590px'}} className="ys-white-text">
                <StructuredTextParser
                  str={render(section.sectionContent[2]?.body)}
                  region={region}
                  lang={lang}
                  color="white"
                  lineHeight="30px"
                  fontFamily="'FedraSansStd-book', sans-serif"
                  fontSize={{ base: "16px", md: "18px" }}
                  mt="15px"
                  mb="30px"
                />
                <style>
                  {`
                    .ys-white-text p {
                      margin-bottom: 20px;
                    }`
                  }
                </style>
              </MidContainer>
            </>}
            <Box
              pos={'relative'}
              maxWidth={'762px'}
              margin={'0 auto'}
              w={{ base: '90%' }}
            >
              <Box
              // sx={{
              //   ":after": {
              //     content: '""',
              //     position: 'absolute',
              //     width: { base: '90%', md: '100%' },
              //     left: '0',
              //     right: '0',
              //     margin: '0 auto',
              //     bottom: { base: '15px', md: '27px' },
              //     zIndex: '-1',
              //     transform: 'scale(.9)',
              //     boxShadow: '10px 3px 39px 10px #000000',
              //   }
              // }}
              >
                <MediaEmbedV2
                  sectionContent={isPbkOnlinePage ? section.sectionContent[4] : section.sectionContent[3]}
                  maxW="100%"
                  margin={{ base: '40px auto 25px', md: '45px auto 50px' }}
                  width={{ base: '100%', md: '762px' }}
                />
              </Box>
            </Box>
          </MidContainer>
          {isPbkOnlinePage && (
            <QuoteSymbolImageSignature
              sectionContent={section.sectionContent[5]}
              pname={isPbkOnlinePage}
            />
          )}
          {!isPbkOnlinePage && (
            <QuoteSymbolImageSignature
              sectionContent={section.sectionContent[4]}
            />
          )}
          {!isPbkOnlinePage && (
          <Box display="flex" justifyContent="center" alignContent="center">
            <Image src={section?.sectionContent[5]?.image?.url} />
          </Box>
          )}
          <Box display="flex" justifyContent="center" alignContent="center">
            <Image src={section?.sectionContent[6]?.image?.url} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default TopBannerYs;
