/* Built In Imports */
import React from 'react';
/* External Imports */
import { Box } from '@chakra-ui/react';
/* Components */
import FaqSectionV2 from '@components/Accordions/FaqSectionV2';
import SectionTitle from '@components/Headings/SectionTitle';

/* Internal Imports */

/**
 * Renders SP Alumni Components
 * @param {string} id - id
 * @returns {ReactElement} SP Alumni Component
 */
export default function SPFaq({ section, region, lang, isLeftMenu }) {
  const secCon = section.sectionContent;
  return (
    <Box
      p={{ base: '0 0 20px 0', md: '80px' }}
      bgColor="transparent"
      float="left"
      position="relative"
      width="100%"
      bg="#fff"
      textAlign="center"
    >
      <SectionTitle titleObj={secCon[0]} />
      <Box
        width={{ base: '100%', md: '100%', lg: '100%' }}
        textAlign="center"
        m="0 auto"
        pt={{
          lg: '20px',
          base: '0',
        }}
      >
        <Box mt="20px" mb="10px">
          <FaqSectionV2
            faqSection={secCon[1]}
            region={region}
            lang={lang}
            bgColor="#540606"
          />
        </Box>
        <Box mt="20px" mb="10px">
          <FaqSectionV2
            faqSection={secCon[2]}
            region={region}
            lang={lang}
            bgColor="#540606"
          />
        </Box>
        <Box mt="20px" mb="10px">
          <FaqSectionV2
            faqSection={secCon[3]}
            region={region}
            lang={lang}
            bgColor="#540606"
          />
        </Box>
        <Box mt="20px" mb="10px">
          <FaqSectionV2
            faqSection={secCon[4]}
            region={region}
            lang={lang}
            bgColor="#540606"
          />
        </Box>
        <Box mt="20px" mb="10px">
          <FaqSectionV2
            faqSection={secCon[5]}
            region={region}
            lang={lang}
            bgColor="#540606"
          />
        </Box>
        <Box mt="20px" mb="10px">
          <FaqSectionV2
            faqSection={secCon[6]}
            region={region}
            lang={lang}
            bgColor="#540606"
          />
        </Box>
        <Box mt="20px" mb="10px">
          <FaqSectionV2
            faqSection={secCon[7]}
            region={region}
            lang={lang}
            bgColor="#540606"
          />
        </Box>
        <Box mt="20px" mb="10px">
          <FaqSectionV2
            faqSection={secCon[8]}
            region={region}
            lang={lang}
            bgColor="#540606"
          />
        </Box>
      </Box>
    </Box>
  );
}
