/* Built In Imports */
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

/* External Imports */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
// import { nanoid } from 'nanoid';
import axios from 'axios';
import * as Yup from 'yup';

/* Internal Imports */
/* Components */
import SingleImage from '@components/Card/SingleImage';
import InputUI from '@components/Elements/InputUI';
import SelectUI from '@components/Elements/SelectUI';
import SectionTitle from '@components/Headings/SectionTitle';
import config from '@config';
import ContentArea from '@Layout/ContentArea';

/* Style */
/* Services */
import { consoleLog } from '@components/Utility/Shared/SharedService';
import CountriesCodes from '@constants/countryCodes';
import { fetchWebinarDetails } from 'services/sadhanapadaService';

/**
 * Renders SP Webinar
 *
 * @param {string} id - id
 * @returns {ReactElement} - SP Webinar
 */
const SPWebinar = ({ section, region, lang }) => {
  const secCon = section.sectionContent;
  const [radioData, setRadioData] = useState([]);
  const [nodata, setnodata] = useState('');
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const router = useRouter();

  const localData = useCallback(async () => {
    const pageData_1 = await fetchWebinarDetails(region, lang);
    if (pageData_1.error_code != '404') {
      setRadioData(pageData_1);
    } else {
      setnodata(pageData_1);
    }
  }, [router.asPath]);

  useEffect(() => {
    localData();
    let frmbutton = document.querySelector('#webinarbtn');
    if (frmbutton != undefined) {
      frmbutton.disabled = true;
    }
  }, [localData]);
  const SPWebinarSchema = Yup.object().shape({
    id: Yup.string().required('Please select webinar'),
    first_name: Yup.string()
      .min(2, 'First Name is invalid')
      .matches(/^([a-zA-Z ])+$/u, {
        message: 'First Name is invalid',
      })
      .max(100, 'First Name is invalid')
      .required('First Name is required'),
    last_name: Yup.string()
      // .min(2, 'Last Name Invalid')
      .matches(/^([a-zA-Z ])+$/u, {
        message: 'Last Name is invalid',
      })
      .max(100, 'Last Name Invalid')
      .required('Last Name is required'),
    email: Yup.string()
      .matches(/^[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z.]{2,15}$/, {
        message: 'Email is invalid',
      })
      .required('Email is required'),
    country: Yup.string().required('Country is required'),
  });

  const onSubmit = async values => {
    setLoading(true);

    const countryKey = radioData.find(({ id }) => id === values.id);

    if (!countryKey || countryKey === null) {
      setErr('Webinar not selected properly');
      setLoading(false);
      return;
    }

    const finalObj = {
      ...values,
      custom_country_key: countryKey.custom_country_key,
    };

    axios({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${config.SADHANAPADA_WEBINAR_REGISTRATION_FORM}`,
      data: finalObj,
    })
      .then(response => {
        if (response && response?.data && response?.data?.length) {
          const resp = response.data[0];
          // consoleLog('response by server first object', resp);
          const { status } = resp;
          if (status === 'success') {
            let curl = `${config.BASE_PATH}/${region}/${lang}/volunteer/sadhanapada/registration-successful`;
            router.push(curl);
            setLoading(false);
            return;
          } else if (status === 'failure') {
            setErr(resp?.error || 'Cannot register for the webinar');
            setLoading(false);
            return;
          } else {
            setErr('Something went wrong');
            setLoading(false);
            return;
          }
        } else {
          setErr('Response by server is not handled');
          setLoading(false);
          return;
        }
      })
      .catch(err => {
        consoleLog(err);
        setLoading(false);
        // actions.setSubmitting(false);
        setErr(err?.response?.status || 'Unknown error');
        // catch errors
      });
  };
  return (
    <Box
      p={{ base: '0 0 30px 0', md: '40px' }}
      bgColor="#f1eee4"
      position="relative"
      display="flex"
    >
      <Formik
        enableReinitialize
        initialValues={{
          id: '',
          first_name: '',
          last_name: '',
          email: '',
          country: '',
        }}
        validationSchema={SPWebinarSchema}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleSubmit, errors, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              textAlign="center"
              mt="30px"
              fontFamily="'FedraSansStd-A-medium', sans-serif"
            >
              <SectionTitle titleObj={secCon[0]} />
              <Box mt="20px" mb="20px">
                <SingleImage
                  sectionContent={secCon[1]}
                  my="0"
                  w={{ base: '100%', md: '320px' }}
                  maxWidth="320px"
                  region={region}
                  lang={lang}
                />
              </Box>
              <Box
                w={{ base: '300px', md: '400px', lg: '500px' }}
                fontFamily={
                  lang === 'ta' ? 'MuktaMalar' : 'FedraSansStd-A-medium'
                }
                fontWeight={lang === 'ta' ? 'bold' : 'normal'}
                fontSize="20px"
                lineHeight="60px"
                bgColor="rgba(255, 221, 146, 0.5)"
                m="20px auto 30px auto"
              >
                {secCon[2]?.text}
              </Box>
              <Box
                display="flex"
                fontFamily="FedraSansStd-book"
                justifyContent="center"
                textAlign="left"
                flexDirection={{ base: 'column', md: 'row', lg: 'row' }}
                mb="20px"
              >
                <Box
                  color="#28231e"
                  fontSize="18px"
                  w={{ base: '90%', md: '48%', lg: '48%' }}
                  pr={{ base: 'unset', md: '25px', lg: '25px' }}
                  m={{
                    base: '20px auto 0 auto',
                    md: '20px 0 0 0',
                  }}
                  className="frm_txt"
                  fontFamily="'FedraSansStd-book', sans-serif"
                >
                  <ContentArea
                    mainContent={secCon[3]?.body}
                    tAlign={secCon[3]?.style}
                    region={region}
                    lang={lang}
                    font="18px"
                  />
                </Box>
                {radioData?.length > 0 ? (
                  <Box
                    bgColor="#d1cbb9"
                    p="20px 40px"
                    w={{ base: '100%', md: '40%', lg: '40%' }}
                    mt={{ base: '20px' }}
                  >
                    <Box fontSize="18px" fontFamily="FedraSansStd-medium">
                      <Text textAlign="center" pb="20px">
                        {secCon[4]?.text}
                      </Text>

                      <Field name="id">
                        {({ field, form }) => {
                          const { onChange, ...rest } = field;

                          return (
                            <FormControl
                              isRequired
                              isInvalid={
                                !!form.errors['id'] && !!form.touched['id']
                              }
                            >
                              <RadioGroup {...rest}>
                                <Stack direction="column">
                                  {radioData?.map(option => {
                                    return (
                                      <Radio
                                        key={option.id}
                                        value={option.id}
                                        colorScheme="orange"
                                        onChange={onChange}
                                        padding="8px"
                                      >
                                        {option.start_time}
                                      </Radio>
                                    );
                                  })}
                                </Stack>
                              </RadioGroup>
                              <FormErrorMessage>
                                {form.errors['id']}
                              </FormErrorMessage>
                            </FormControl>
                          );
                        }}
                      </Field>
                    </Box>
                    <Box className="webnair">
                      <ContentArea
                        mainContent={secCon[5]?.body}
                        tAlign={secCon[5]?.style}
                        region={region}
                        lang={lang}
                      />
                    </Box>
                  </Box>
                ) : (
                  ''
                )}
              </Box>
              {radioData?.length > 0 ? (
                <Box w={{ base: '100%' }} m="0px auto" className="webinarfrm">
                  {/* JoinWebinar */}
                  <Flex
                    w={{ base: '', sm: '', md: '' }}
                    flexFlow={{ base: 'column-reverse', md: 'row' }}
                    flexWrap="wrap"
                    pos="relative"
                    margin={{ base: '40px 0 0', md: '25px 20px 0' }}
                    justifyContent="center"
                  >
                    <Box
                      display={{ base: 'block', xl: 'flex' }}
                      m={{ base: '0 5%', lg: 'unset' }}
                      justifyContent="space-between"
                    >
                      <Field name="first_name">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.first_name && form.touched.first_name
                            }
                            isRequired
                            width={{
                              base: '100%',
                              xl: 'calc((100% - 40px)/3)',
                            }}
                            m={{ base: '0 0 18px 0', xl: '0 18px 0 0' }}
                          >
                            <InputUI
                              field={field}
                              id="first_name"
                              placeholder="First Name"
                              onChange={handleChange}
                              variant="outline"
                              borderWidth="none"
                              fontFamily="'FedraSansStd-book', sans-serif"
                              fontSize="18px"
                              color="#8a8173"
                              height={{ base: '50px', lg: '40px' }}
                              padding="10px"
                              borderRadius="3px"
                              border="1px solid #a1a1a1"
                              p="20px"
                              mr="20px"
                            />
                            <FormErrorMessage justifyContent="left">
                              {form.errors.first_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="last_name">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.last_name && form.touched.last_name
                            }
                            isRequired
                            width={{
                              base: '100%',
                              xl: 'calc((100% - 40px)/3)',
                            }}
                            m={{ base: '0 0 18px 0', xl: '0 18px 0 0' }}
                          >
                            <InputUI
                              field={field}
                              id="last_name"
                              placeholder="Last Name"
                              onChange={handleChange}
                              variant="outline"
                              borderWidth="none"
                              fontFamily="'FedraSansStd-book', sans-serif"
                              fontSize="18px"
                              color="#8a8173"
                              height={{ base: '50px', lg: '40px' }}
                              padding="10px"
                              borderRadius="3px"
                              border="1px solid #a1a1a1"
                              p="20px"
                              mr="20px"
                            />
                            <FormErrorMessage justifyContent="left">
                              {form.errors.last_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="email">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={form.errors.email && form.touched.email}
                            isRequired
                            width={{
                              base: '100%',
                              xl: 'calc((100% - 40px)/3)',
                            }}
                            m={{ base: '0 0 18px 0', xl: '0 18px 0 0' }}
                          >
                            <InputUI
                              field={field}
                              id="email"
                              placeholder="Email"
                              onChange={handleChange}
                              variant="outline"
                              borderWidth="none"
                              fontFamily="'FedraSansStd-book', sans-serif"
                              fontSize="18px"
                              color="#8a8173"
                              height={{ base: '50px', lg: '40px' }}
                              padding="10px"
                              borderRadius="3px"
                              border="1px solid #a1a1a1"
                              p="20px"
                              mr="20px"
                            />
                            <FormErrorMessage justifyContent="left">
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="country">
                        {({ field, form }) => (
                          <FormControl
                            isInvalid={
                              form.errors.country && form.touched.country
                            }
                            isRequired
                            width={{
                              base: '100%',
                              xl: 'calc((100% - 40px)/3)',
                            }}
                            m={{ base: '0 0 18px 0', lg: '0 18px 0 0' }}
                          >
                            <SelectUI
                              field={field}
                              id="country"
                              borderWidth="none"
                              options={CountriesCodes}
                              values="label"
                              fontSize="18px"
                              placeHolder=" Country"
                              colors="#8a8173"
                              heights="40px"
                              paddingLeft="15px"
                              className="countrylist"
                              onChange={e => {
                                handleChange(e);
                                // setTouched('country', false);
                              }} // or {form.handleChange}
                              borderRadius="0"
                            />
                            <FormErrorMessage justifyContent="left">
                              {form.errors.country}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </Flex>

                  {errors ? (
                    <Box color="red" m="15px 0" textAlign="center">
                      {Object.values(errors)[0]}
                    </Box>
                  ) : null}
                  {err ? (
                    <Box color="red" m="15px 0" textAlign="center">
                      {err}
                    </Box>
                  ) : null}

                  <Box marginTop="20px">
                    <Box textAlign="center" mt="20px">
                      <Button
                        type="submit"
                        w="auto"
                        id="webinarbtn"
                        background="#cf4520"
                        color="#faf7f0"
                        fontSize={{
                          base: '18px',
                          lg: '16px',
                        }}
                        padding={{ base: '7px 19px', sm: '7px 19px' }}
                        border="1px solid #a1a1a1"
                        borderRadius="4px"
                        fontStyle={
                          lang === 'ta' ? 'MuktaMalar' : 'FedraSansStd-medium'
                        }
                        fontBold={lang === 'ta' ? 'bold' : 'normal'}
                        fontWeight="300"
                        _hover={{
                          border: 'none',
                          color: '#ffffff',
                          background: '#000054',
                        }}
                        _active={{
                          border: 'none',
                          background: '#000054',
                        }}
                        margin="0 auto"
                        disabled={isValid ? (loading ? true : false) : true}
                      >
                        {loading ? 'Submitting...' : `${secCon[6].buttonText}`}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  className="nowebinarfrm"
                  textAlign="center"
                  fontSize="18px"
                >
                  <ContentArea
                    mainContent={secCon[7]?.body}
                    tAlign={secCon[7]?.style}
                    region={region}
                    lang={lang}
                  />
                  {/* {nodata.message} */}
                </Box>
              )}
            </Box>
          </Form>
        )}
      </Formik>
      <style global jsx>
        {`
          .centerContent {
            clear: both;
          }
          .webnair a {
            color: #cf4824;
          }
          .webinarfrm select {
            padding-left: 20px;
          }
          .nowebinarfrm p {
            text-align: center;
          }
          .frm_txt p {
            font-size: 18px !important;
          }
          .countrylist {
            padding-left: 10px;
          }
        `}
      </style>
    </Box>
  );
};
export default SPWebinar;
