/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SectionUI from '@components/UI/Common/SectionUI';
import StyleConfig from '@components/UI/Common/StyleConfig';

/* Services */

/**
 * Renders the Faq component.
 * @param {Array} section - Main FAQ data.
 * @returns {ReactElement} AccordionFaq component.
 */
const AccordionFaq = ({ section, region, lang }) => {
  return (
    <Box as="div" display="block" placeContent="center">
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        {section?.sectionContent && section?.sectionContent?.length
          ? section?.sectionContent?.map((secCon, i) => {
            return (
              <SectionUI
                key={i}
                sectionContent={secCon}
                section={section}
                region={region}
                language={lang}
                isLeftMenu={false}
              />
            );
          })
          : null}
      </StyleConfig>
    </Box>
  );
};

export default AccordionFaq;
