/* Built In Imports */
/* External Imports */
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/**
 * Renders the MobileApp Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @returns {ReactElement} MobileApp component
 */

const MobileApp = ({ section, region, lang }) => {
    return (
      <>
        <SectionTitle titleObj={section.sectionContent?.[0]} />
        <StructuredTextParser
          textAlign={section.sectionContent[1]?.style}
          str={render(section.sectionContent[1]?.body)}
            />
      </>
    );
}

export default MobileApp;