/* Built In Imports */
import React from 'react';
/* External Imports*/
import { Box, Image, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import NextLink from 'next/link';

/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain } from '@components/Utility/utils';
import SectionUI from '../../components/UI/Common/SectionUI';

/**
 * Renders the Online Satsang Banner Component
 *
 * @param {object} section - data for the section
 * @param section.sectionContent
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @param section.region
 * @param section.lang
 * @returns {ReactElement} - Online Satsang Banner Component
 */
const OnlineSatsangBanner = ({ sectionContent, region, lang }) => {
  return (
    <Box>
      <Box
        bgColor="#634b31"
        mx="auto"
        h={{ base: '350px', sm: '400px' }}
        bgRepeat="no-repeat"
        bgSize="auto"
        bgPosition="top center"
        display={{ base: 'inherit', md: 'none' }}
        bgImage={{
          base:
            sectionContent[5]?.mobileImage?.url ||
            sectionContent[5]?.image?.url,
          md:
            sectionContent[5]?.mobileImage?.url ||
            sectionContent[5]?.image?.url,
        }}
      ></Box>

      <Box
        bgColor="#fcf2d9"
        mx="auto"
        h="100%"
        bgImg={{ base: 'none' }}
        bgRepeat="no-repeat"
        bgSize="auto"
        bgPosition="top center"
        bgImage={sectionContent[5]?.image?.url}
        display="flex"
      >
        <Box maxW={'100%'}>
          <TextArea content={sectionContent} />
        </Box>
      </Box>
      {sectionContent?.map(section => {
        if (section._modelApiKey === 'top_kolam')
          return <SectionUI sectionContent={section} />;
      })}
    </Box>
  );
};

const TextArea = ({ content, region, lang }) => {
  return (
    <Box
      left="0"
      display={{ md: 'block', base: 'initial' }}
      clear="both"
      float="left"
      placeItems="center"
      h="auto"
      bg={{ base: '#634b31', md: 'none' }}
      m={{ sm: '0', base: '0 auto' }}
      textAlign={{ md: 'left', base: 'center' }}
      top="0"
      width={{ base: '100%', lg: '66%' }}
      height={'auto'}
      p={{ md: '2em', base: '19px 24px' }}
      className="banner-text"
      zIndex={10}
    >
      <Box className="first" display={{ sm: 'block', base: 'grid' }}>
        <StructuredTextParser
          str={render(content[0]?.body)}
          region={region}
          lang={lang}
          className="second"
        />
      </Box>

      <Box
        width="100%"
        mt="1em"
        display="flex"
        flexDir={{
          xl: 'row',
          lg: 'column',
          md: 'column',
          sm: 'column',
          base: 'column',
        }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        textAlign={{ base: 'center', sm: 'left' }}
      >
        <Box
          width="100%"
          display={{ base: 'flex', sm: 'initial' }}
          flexDir={{ base: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="center"
          borderRight={{
            xl: '1px solid black',
            lg: 'none',
            md: 'none',
            sm: 'none',
            base: 'none',
          }}
          paddingRight={{ base: 0, sm: '1em' }}
          mr={{ base: 0, md: '20px' }}
        >
          <Box
            className="second"
            lineHeight="22px"
            textAlign={{ base: 'center', md: 'left' }}
          >
            {StructuredTextContent(content[1].body)}
            {/* <StructuredTextParser
              str={render(content[1].body) || ''}
              region={region}
              lang={lang}
              textAlign={{ base: 'center', md: 'left' }}
              mt="0"
            /> */}
          </Box>
          <Box
            mx="auto"
            width={{ base: '100%', sm: '80%', md: '100%' }}
            display={{ base: 'flex', md: '' }}
            justifyContent={{ base: 'center', md: 'flex-start' }}
          >
            <LoginButton
              className="button"
              content={content[2]}
              region={region}
              lang={lang}
            />
          </Box>
          <Box className="third" fontFamily="FedraSansStd-book">
            <StructuredTextParser
              str={render(content[3].body) || ''}
              region={region}
              lang={lang}
              mb={'20px'}
              textAlign={{ base: 'center', md: 'left' }}
            />
          </Box>
        </Box>

        <Box
          h="1px"
          w="100%"
          display={{ base: '', md: 'none' }}
          bgColor="#fff"
          my={2}
        ></Box>

        <Box
          width={{ base: '100%', md: '50%' }}
          fontFamily="FedraSansStd-book"
          className="fourth"
        >
          <StructuredTextParser
            str={render(content[4].body) || ''}
            region={region}
            lang={lang}
            mb={'20px'}
            className="wordBreak"
            textAlign={{ base: 'left', sm: 'center', md: 'left' }}
          />
        </Box>
      </Box>
      <style>
        {`
        *{
          word-wrap:inherit;
        }
        @media only screen and (max-width: 600px) {
 *{
  word-wrap:break-word;
  }
}
        .banner-text h1{
          color:#333;
        }
        .banner-text p{
          color:#333;
        }
        .banner-text b{
          color:#333;
        }
        .button a{
          color:"#fff !important";
        }
        .first a {
          font-family: FedraSansStd-medium;
          font-weight: 200;
        }
        .first p{
          font-size: 18px;
    color: #333;
    text-align: left;
    line-height: 1.44;
    padding: ${lang === 'en' ? '2vw 4vw 0 0vw' : '0 4vw 0 0vw'} ;
    font-family: FedraSansStd-book-i;
        }
        .banner-txt b{
          font-family:FedraSansStd-medium !important;
        }
        .second h1 {
          font-family: 'FedraSerifAStdBook'
        }
        h1{
          font-size:54px;
        }
        .second a{
          color: #cf4520;
          cursor: pointer;
          font-family: FedraSansStd-medium;
        }
        .second a:hover{
          text-decoration: underline;

        } 
        .fourth p a:hover{
          text-decoration: underline;

        }
        .second strong, .second a strong {
          font-weight: 400;
          font-family: FedraSansStd-medium;
        }
        .second, .third, .fourth p{
          font-family:'FedraSansStd-book';
         
        }
        .fourth p { margin-bottom: 18px; }
        .second, .third, .fourth b{
          font-weight:normal;
          color: #fff;
        }
        .fourth p a { color: #cf4520; }
        .fourth strong{
          font-family:'FedraSansStd-medium';
          font-weight: 200;
        }
        @media (max-width: 767px) {
           .second, .third, .fourth b, 
          .second, .third, .fourth p{
            color: #fff;
             font-family:'FedraSansStd-book';
        }
        .second h1, .first p, .third p{
          color: #fff;
        }
        .second p strong {
          color: #fff;
        }
        .second p a strong {
          color: #f7d07e;
        }
        .second p a {text-decoration: none;}
        .second h1 {
          font-size: 20px;
        }
        .second .banner-text p { text-align: center; }
        `}
      </style>
    </Box>
  );
};

const LoginButton = ({ content, region, lang }) => {
  return (
    <NextLink
      href={refineUrlDomain(content?.linkUrl, region, lang)}
      passHref
      legacyBehavior>
      <Link
        display="block"
        fontWeight={500}
        fontFamily="FedraSansStd-medium"
        fontSize={'17px'}
        bgColor="#cf4520"
        textAlign="center"
        padding="5px 30px"
        mt={'20px'}
        borderRadius="3px"
        color="#faf7f0"
        textDecoration="none"
        _hover={{
          bg: '#000054',
        }}
        width={'70%'}
      >
        {content?.buttonText}
      </Link>
    </NextLink>
  );
};

const SingleImageBanner = ({ sectionContent }) => {
  return (
    <Box>
      <Image
        alt={sectionContent.image?.title}
        title={sectionContent.image?.title}
        textAlign="center"
        src={{
          md: sectionContent?.image?.url,
          sm: sectionContent?.image?.url,
          base: sectionContent?.mobileImage?.url || sectionContent?.image?.url,
        }}
        // src={sectionContent?.image?.url}
      />
    </Box>
  );
};

export default OnlineSatsangBanner;
