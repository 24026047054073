/* Built In Imports */
import NextLink from 'next/link';
import React from 'react';

/* External Imports */
import { Box, Image, Link, SimpleGrid } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */ 
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
/* Styles */
/* Services */

/**
 * Renders the Tamil Research Component
 *
 * @param section.section
 * @param {object} section - Data for the section from api
 * @param {string} region - Region of the section
 * @param {string} lang - lang of the section
 * @param section.region
 * @param section.lang
 * @returns {ReactElement} Tamil Research Component
 */
const TamilResearch = ({ section, region, lang }) => {
  return <>
    <Box w="100%" fontFamily="MuktaMalar" textAlign="center">
      <Box
        maxW="770px"
        mx="auto"
        fontFamily="FedraSansStd-A-medium"
        fontSize={{
          base: '24px',
          md: '30px',
        }}
        lineHeight="1.1"
        color="#02026c"
        textAlign="center"
        my={{
          base: '30px',
          md: '50px',
        }}
      >
        <Box as="h2" mb={1}>
          {section.sectionContent[0]?.titleText}
        </Box>
        <Box textAlign="center" mb="1.5rem">
          <LazyLoadImageComponent
            alt="divider"
            title="divider"
            src={`${config.imgPath}/d/46272/1654103596-divider-yoga.svg`}
          />
        </Box>
      </Box>
      <Box w={{ md: '710px', sm: '302px' }} m="0 auto">
        <SimpleGrid spacing={'30px'} columns={{ md: '2', sm: '1' }}>
          <Box
            // justifyContent={"space-around"}
            w={{ md: '340px', sm: '302px' }}
            // flexDir={"column"}
            h="100%"
            background="#f5f0e5"
            padding="32px 15px 0px"
            borderRadius="5px"
            boxShadow="0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
          >
            <Box
              as="h2"
              className="title"
              paddingBottom="30px"
              fontFamily="MuktaMalar"
              textAlign="center"
              mb="1em"
              color="#02026c"
              fontSize={'30px'}
              fontWeight="500"
              lineHeight={'1.11'}
            >
              {section?.sectionContent[1]?.titleText}
            </Box>

            <Box
              className="title"
              paddingBottom="30px"
              fontSize={'18px'}
              color="black"
              fontWeight={'normal'}
            >
              <style global jsx>
                {`
                  p {
                    text-align: center;
                    font-size: 18px;
                    font-family: 'FedraSerifAStdBook';
                  }
                `}
              </style>
              <StructuredTextParser
                str={render(section?.sectionContent[2].body)}
                region={region}
                lang={lang}
              />
            </Box>
            <NextLink href={section?.sectionContent[3]?.linkUrl} passHref legacyBehavior>
              <Link
                mb="40px"
                display="block"
                fontWeight={500}
                fontFamily="FedraSansStd-medium"
                fontSize={'18px'}
                bgColor="#cf4520"
                h={'auto'}
                margin="2.2vw 0"
                textAlign="center"
                padding="10px 36px"
                borderRadius="3px"
                color="#faf7f0"
                textDecoration="none"
                _hover={{
                  bg: '#000054',
                }}
              >
                {section?.sectionContent[3]?.buttonText}
              </Link>
            </NextLink>

            <Box
              as="h3"
              className="title"
              paddingBottom="30px"
              fontWeight={'normal'}
            >
              {' '}
              <style global jsx>
                {`
                  p {
                    text-align: center;
                    font-size: 18px;
                    font-family: 'FedraSerifAStdBook';
                  }
                `}
              </style>
              <StructuredTextParser
                str={render(section?.sectionContent[4].body)}
                region={region}
                lang={lang}
              />
            </Box>
            <Image
              src={section?.sectionContent[5]?.image.url}
              alt={section?.sectionContent[5]?.image.alt}
              title={section?.sectionContent[5]?.image.title}
              margin="2.2vw 0"
            />
          </Box>

          <Box
            // justifyContent={"space-around"}
            w={{ md: '340px', sm: '302px' }}
            // flexDir={"column"}
            h="100%"
            background="#f5f0e5"
            padding="32px 15px 0px"
            borderRadius="5px"
            boxShadow="0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
          >
            <Box
              as="h2"
              className="title"
              paddingBottom="30px"
              fontFamily="MuktaMalar"
              textAlign="center"
              mb="1em"
              color="#02026c"
              fontSize={'30px'}
              fontWeight="500"
              lineHeight={'1.11'}
            >
              {section?.sectionContent[6]?.titleText}
            </Box>

            <Box
              as="h3"
              className="title"
              paddingBottom="30px"
              fontSize={'18px'}
              fontWeight="normal"
            >
              <style global jsx>
                {`
                  p {
                    text-align: center;
                    font-size: 18px;
                    font-family: 'FedraSerifAStdBook';
                    fontWeight="normal"
                  }
                `}
              </style>
              <StructuredTextParser
                str={render(section?.sectionContent[7].body)}
                region={region}
                lang={lang}
              />
            </Box>
            <NextLink href={section?.sectionContent[8]?.linkUrl} passHref legacyBehavior>
              <Link
                mb="40px"
                display="block"
                fontWeight={500}
                fontFamily="FedraSansStd-medium"
                fontSize={'18px'}
                bgColor="#cf4520"
                h={'auto'}
                margin="2.2vw 0"
                textAlign="center"
                padding="10px 36px"
                borderRadius="3px"
                color="#faf7f0"
                textDecoration="none"
                _hover={{
                  bg: '#000054',
                }}
              >
                {section?.sectionContent[8]?.buttonText}
              </Link>
            </NextLink>

            <Box paddingBottom="30px">
              <style global jsx>
                {`
                  p {
                    text-align: center;
                    font-size: 18px;
                    font-family: 'FedraSerifAStdBook';
                    fontWeight="normal"
                  }
                `}
              </style>
              <StructuredTextParser
                str={render(section?.sectionContent[9].body)}
                region={region}
                lang={lang}
                textAlign="center"
                fontSize="18px"
                fontFamily="FedraSerifAStdBook"
                fontWeight="normal"
              />
            </Box>
            <Image
              src={section?.sectionContent[10]?.image.url}
              title={section?.sectionContent[10]?.image.title}
              alt={section?.sectionContent[10]?.image.url}
              margin={'2.2vw 0'}
            />
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  </>;
};

export default TamilResearch;
