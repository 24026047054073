/* Built In Imports */
/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/**
 * Renders the BuyNow Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @returns {ReactElement} BuyNow component
 */

const BuyNow = ({ section, region, lang }) => {
  return (
    <>
      <SectionTitle titleObj={section.sectionContent[0]}></SectionTitle>
      <Box
        className="event-text"
        mx={{ base: '15px', md: 0 }}
        textAlign="center"
        color={'#ab850e'}
      >
        <StructuredTextParser str={render(section.sectionContent[0]?.body)} />
      </Box>
      <Box className="event-text" textAlign="left" mx={{ base: '15px', md: 0 }}>
        <StructuredTextParser
          textAlign={section.sectionContent[1]?.style}
          str={render(section.sectionContent[1]?.body)}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        gridGap={{ base: '5px', md: '50px' }}
        my={{ base: 3, md: 10 }}
        mx={{ base: '15px', md: 'auto' }}
        flexDir={{ base: 'column', md: 'row' }}
        maxW={{ base: '80%', md: '600px', lg: '770px' }}
      >
        {section?.sectionContent
          .map((item, index) => {
            return (
              <Link href={item?.linkUrl} key={index} mb={12} target="_blank">
                <LazyLoadImageComponent
                  src={item?.image?.url}
                  alt={item?.image?.alt}
                  title={item?.image?.title}
                  maxWidth="200"
                  height={{ base: 'auto', md: '100' }}
                  objectFit="contain"
                />
              </Link>
            );
          })
          .slice(2, 5)}
      </Box>
    </>
  );
};

export default BuyNow;
