/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */
import { isVisInViewport } from '@components/Utility/Shared/SharedService';

/**
 * Renders IdyRegisterStickyButton
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} IdyRegisterStickyButton
 */
const IdyRegisterStickyButton = ({ sectionContent, region, lang }) => {
  const router = useRouter();
  const [isVisible, setIsVisible] = useState(false);
  const [newButton, setNewButton] = useState(null);

  useEffect(() => {
    // if (!isMobile) {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
    // }
  }, []);

  const listenToScroll = () => {
    const footerDiv = document.getElementById('footer');
    const heightToHideFrom = window.innerHeight * .8;
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
    // console.log('winScroll', winScroll, heightToHideFrom)

    if (winScroll > heightToHideFrom) {
      const footerInViewPort = footerDiv && isVisInViewport(footerDiv);
      if (footerInViewPort) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
    else {
      setIsVisible(false);
    }
  };

  return (
    isVisible && sectionContent[0].linkUrl != '' && (
      <Box
        position="fixed"
        sx={{
          filter:
            'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.10)) ' +
            'drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.09)) ' +
            'drop-shadow(0px 34px 21px rgba(0, 0, 0, 0.05)) ' +
            'drop-shadow(0px 61px 24px rgba(0, 0, 0, 0.01)) ' +
            'drop-shadow(0px 96px 27px rgba(0, 0, 0, 0.00))',
          color: '#FFFFFF',
        }}
        // background={{ base: '#171001', md: 'none' }}
        top={{ base: 'unset', md: '50%' }}
        py="15px"
        bottom={{ base: '0', md: 'unset' }}
        zIndex="9"
        right={{ base: 'unset', md: '0', xl: '0' }}
        left={{ base: '50%', md: 'unset' }}
        width={{ base: '100%', md: '166px' }}
        h={{ base: 'auto', md: '80px' }}
        transform={{ base: 'translateX(-50%)', md: 'none' }}
        color="#fff"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          window.dataLayer.push({
            event: region === 'in' ? 'IDY_Accept_Challenge' : 'IDY_Download_the_app',
            IDY_LP_Location: 'Sticky_CTA',
          });
        }}
      >
        <NextLink
          href={(region !== 'in' && router.query.referrer_id) ? '#challenge-form' : sectionContent[0].linkUrl}
          passHref
          legacyBehavior
        >
          <Link
            className="orange-button"
            display={'block'}
            justifyContent="center"
            alignItems="center"
            fontWeight={'500'}
            // fontSize={'18px'}
            bgColor={'#cf4520'}
            h={'auto'}
            minH="44px"
            cursor={'pointer'}
            textAlign={'center'}
            padding={'14px 29px'}
            color="#faf7f0"
            textDecoration="none"
            width={'auto'}
            background="#CA4E2A"
            borderRadius={3}
            fontFamily="FedraSansStd-medium"
            textDecor="none"
            mx={{ base: '0', md: '0' }}
            p="16px 18px"
            fontSize="16px"
            maxW={{ base: '233px', md: '166px' }}
            minW={{ base: '217px', md: '148px' }}
            mb="0"
            mt="0"
            _focus={{ outline: '0' }}
            _hover={{ background: '#983C21', textDecoration: 'none' }}
            target={(region !== 'in' && router.query.referrer_id) ? '_self' : '_blank'}
          >
            {sectionContent[0]?.buttonText}
          </Link>
        </NextLink>
      </Box>
    )
  );
};

export default IdyRegisterStickyButton;
