/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { Box, Collapse, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { FaTimes } from 'react-icons/fa';

/* Internal Imports */
/* Components */
import CardInfo3ColumnImageLessGroup from '@components/Card/CardInfo3ColumnImageLessGroup';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the All Center Section component
 *
 * @param {object} section - Data for section
 * @returns {ReactElement} All centers component
 */

const AllCenter = ({ section }) => {
  const [viewAllCenters, setViewAllCenters] = useState(false);

  // useEffect(() => {
  //   return () => {
  //     setViewAllCenters(false);
  //   };
  // }, [viewAllCenters]);

  return (
    <>
      {section.sectionContent[1]?.cards?.length > 0 &&
        section?.sectionContent[0] && (
          <Box
            fontFamily="FedraSansStd-medium"
            fontSize={{ base: '18px' }}
            marginTop={{ md: '45px', base: '10px' }}
            marginBottom={{ md: '45px', base: '10px' }}
            as="div"
            display="block"
            className="event-text"
          >
            <Link
              fontFamily="FedraSansStd-medium"
              fontSize="18px"
              textAlign="center"
              onClick={() => setViewAllCenters(true)}
            >
              <StructuredTextParser
                textAlign={section.sectionContent[0]?.style}
                str={render(section.sectionContent[0]?.body)}
              />
            </Link>
          </Box>
        )}
      {section?.sectionContent[1] && (
        <>
          <Collapse in={viewAllCenters} animateOpacity>
            <Box borderBottom="1px solid #dfd6c4">
              <Box
                fontSize={'24px'}
                top={0}
                float="right"
                color={'black'}
                fontWeight="100"
                onClick={() => setViewAllCenters(false)}
              >
                <FaTimes cursor="pointer" />
              </Box>
              <CardInfo3ColumnImageLessGroup
                sectionContent={section.sectionContent[1]}
              />
            </Box>
          </Collapse>
        </>
      )}
    </>
  );
};

export default AllCenter;
