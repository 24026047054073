/* Built In Imports */
/* External Imports */
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import ContentArea from '@components/Layout/ContentArea';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';

/* Services */
/* Styles */

/**
 * Render Global Center Component
 * @param {Object} sectionContent - Data from api
 * @param {string} lang - Language of page
 * @param {string} region - Region of page
 * @returns {ReactElement} GlobalCenter component.
 */
const GlobalCenter = ({ sectionContent, region, lang }) => {
  const GlobalCenterCard = ({ card }) => {
    return (
      <Flex
        flexDir={{
          base: 'column',
          sm: 'column',
          md: 'column',
          lg: 'row',
          xl: 'row',
        }}
      >
        <Box>
          {' '}
          <LazyLoadImageComponent
            w="68"
            h="69"
            borderRadius={'10%'}
            src={card.thumbnail?.url}
            alt={card.thumbnail?.alt}
            title={card.thumbnail?.title}
          />
        </Box>

        <Box>
          <Box
            as={'h2'}
            fontFamily="FedraSansStd-A-medium"
            fontSize="24px"
            fontWeight="500"
            textAlign="left"
            color="#28231e"
            lineHeight="1.42857143"
            pl={{ base: 0, sm: 0, md: 0, lg: 6, xl: 6 }}
          >
            {card.title}
          </Box>
          <Box>
            <Box
              as={'h5'}
              fontFamily="FedraSansStd-book"
              fontSize={{ xl: '16px', base: '14px' }}
              lineHeight="1.5"
              textAlign="left"
              color="#28231e"
              pl={{ base: 0, sm: 0, md: 0, lg: 6, xl: 6 }}
            >
              <ContentArea
                px="0"
                pb="0"
                mt="0"
                font="16px"
                mainContent={card.description}
                region={region}
                lang={lang}
              />
              {/* <Box as={'span'} fontWeight="bold" mr="5px">
                      Telephone:
                    </Box>
                    1300 AU ISHA (284 742) */}
            </Box>
          </Box>

          {/* <Box>
            <Box
              as={'h5'}
              fontFamily="FedraSansStd-book"
              fontSize={{ xl: '16px', base: '14px' }}
              lineHeight="1.5"
              textAlign="left"
              mr={'5px'}
            // fontWeight={'bold'}
            >
              <Box as={'span'} color="#28231e" fontWeight="bold" mr="5px">
                Email:
              </Box>
              <Box as="span" color="#d33100">
                australia@ishafoundation.org
              </Box>
            </Box>
          </Box> */}
        </Box>
      </Flex>
    );
  };

  return (
    <>
      <Box
        className="bgImageOfGlobalCenter"
        id="global-center"
        m="auto"
        background={`url('${config.imgPath}/d/46272/1663582814-quote_pattern-final.jpg') repeat scroll`}
        // background="url(Quote_pattern-final.jpg') repeat scroll"
        objectFit="contain"
        display="grid"
        mt={{ base: '50px', sm: '60px', md: '80px', lg: '100px' }}
      >
        <style global jsx>
          {`
            #global-center a[href^='mailto'] {
              color: #cf4824;
              border-bottom: 2px solid #cf4824;
            }
            #global-center a[href^='mailto']:hover {
              color: white;
              background: #cf4824;
            }
          `}
        </style>
        <Flex
          flexDir={'column'}
          alignItems="center"
          justifyContent="center"
          mx="auto"
        >
          {' '}
          <Box mt={{ base: '50px', sm: '60px', md: '100px', lg: '100px' }}>
            <SectionTitle titleObj={sectionContent[0]} mt="0" />
          </Box>
          <Box
            as={'p'}
            fontFamily="FedraSansStd-book"
            width={{ base: '100%' }}
            fontSize={{ base: '16px' }}
            lineHeight="1.61"
            textAlign="center"
            color="#28231e"
            mb="40px"
            px={3}
            fontWeight="500"
          >
            <StructuredTextParser
              str={render(sectionContent[1]?.body)}
              region={region}
              lang={lang}
            />
          </Box>
        </Flex>
        <Box
          w={{ base: '90%', sm: '70%', md: '672px', lg: '972px', xl: '972px' }}
          mx="auto"
        >
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={10}>
            {sectionContent[2]?.cards?.map(secCon => {
              return <GlobalCenterCard card={secCon} />;
            })}
          </SimpleGrid>
        </Box>

        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          fontSize="18px"
          fontFamily="FedraSansStd-book"
          textAlign="center"
          my="40px"
          px={{ base: '15px', sm: '15px', md: '15px', lg: '15px', xl: '15px' }}
          className="event-text"
        >
          <StructuredTextParser
            str={render(sectionContent[3]?.body)}
            region={region}
            lang={lang}
          />
        </Flex>
      </Box>
    </>
  );
};

export default GlobalCenter;
