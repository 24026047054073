/* Built In Imports */

/* Services */
import { IsoBackendHttpService } from 'utils/IsoBackendHttpService';

let isCache = false;
const API_PREFIX = '/content/fetchiso';
const API_PREFIX_1 = '/content/bigmarker/fetch/webinar/iso';

export const fetchDetails = async (region, lang, slug = ``) => {
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        slug: slug ? `volunteer${slug}` : `volunteer/sadhanapada`,
        region,
        lang,
      },
    }
  );
  return pageData;
};

export const fetchWebinarDetails = async (region, lang) => {
  const type = 'pull';
  const pageData_1 = await new IsoBackendHttpService().get(
    `${API_PREFIX_1}`,
    !isCache,
    {
      params: {
        type,
        format: 'json',
        region,
        lang,
      },
    }
  );
  // consoleLog('webinar data', pageData_1);
  return pageData_1;
};
