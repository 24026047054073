/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import config from '@config';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the Idy Testimonials content component
 * @param {Object} section - Data for section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @returns {ReactElement} Testimonials content component
 */

const IdyTestimonial = ({ section, region, language }) => {
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="50%"
        zIndex="10"
        cursor="pointer"
        bgSize="20px"
        bgColor="#fff"
        border="solid 1px #f4f4f4"
        borderRadius="100%"
        w="46px"
        h="46px"
        boxShadow="0 0 7px 0 rgb(0 0 0 / 13%)"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url('${config.imgPath}/d/46272/1652294891-right-black-arrow.png')`}
        right="-23px"
        onClick={onClick}
      ></Box>
    );
  };
  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="50%"
        zIndex="10"
        cursor="pointer"
        bgSize="20px"
        bgColor="#fff"
        border="solid 1px #f4f4f4"
        borderRadius="100%"
        boxShadow="0 0 7px 0 rgb(0 0 0 / 13%)"
        w="46px"
        h="46px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url('${config.imgPath}/d/46272/1652294895-left-black-arrow.png')`}
        left={{ base: '-20px', md: '-23px' }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 11000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="12px"
          h="12px"
          m="0px 8px"
          bgColor="#c4c4c4"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  return (
    <Box as="div">
      <Box
        maxW="100%"
        m="0 auto 0 auto"
        overflow="hidden"
        p={{ base: '5px 35px 0 23px', md: '20px 35px 0 35px' }}
      >
        <SectionTitle
          titleObj={section[0]}
          maxW="500px"
        />
        <Slider {...settings} m="0">
          {section[1]?.testimonials?.map((data, i) => {
            return (
              <Box
                overflow="hidden"
                p="50px 5% 0 5%"
                key={nanoid()}
                textAlign="left"
                fontFamily="FedraSansStd-book"
                fontSize="14px"
                lineHeight="27px"
                width="33%"
                display="flex"
                flexWrap="wrap"
                position="relative"
                margin="0 15px 30px 15px"
                _first={{ p: '50px 6% 0 0' }}
              >
                <Box
                  as="div"
                  position="absolute"
                  top="33px"
                  left="10%"
                  textAlign="left"
                  mb="24px"
                  zIndex="20"
                  height="50px"
                >
                  <Image
                    src={`${config.imgPath}/d/46272/1653093606-icon_doublequote.png`}
                    alt={'double quote'}
                    title={'double quote'}
                  />
                </Box>
                <Box
                  as="div"
                  background="#F0F0F0"
                  borderRadius="15px"
                  minH="555px"
                >
                  <Box as="div" textAlign="center">
                    <Image
                      loading="lazy"
                      src={data?.photo?.url}
                      height="168px"
                      width="168px"
                      borderRadius="50%"
                      mt="25px"
                      title={data?.photo?.title}
                      alt={data?.photo?.alt}
                    />
                  </Box>
                  <Box
                    as="div"
                    p="20px"
                    fontFamily="FedraSansStd-book"
                    color="#000000"
                  >
                    {data.testimonialText}
                    {data?.userName && (
                      <Box
                        marginTop="15px"
                        marginBottom="20px"
                        textAlign="left"
                        color="#CF4520"
                        fontWeight="500"
                        fontFamily="FedraSansStd-medium"
                      >
                        {data?.userName} {data?.userDescription}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Slider>
      </Box>
      <style jsx global>{`
        .slick-dots li.slick-active p {
          background: #858585;
        }
        ul.slick-dots {
          display: none !important;
        }
        @media (max-width: 767px) {
          ul.slick-dots {
            display: block !important;
            bottom: 0px;
            padding-bottom: 8px;
          }
        }
      `}</style>
    </Box>
  );
};

export default IdyTestimonial;
