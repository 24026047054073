/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
/* External Imports */
import { Flex, Box, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

const MediaEmbedV2 = dynamic(() => import('@components/Embeds/MediaEmbedV2'));

/**
 *
 * @param section
 * @param region
 * @param lang
 */

const FeaturesPBK = ({
  section,
  region,
  lang
}) => {
  return (
    <Box mt={{base:'10px', md:'40px'}}>
        <Box maxW={{base:'246px', md:'385px'}} m="0 auto"><SectionTitle titleObj={section?.sectionContent[0]} /></Box>
        <Box maxW={{base:'90%', md:'600px'}} m="0 auto">
          <StructuredTextParser
              str={render(section?.sectionContent[1]?.body)}
              region={region}
              lang={lang}
              color="#28231E"
              lineHeight="30px"
              fontFamily="'FedraSansStd-book', sans-serif"
              fontSize={{ base: "16px", md: "18px" }}
              mt="30px"
              mb="50px"
            />
        </Box>
        <Box maxW={{base:'246px', md:'445px'}} m="0 auto"><SectionTitle titleObj={section?.sectionContent[2]} /></Box>
        <Box maxW={{base:'90%', md:'600px'}} m="0 auto">
            <StructuredTextParser
              str={render(section?.sectionContent[3]?.body)}
              region={region}
              lang={lang}
              color="#28231E"
              lineHeight="30px"
              fontFamily="'FedraSansStd-book', sans-serif"
              fontSize={{ base: "16px", md: "18px" }}
              mt="30px"
              mb="50px"
            />
        </Box>
        <Box pos={"relative"} maxWidth={'762px'} margin={'0 auto'}>
            <Box sx={{
              ":after": {
                content: '""',
                position: 'absolute',
                width: { base: '90%', md: '100%' },
                left: '0',
                right: '0',
                margin: '0 auto',
                bottom: { base: '15px', md: '27px' },
                zIndex: '-1',
                transform: 'scale(.9)',
                boxShadow: '10px 3px 39px 10px #000000',
              }
            }}>
              <MediaEmbedV2
                sectionContent={section.sectionContent[4]}
                maxW="100%"
                margin={{ base: "20px auto 25px", md: "55px auto 50px" }}
                width={{ base: "90%", md: "762px" }}
              />
            </Box>
        </Box>
        <Box maxW={{base:'246px', md:'445px'}} m="0 auto"><SectionTitle titleObj={section?.sectionContent[5]} /></Box>
        <Box  className="about_dhalinga" maxW={{base:'90%', md:'600px'}} m="0 auto">
            <StructuredTextParser
              str={render(section?.sectionContent[6]?.body)}
              region={region}
              lang={lang}
              color="#28231E"
              lineHeight="30px"
              fontFamily="'FedraSansStd-book', sans-serif"
              fontSize={{ base: "16px", md: "18px" }}
              mt="30px"
              mb="20px"
            />
        </Box>
        <style>
              {`
                .about_dhalinga .css-vpkyow {
                    /* top: -70px; */
                    /* left: 90px; */
                    top: -36px;
                    left: 0px;
                    text-align: left;
                }
                .about_dhalinga .css-vpkyow a {
                    border: none !important;
                    color: #CA4E2A;
                    font-family: FedraSansStd-medium !important;
                    text-decoration: underline !important;
                    margin-bottom: 5px;
                    text-align: left;
                    /* padding-left: 10px; */
                    padding-left: 0px;
                }
                .about_dhalinga .css-vpkyow a:hover {
                    background: none;
                    color: #CA4E2A !important;
                    text-dection: underline;
                }
                .about_dhalinga .css-vpkyow a svg {
                    display: none;
                }
             `}
            </style>
    </Box>
  );
};

export default FeaturesPBK;
