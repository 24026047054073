/* Built In Imports */
/* External Imports */
import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box,
    chakra
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';


/* Services */

/**
 * Renders the Faq Linked Content component.
 *
 * @param mainSection.mainSection
 * @param {object} mainSection - Container Object of FAQ's.
 * @param {Array} faqSection - Main FAQ data.
 * @param mainSection.faqSection
 * @param mainSection.region
 * @param mainSection.lang
 * @returns {ReactElement} FaqLinkedContent component.
 */
export default function FaqLinkedContent({
  mainSection,
  faqSection,
  region,
  lang,
}) {
  return (
    <Box
      fontFamily="FedraSansStd-book"
      fontSize="1.1rem"
      lineHeight="1.61"
      width="100%"
      m="0 auto 3rem auto"
    >
      <Box textAlign="center" mb="1.5rem">
        <SectionTitle titleObj={mainSection.sectionContent[0]} />
      </Box>
      {faqSection?.map(fs => {
        return (
          <FaqSection
            key={nanoid(4)}
            faqSection={fs}
            region={region}
            lang={lang}
          />
        );
      })}
    </Box>
  );
}

const FaqSection = ({ faqSection, region, lang }) => {
  return (
    <Accordion
      key={nanoid(4)}
      allowMultiple
      width={{ base: '100%' }}
      m={{ base: '0 auto', lg: '0', md: '0' }}
    >
      <AccordionItem borderBottom="none">
        <AccordionButton
          bgColor="#000054"
          borderRadius="3px"
          mb="2px"
          fontFamily="FedraSansStd-medium"
          fontSize="20px"
          borderColor="#ffffff"
          padding="10px 13px 10px 13px"
          color="white"
          _hover={{ bgColor: '#000054', color: '#ffffff' }}
          _focus={{ shadow: 'transparent' }}
          minHeight="55px"
          lineHeight="1.5"
        >
          <chakra.div flex="1" textAlign="left">
            {faqSection.title}
          </chakra.div>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={1}>
          {faqSection?.faqs?.map(faqs => {
            return (
              <FaqSectionChildren
                key={nanoid(4)}
                faqs={faqs}
                region={region}
                lang={lang}
              />
            );
          })}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const FaqSectionChildren = ({ faqs, region, lang }) => {
  return (
    <AccordionItem
      key={nanoid(4)}
      borderBottomWidth="1px"
      borderBottomColor="gray.400"
      borderTop="none"
      lineHeight="1.61"
      border="none"
    >
      <AccordionButton
        pt="0.8rem"
        pb="0.8rem"
        fontFamily="global.fontFamily.md"
        fontSize="1.1rem"
        borderTop="none"
        border="none"
        _hover={{ background: 'none' }}
        _focus={{ shadow: 'transparent' }}
      >
        <chakra.div flex="1" textAlign="left" key={nanoid(4)}>
          {faqs.question}
        </chakra.div>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel
        pb={2}
        fontSize="1.1rem"
        fontFamily="FedraSansStd-book"
        lineHeight="1.61"
      >
        <Box
          display="block"
          width="100%"
          lineHeight="1.61"
          fontSize={{ lg: '18px', sm: '16px' }}
          color="#28231e"
        >
          <Box className="event-text">
            <StructuredTextParser
              str={render(faqs.answer)}
              region={region}
              lang={lang}
            />
          </Box>
        </Box>
      </AccordionPanel>
      <style global jsx>
        {`
          .faq a {
            color: #cf4520;
          }
          .faq span.setting:after {
            font-family: fontawesome;
            font-size: 1.6rem;
            content: '\f013';
            color: #fff;
            background: rgba(31, 41, 55, var(--tw-bg-opacity));
            border-radius: 18px;
            padding: 7px 8px 6px;
            --tw-bg-opacity: 1;
            vertical-align: middle;
          }
          .faq ul {
            padding-left: 1.5em;
          }
          .faq p {
            padding-bottom: 20px;
          }
        `}
      </style>
    </AccordionItem>
  );
};
