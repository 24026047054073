/* Built In Imports */
/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import SectionTitle from '@components/Headings/SectionTitle';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/**
 * Returns the IdyBenefits.
 * @param {Object} sectionContent - Content from API
 * @param {string} language - Language of the page
 * @param {string} region - Region of the page
 * @returns {ReactElement} Banner component
 */
const IdyBenefits = ({ sectionContent, region, language }) => {
  // console.log('IdyBenefits', sectionContent);
  return (
    // <Box display={'grid'} pos={'relative'} h={{ base: '100%', md: 'auto' }}>
    <Box
      as="div"
      fontSize="18px"
      m="0 auto 80px"
      width="100%"
      mb={{ md: '40px', sm: '15px' }}
      bgColor="#F5F0E5"
      py={{ base: "80px", md: "60px" }}
      w="100%"
    >
      {
        sectionContent[0]?.title?.length > 0 && (
          <SectionTitle titleObj={sectionContent[0].title[0]} />
        )
      }
      < Box
        as="div"
        display="flex"
        flexWrap="wrap"
        // justifyContent={{ md: 'center', base: 'normal' }}
        // alignItems={{ md: 'center', base: 'normal' }}
        margin="40px auto 0 auto"
        fontSize="18px"
        gap={{ base: "40px", md: "10px" }
        }
        maxW="1050px"
      // width={{ base: '100%', md: '737px', lg: '933px' }}
      >
        {
          sectionContent[0]?.benefits?.map(data => {
            return (
              <Box
                //turn this on after you have done
                flex={{
                  base: '100%',
                  md: '30%',
                }}
                // p="0 10px"
                // alignItems="center"
                display={{
                  base: 'block',
                  md: 'block',
                }}
                width="calc((100%)/3)"
                marginBottom={{ base: '26.07px', md: '33px', lg: '36px' }}
                // display="block"
                ml={{ base: '0', md: '26px' }}
                // h={{ md: '100px', lg: '100px' }}
                mb={{ base: '10px', lg: '53px', md: '80px' }}
              >
                <Flex
                  alignItems="center"
                  justifyContent={{ base: 'flex-start', md: 'center' }}
                  // as="div"
                  minWidth={{ base: '37.17px', md: '100%', lg: '100%' }}
                  maxW={{ base: '70px', md: '100%', lg: '100%' }}
                  mr="17.93px"
                  textAlign={{ base: 'left', md: 'center' }}
                  maxH={{ md: '100px', lg: '100px' }}
                  m="0 auto"
                >
                  <LazyLoadImageComponent
                    loading="lazy"
                    src={data?.image?.url}
                    title={data?.image?.title}
                    minHeight={{
                      base: '44px',
                      md: '70px',
                    }}
                    minW={'31px'}
                    w={{ base: "auto", md: "65px" }}
                    objectFit={{ base: 'contain', md: 'contain' }}
                    m={{ base: '15px auto', md: '0 0 22px 0' }}
                  />
                </Flex>
                <Text
                  fontFamily="FedraSansStd-book, sans-serif"
                  fontSize={{
                    base: '16px',
                    md: '18px',
                  }}
                  color="#28231E"
                  m={{ base: '0 auto', lg: '0 auto 15px' }}
                  textAlign={{
                    base: 'center',
                    md: 'center',
                  }}
                  pt={{ base: '5px', sm: '10px', md: '0' }}
                  maxW="235px"
                >
                  {data?.text}
                </Text>
              </Box>
            );
          })
        }
      </Box >

      {
        sectionContent[1]?._modelApiKey === 'simple_button' && (
          <Box m="30px 0">
            <SimpleButton
              style={sectionContent[1]}
              bType={section.sectionId}
              region={region}
              lang={language}
            />
          </Box>
        )
      }

    </Box >
  );
};

export default IdyBenefits;
