/* Built In Imports */
/* External Imports */
import { Select } from '@chakra-ui/react';
// import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */

/* Services */

/**
 * Renders the Select UI component.
 * @param {string} field - field details.
 * @param {string} id - id.
 * @param {string} placeholder - placeholder.
 * @param {string} wd - width.
 * @param {Boolean} textArea - if the input is an textarea.
 * @param {Object} props - more styling properties
 * @param {string} options - Options for the Select component
 * @returns {ReactElement} SelectUI component.
 */

const SelectUI = ({
  field,
  id,
  placeholder,
  wd,
  options,
  values,
  ...props
}) => {
  return (
    <Select
      {...field}
      name={id}
      id={id}
      variant="flushed"
      w={wd}
      fontFamily="'FedraSansStd-book', sans-serif"
      fontSize={props.fontSize ? props.fontSize : '14px !important'}
      color={props.colors ? props.colors : '#28231e'}
      borderRadius={props.borderRadius || '3px'}
      borderBottom={props.borderwidth ? props.borderwidth : '1px solid #aea493'}
      backgroundColor="#fff"
      textTransform="capitalize"
      padding="0 0 0 5px"
      height={props.heights ? props.heights : 'auto'}
      // defaultValue={options[0].value} // assigns default to first object in array
      _placeholder={{
        color: 'grey',
        fontSize: `${props.fontSize ? props.fontSize : '12px'}`,
      }}
      {...props}
    >
      {options.map((option, index) => (
        <option
          id={id}
          key={index}
          // value={values ? option.label : option.value}
          value={option.value}
          fontSize="16px"
        >
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default SelectUI;
