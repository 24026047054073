/* Built In Imports */
/* External Imports */
import { Box, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SingleImage from '@components/Card/SingleImage';
import SectionTitle from '@components/Headings/SectionTitle';
import ContentArea from '@components/Layout/ContentArea';
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the Seating Reg Section Section component
 *
 * @param {object} sectionContent - Data for section
 * @param sectionContent.section
 * @param {string} region - Region for the section
 * @param {string} lang - Language for the section
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} Seating Reg Section Section component
 */
const SeatingRegSection = ({ section, region, lang }) => {
  return (
    <MidContainer>
      <SectionTitle titleObj={section?.sectionContent[0]} />
      <Box className="event-text">
        <ContentArea mainContent={section?.sectionContent[1]?.body}
          region={region}
          lang={lang}
        />
      </Box>
      <Box mx="auto">
        <SingleImage sectionContent={section?.sectionContent[2]} />
      </Box>

      <TableContainer mb="30px">
        <Table variant='whiteAlpha'>
          <Tbody
            fontFamily="FedraSansStd-book"
          >
            <Tr              
              p="0.35em"
              fontSize="18px"
            >
              {section.sectionContent[3] &&
                section.sectionContent[3].data &&
                (section.sectionContent[3].data)?.map((item, index) => {
                  return (
                    <Td>
                      <Table variant='whiteAlpha'>
                        {item &&
                          Object.keys(item)?.map((field, index) => {
                            return (
                              <Tr
                                border="0"
                                fontSize="18px"
                              >
                                <Td
                                  borderRight="1px solid rgb(221 221 221)"
                                  fontSize={index % 2 === 0 ? '30px' : ''}
                                  fontFamily={index % 2 === 0 ? 'FedraSansStd-medium' : ''}
                                  textAlign="center"
                                  pt="40px"
                                  pb="29px"
                                  color="#464038"
                                >                                  
                                  {item[field]}
                                </Td>
                              </Tr>
                            )
                          }
                          )}
                      </Table>
                    </Td>
                  );
                })
              }
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
        
    </MidContainer>
  );
};

export default SeatingRegSection;
