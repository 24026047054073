/* Built In Imports */
import { createContext, useContext, useState } from 'react';

const DonateFormContext = createContext();

const DonateFormProvider = ({ children }) => {
  const [formActive, setFormActive] = useState(false);

  const toggleFormActive = e => {
    e.preventDefault();
    setFormActive(prev => !prev);
  };

  return (
    <DonateFormContext.Provider value={{ formActive, toggleFormActive }}>
      {children}
    </DonateFormContext.Provider>
  );
};

export const useDonateFormContext = () => {
  return useContext(DonateFormContext);
};

export { DonateFormContext, DonateFormProvider };

