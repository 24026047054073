/* Built In Imports */
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
/* External Imports */
import { Flex, Box, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import TopKolam from '@components/Headings/TopKolam';
import YsBenefitsTitle from '@components/Text/YsBenefitsTitle';

/**
 *
 * @param section
 * @param region
 * @param lang
 */

const HighlightBlockPBK = ({
  section,
  region,
  lang,
  currentRegion
}) => {
  return (
    <Box
        display="flex"
        justifyContent="center"
        w="full"
        maxW="1170px"
        flexDir={{ base: 'column', md: 'row' }}
        mx="auto"
        alignContent="center"
    >
        {section?.benefits?.map(data => {
        return (
            <Box
            key={``}
            mb={{ base: '10px', md: '30px', lg: '53px' }}
            flexBasis={{ base: '90%', md: '30%' }}
            >
            <Flex alignItems="center" justifyContent="center">
                <LazyLoadImageComponent
                loading="lazy"
                src={data?.image?.url}
                title={data?.image?.title}
                height={{
                    base: '289px',
                    md: '320px',
                }}
                minW={{ md: '200px', lg: '274px' }}
                objectFit={{ base: 'contain', md: 'cover' }}
                m={{ base: '0 0 22px 0' }}
                />
            </Flex>
            <Text
                fontFamily="FedraSansStd-book, sans-serif"
                fontSize={{
                base: '16px',
                md: '18px',
                }}
                width={{ base: '75%', md: '90%', lg: '80%' }}
                color="#fff"
                mx="auto"
                textAlign="center"
                pt={{ base: '5px', sm: '10px', md: '0' }}
            >
                {data?.text}
            </Text>
            </Box>
        );
        })}
      </Box>
  );
};

export default HighlightBlockPBK;
