/* Built In Imports */
/* External Imports */
import {
  AspectRatio, Box, Image,
  Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalOverlay, useDisclosure
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';

/* Services */
/* Style */

/**
 * Renders Why Sp Component
 *
 * @param root0
 * @param root0.section
 * @param root0.region
 * @param root0.lang
 * @returns {ReactElement} - Why SP Component
 */
export default function WhySP({ section }) {
  const secCon = section.sectionContent;
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box
      bgColor="#fff"
      float="left"
      width="100%"
      maxW={'1142px'}
      textAlign="center"
    >
      <Box
        position="relative"
        width={{ base: '100%' }}
        display="block"
        // clipPath="polygon(0 0, 100% 0, 100% 75%, 0 90%)"
      >
        <Box
          position="absolute"
          width={{ base: '100%' }}
          height={{ base: '50vh', lg: '65vh' }}
          bgColor="#ede9de"
          clipPath="polygon(0 0, 100% 0, 100% 75%, 0 90%)"
        ></Box>
      </Box>
      <Box
        textAlign="center"
        pos="relative"
        w={{ base: '80%', lg: '100%' }}
        pt="10px"
        m="0 auto"
      >
        <SectionTitle titleObj={secCon[0]} />
      </Box>
      <Box w="100%" display="flex" justifyContent="center" pt="10px" pb="20px">
        <Box
          zIndex="1"
          w={{ base: '90%', md: '75%', sm: '75%', lg: '75%' }}
          ratio={1}
          h={{
            base: 'auto',
          }}
          boxShadow="11px 29px 35px -3px rgba(225,225,225,0.84)"
          display="inline-block"
          pos="relative"
          background="#e1e1e1"
        >
          <Box>
            <Image
              cursor="pointer"
              onClick={onOpen}
              zIndex="0"
              alt={secCon[1]?.image?.alt}
              title={secCon[1]?.image?.title}
              src={secCon[1]?.image?.url}
            />
          </Box>

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay bgColor="#000000" />
            <ModalContent bgColor="transparent" maxW="container.sm">
              <ModalCloseButton
                top={{ base: '-34px', md: '-38px', lg: '-20px' }}
                right={{ base: '12px', md: '8px', lg: '-70px' }}
                color="#fff"
                fontSize={{ base: '18px', md: '24px', lg: '24px' }}
                _focus={{
                  boxShadow: 'none',
                }}
              />
              <ModalBody p="0" m={{ base: '0 10px', md: '0', lg: '0' }}>
                <AspectRatio>
                  <iframe
                    src={secCon[2]?.embedUrl}
                    title="Sadhanapada video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ 'z-index': '1' }}
                    width="auto"
                  ></iframe>
                </AspectRatio>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
}
