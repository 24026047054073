/* Built In Imports */
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
/* External Imports */
import { Flex, Box, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import TopKolam from '@components/Headings/TopKolam';
import YsBenefitsTitle from '@components/Text/YsBenefitsTitle';
import HighlightBlockPBK from './HighlightBlockPBK';

/**
 *
 * @param section
 * @param region
 * @param lang
 */

const HighlightsPBK = ({
  section,
  region,
  lang,
  currentRegion
}) => {
  return (
    <Box pt="65px" background={'linear-gradient(180deg, #131313 50%, #000000 100%)'}>
        <YsBenefitsTitle sectionContent={section?.sectionContent[0]} pt="10px" />
        <Box display="flex" justifyContent="center" alignContent="center">
          <TopKolam margin="30px auto 45px" sectionContent={section?.sectionContent[1]} />
        </Box>
        <HighlightBlockPBK section={section?.sectionContent[2]}/>
    </Box>
  );
};

export default HighlightsPBK;
