/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, SimpleGrid } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */

import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import { refineUrlDomain } from '@components/Utility/utils';
import { Styles } from '../../constants';

/* Services */

/**
 * Renders the DonationEnquiryCard component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region of the page
 * @param {string} lang - Language of the page
 * @param isLeftMenu - responsive navbar for the page
 * @returns {ReactElement} DonationEnquiryCard component
 */

const DonationEnquiryCard = ({ sectionContent, region, lang, isLeftMenu }) => {
  const bgColors = Styles.bgColors;
  return (
    <Box
      mx="auto"
      maxW={{
        base: '302px',
        sm: '480px',
        md: '690px',
        lg: '770px',
        xl: '1170px',
      }}
    >
      <SimpleGrid
        columns={{
          base: 1,
          md: 2,
          lg: isLeftMenu ? 3 : 2,
          xl: isLeftMenu
            ? 3
            : (sectionContent?.cards?.length > 2 ? 3
            : sectionContent?.cards?.length)
        }}
        spacing="30px"
        mx="auto"
      >
        {sectionContent?.cards?.map((secCon, index) => {
          return (
            <Box
              id="card3"
              key={nanoid()}
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              mx="auto"
              w={{
                base: '100%',
                sm: '330px',
                // lg: '370px',
                lg: isLeftMenu ? '280px' : '370px',
              }}
              position="relative"
            >
              <NextLink
                href={refineUrlDomain(secCon.buttonLink, region, lang)}
                passHref
                legacyBehavior>
                <Link cursor={secCon.buttonLink ? 'cursor' : 'auto'}>
                  <LazyLoadImageComponent
                    src={secCon.thumbnail?.url}
                    alt={secCon.thumbnail?.alt}
                    title={secCon.thumbnail?.title}
                    objectFit="cover"
                    w="100%"
                    minH="210px"
                    maxH={218}
                  />
                </Link>
              </NextLink>

              <Box
                w="90%"
                h={{ base: 235, sm: 240, lg: '244px' }}
                objectFit="contain"
                transform="rotate(-178.76deg)"
                position="absolute"
                left="13.5px"
                background={bgColors[index % bgColors.length]}
                outline="1px solid transparent"
                zIndex="-1"
                top="-10px"
              ></Box>

              <Box m={{ base: '0 29px' }}>
                {/* <Box height="20px" bgColor={bgColors[index % 4]}></Box> */}
                <Box
                  lineHeight={1.1}
                  fontFamily="FedraSansStd-medium"
                  fontSize="20px"
                  mt="15px"
                  color="#28231e"
                >
                  {secCon.title}
                </Box>
                <Box
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  color={'gray.200'}
                  pt={15}
                >
                  {StructuredTextContent(secCon.description)}
                </Box>
                {secCon.buttonLink && (
                  <Box>
                    <NextLink
                      href={refineUrlDomain(secCon.buttonLink, region, lang)}
                      passHref
                      legacyBehavior>
                      <Link
                        bgColor="transparent"
                        color="#cf4520"
                        mr={{ md: '20px' }}
                        padding="5px 30px"
                        border="1px solid #cf4520"
                        borderRadius="5px"
                        display="inline-block"
                        my={19}
                        fontFamily="FedraSansStd-medium"
                        fontSize="13px"
                        _hover={{
                          color: '#fff',
                          bgColor: '#cf4520',
                          textDecoration: 'none',
                        }}
                        _focus={{
                          color: '#fff',
                          bgColor: '#cf4520',
                          textDecoration: 'none',
                        }}
                        _active={{
                          color: '#fff',
                          bgColor: '#cf4520',
                          textDecoration: 'none',
                        }}
                      >
                        {secCon.buttonText}
                      </Link>
                    </NextLink>
                  </Box>
                )}
              </Box>
              <style global jsx>
                {`
                  #card3 a {
                    color: #cf4824 !important;
                  }
                  #card3 a:hover {
                    color: #ffffff !important;
                  }
                `}
              </style>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default DonationEnquiryCard;
