/* Built In Imports */
import NextLink from 'next/link';
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { Box, Button, Flex, Input, Link, Text } from '@chakra-ui/react';
import forEach from 'lodash/forEach';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Services */
/**
 * Renders the TeacherCard Card component
 *
 * @param cards.cards
 * @param {object} cards - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @param {string} isLeftMenu - Responsive navbar for the page
 * @param cards.region
 * @param cards.lang
 * @param cards.isLeftMenu
 * @returns {ReactElement} TeacherCard component
 */
const TeacherCard = ({ cards, region, lang, isLeftMenu }) => {
  let count = 4;

  const [viewCards, setViewCards] = useState([]);
  const [currentCards, setCurrentCards] = useState([]);
  let [isSearch, setIsSearch] = useState(1);
  const loadtext = 'Load More';
  const [search, setSearch] = useState('');
  const searchBoxRef = useRef();

  const handleLoadMore = () => {
    if (cards?.length) {
      let slicedCards = [];

      if (search) {
        slicedCards = cards.filter(data => {
          return (
            data?.teacher_name?.toLowerCase().indexOf(search?.toLowerCase()) >
              -1 ||
            data?.business_name?.toLowerCase().indexOf(search?.toLowerCase()) >
              -1
          );
          // }
        });
        // consoleLog('slicedCards', slicedCards);
        if (!slicedCards.length) {
          slicedCards = cards.filter(data => {
            let addressList = [];
            forEach(data.business_address, function (address) {
              if (
                address?.city?.toLowerCase().indexOf(search?.toLowerCase()) >
                  -1 ||
                address?.state?.toLowerCase().indexOf(search?.toLowerCase()) >
                  -1 ||
                address?.country?.toLowerCase().indexOf(search?.toLowerCase()) >
                  -1
              ) {
                addressList.push(address);
              }
            });
            return addressList.length > 0;
          });
        }
        setCurrentCards(slicedCards);
      } else {
        setCurrentCards(cards);
      }

      // pagination
      if (slicedCards?.length) {
        slicedCards = slicedCards.slice(
          viewCards?.length,
          viewCards?.length + count
        );
      } else {
        if (search) {
          slicedCards = [];
        } else {
          slicedCards = cards.slice(
            viewCards?.length,
            viewCards?.length + count
          );
        }
      }
      setViewCards(prev => [...prev, ...slicedCards]);
    }
  };

  useEffect(() => {
    handleLoadMore();
  }, [search, cards, isSearch]);

  const handleSearchFilter = e => {
    // consoleLog('handleSearchFilter', e.target.value, searchBoxRef?.current?.value, isSearch);
    e.preventDefault();
    setViewCards([]);
    setIsSearch(++isSearch);
    setSearch(e?.target?.value?.trim() || searchBoxRef?.current?.value?.trim());
  };

  return (
    <>
      <Box
        position={{ base: 'relative' }}
        right={{ base: 'inherit', xl: 'inherit' }}
        top={{ base: 'unset', md: '5px', xl: 'unset' }}
        padding={{
          base: '10px 15px !important',
          sm: '10px 20px !important',
        }}
        height="45px"
        mb="20px"
        // ml={{ base: 0, sm: '15px !important' }}
        minWidth={{ base: '250px', md: '275px', xl: '250px' }}
        outline="unset"
        lineHeight="1.42857143"
        w={{ base: 'auto', sm: '95%', lg: '100%' }}
        userSelect="none"
        _hover={{
          color: '#cf4520',
        }}
        order={{
          base: '3',
          sm: '3',
          md: '1',
          xl: '1',
        }}
        display={{
          base: 'inherit',
          sm: 'inline-block',
          xl: 'inherit',
        }}
      >
        <Box>
          <Input
            variant="unstyled"
            defaultValue={search}
            float="right"
            key={search}
            borderWidth="0 0 1px 0 !important"
            fontFamily="FedraSansStd-book"
            fontSize="16px"
            w="250px"
            borderColor="#CF4523 !important"
            borderRadius="0"
            // value={search}
            p="5px"
            ref={searchBoxRef}
            placeholder="Search ..."
            onKeyUp={e => {
              if (e.keyCode === 13) {
                handleSearchFilter(e);
              }
            }}
            color="#333333"
            _placeholder={{
              color: '#333333',
            }}
          />
          <Text
            as="span"
            background={`url('${config.staticPath}/d/46272/1663582866-search.svg') no-repeat`}
            // background="url(search.svg') no-repeat"
            backgroundSize="auto auto"
            width="24px"
            height="24px"
            cursor="pointer"
            position="absolute"
            margin="0"
            borderWidth="0 !important"
            float="right"
            right="15px"
            onClick={e => handleSearchFilter(e)}
            top="15px"
            data-value1="data"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        gridGap="10px"
        gridRowGap="35px"
      >
        {viewCards && viewCards.length ? (
          viewCards.map(viewCard => {
            return (
              <Box
                key={nanoid()}
                w={
                  isLeftMenu
                    ? { base: '302px', sm: '330px', md: '420px' }
                    : { base: '302px', sm: '330px', md: '420px', lg: '590px' }
                }
              >
                <Card content={viewCard} region={region} lang={lang} />
              </Box>
            );
          })
        ) : (
          <Box
            w="100%"
            p="5%"
            textAlign="center"
            display="grid"
            placeContent="center"
            placeItems="center"
            fontFamily="FedraSerifAStdBook"
            fontSize="22px"
            color="#333"
            lineHeight="1.42857143"
          >
            No teachers here
          </Box>
        )}
      </Box>
      <Box>
        {viewCards.length && viewCards?.length < currentCards?.length ? (
          <Box m="40px 0" w="100%" display="flex" placeContent="center">
            <Button
              fontWeight={500}
              fontFamily="FedraSansStd-medium"
              fontSize="18px"
              padding="10px 36px"
              bgColor="transparent"
              border="1px solid #cf4520"
              color="#cf4520"
              textDecoration="none"
              outline="0"
              _hover={{
                textDecoration: 'none',
                bgColor: '#cf4520',
                color: 'white',
                outline: 0,
              }}
              onClick={handleLoadMore}
            >
              {loadtext}
            </Button>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

const Card = ({ content, region, lang }) => {
  const { street1, street2, country, state, city, email, phone } =
    content.business_address[0];
  const address = `${street1 ? `${street1}, ` : ''}${
    street2 ? `${street2}, ` : ''
  }${city ? `${city}, ` : ''}${state ? `${state}, ` : ''}${
    country ? `${country}` : ''
  }`;

  return (
    <Flex
      flexDirection="column"
      marginBottom="10px"
      mr={{ md: '15px', base: '0' }}
      ml={{ md: '15px', base: '0' }}
      p="15px 10px"
      bg="white"
      h="100%"
      boxShadow="rgba(0, 0, 0, 0.3) 0px 12px 15px -6px"
    >
      <Box w="100%">
        <Text
          as="h2"
          textAlign={'center'}
          fontSize="27px"
          fontFamily={'FedraSansStd-A-medium'}
        >
          {content.teacher_name}
        </Text>
      </Box>
      <Box margin="15px 0px">
        {address && (
          <Flex flexWrap={'wrap'} mb="10px">
            <Flex flex={'16.6%;'} alignItems="center">
              <LazyLoadImageComponent
                src={`${config.imgPath}/d/46272/1663582862-hata-checkin.svg`}
                // src="https://isha.sadhguru.org/profiles/isha/themes/ishatheme/assets/hata-checkin.svg"
                m="0 auto"
                title="location icon"
                alt="location icon"
                w="auto"
              />
            </Flex>
            <Box flex="83.4%" fontFamily={'FedraSansStd-book'}>
              {address}
            </Box>
          </Flex>
        )}
        {phone && (
          <Flex flexWrap={'wrap'} mb="10px">
            <Flex flex={'16.6%;'}>
              <LazyLoadImageComponent
                src={`${config.imgPath}/d/46272/1663582859-hata-phone.svg`}
                m="0 auto"
                w="auto"
                title="phone icon"
                alt="phone icon"
              />
            </Flex>
            <Box flex="83.4%" fontFamily={'FedraSansStd-book'}>
              {phone}
            </Box>
          </Flex>
        )}
        {email && (
          <Flex flexWrap={'wrap'} mb="10px" fontFamily={'FedraSansStd-book'}>
            <Flex flex={'16.6%;'}>
              <LazyLoadImageComponent
                src={`${config.imgPath}/d/46272/1663582854-hata-message.svg`}
                m="0 auto"
                w="auto"
                title="icon"
                alt="icon"
              />
            </Flex>
            <Box
              flex="83.4%"
              color={'#cf4520'}
              fontSize="16px"
              lineHeight="1.5"
            >
              <Link
                w="auto"
                fontFamily={'FedraSansStd-book'}
                _hover={{ borderBottom: '1px solid #cf4520' }}
                href={`mailto:${email}`}
              >
                {email}
              </Link>
            </Box>
          </Flex>
        )}
        <Flex flexWrap={'wrap'} mb="10px">
          <Flex flex={'16.6%;'}>
            <LazyLoadImageComponent
              src={`${config.imgPath}/d/46272/1663582852-hata-home.svg`}
              m="0 auto"
              w="auto"
              title="icon"
              alt="icon"
            />
          </Flex>
          <Box
            flex="83.4%"
            color={'#cf4520'}
            fontSize="16px"
            lineHeight="1.5"
            fontFamily="FedraSansStd-book"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            <NextLink
              href={`/${region}/${lang}/redirect-to-site?link=${encodeURI(
                content?.website
              )}`}
              passHref
              legacyBehavior>
              <Link
                fontFamily={'FedraSansStd-book'}
                _hover={{ borderBottom: '1px solid #cf4520' }}
                w="auto"
                height={{ base: '105px' }}
                target="_blank"
              >
                {content?.website}
              </Link>
            </NextLink>
          </Box>
        </Flex>
      </Box>
      <Box w="100%" h="1px" bg="#d0011b" display="block"></Box>
      <Flex justifyContent={'space-between'} flexWrap="wrap">
        <Box flex="16.6%" textAlign={'center'}>
          <LazyLoadImageComponent
            maxW="24px"
            maxH="24px"
            title="icon"
            w="24px"
            alt="rollover position"
            mt="-3px"
            src={`${config.imgPath}/d/46272/1663582849-hata-bookmark-rollover-marked.svg`}
            className="rollover-position"
          />
        </Box>
        <Box
          padding="10px 0px 0px"
          fontSize="16px"
          lineHeight="1.5"
          color="rgb(74, 74, 74)"
          flex={'83.4%'}
          fontFamily={'FedraSansStd-book'}
        >
          {content?.practices?.join(', ')}
        </Box>
      </Flex>
    </Flex>
  );
};

export default TeacherCard;
