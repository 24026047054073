/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import Image from 'next/image';

/* External Imports */
import { Box, Heading, useBreakpointValue, Text, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { isOpenInNewTab, refineUrlDomain } from '@components/Utility/utils';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser13';
import StructuredTextContent from '@components/UI/StructuredTextContent';

/* Services */

/**
 * Renders the LandingPageTopBanner component.
 *
 * @param src
 * @param width
 * @param quality
 */

const imageLoader = ({ src, quality }) => {
  return `${src}?q=${quality || 75}`
}
const LandingPageTopBanner = ({ sectionContent, region, language, startTime, endTime, csrData, disabled = false, timezone, currentRegion, prgDate, pNameAlias }) => {
  const router = useRouter();
  const others = router.asPath;
  //Check if page is PBK online
  const isPbkOnlinePage = router.asPath.includes('pancha-bhuta-kriya-online-with-sadhguru-mahashivratri')

  const [currentUrl, setCurrentUrl] = useState('');
  let reg_URL = '';
  if (csrData) {
    reg_URL = csrData?.result[0]?.url_registration;
  }
  useEffect(() => {
  if (sectionContent?.button[0]) {
    setCurrentUrl(refineUrlDomain(sectionContent?.button[0]?.linkUrl, region, language));
  }
  else if (reg_URL != '' && currentRegion != 'IN') {
    setCurrentUrl(refineUrlDomain(reg_URL, region, language)); 
  }
  }, [sectionContent?.button[0]?.linkUrl, reg_URL]);
  return (
    <>
      <Box
        maxW={1330}
        w="100%"
        mx="auto"
        height={{ base: '640px', md: '628px' }}
        background={isPbkOnlinePage ? "linear-gradient(180deg, #131313 50%, #000000 100%)" : ''}
        pos="relative"
      >
        <Box
          width={{ base: "100vw", md: "76%" }}
          height={{ base: '305px', sm: "305px", md: '100%' }}
          pos="absolute"
          top={0}
          bottom={0}
          right={0}
          className='bannerimage'
        // left={0}
        >
          <Image
            fill
            priority
            // sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            style={{
              clipPath: useBreakpointValue({ base: "none", md: "polygon(0 0, 100% 0, 100% 100%, 0 85.5%)" })
            }}
            loader={imageLoader}
            src={
              useBreakpointValue({
                base: sectionContent?.mobileImage?.url ||
                  sectionContent?.desktopImage?.url, md: sectionContent?.desktopImage?.url
              })
            }
            blurDataURL={sectionContent?.mobileImage?.url + "?q=50"}
            placeholder='blur'
            alt={sectionContent?.mobileImage?.alt ||
              sectionContent?.desktopImage?.alt || ""}
            loading={"eager"}
          />
        </Box>
        <Box
          position="absolute"
          top={{ base: '135px', md: 0 }}
          left={0}
          className='bg_banner'
          w="100%"
          right={0}
          bottom={0}
          background={{
            base: 'linear-gradient(0deg, #000 50.9%, rgba(0, 0, 0, 0.00) 100%)',
            md: 'linear-gradient(91deg, #191407 25.57%, rgba(81, 36, 97, 0.00) 64%)',
            lg: 'linear-gradient(91deg, #191407 25.57%, rgba(81, 36, 97, 0.00) 64%)',

          }}
          style={{
            clipPath: useBreakpointValue({
              base: 'polygon(0 0, 100% 0, 100% 100%, 0 95%)',
              md: 'polygon(0 0, 100% 0, 100% 100%, 0 81%)',
            })
          }}
        >
          <Box maxW={560} w={{base:'90%', md:'100%'}} mr={{base:'auto'}} marginLeft={{ base: '30px', md: 93 }} mt={{ base: "170px", md: "20px" }}>
            {/* Heading of the banner */}
            {sectionContent?.title && sectionContent?.title.length && (
              <Heading
                as="h1"
                fontSize= {{ base: '32px', md: region != 'in' ? '48px' : '44px' }}
                color="#FFF"
                fontFamily="FedraSansStd-A-medium,serif"
                fontStyle="normal"
                fontWeight="500"
                lineHeight={{
                  base: "36px !important", md: "58px !important"
                }}
                mt={{base:'0', md:'40px'}}
                pt={{base: '0px'}}
                w={{base:'100%',md:'100%'}}
              >
                {sectionContent?.title}
              </Heading>
            )}

            {/* description of the banner */}
            {sectionContent?.titleSubtext && (
              <Box>
                <StructuredTextParser
                  maxWidth={361}
                  mt="0"
                  color="#FFCBA0"
                  fontFamily="FedraSansStd-book, sans-serif"
                  fontSize={{ base: '20px', md: '28px' }}
                  fontStyle="normal"
                  fontWeight="400"
                  mb="0"
                  lineHeight={{ base: '28px', md: '128%' }}
                  str={render(sectionContent?.titleSubtext)}
                  className={'sub-text'}
                />
              </Box>
            )}
            {sectionContent?.date && (
              <Box color="#EB972F"
              fontFamily="FedraSansStd-medium"
              fontSize={{ base: '22px', md: '28px' }}
              mb={{base: pNameAlias ? '0' : '0px', md: pNameAlias ? '0' : '6px'}}>
                {region === 'in' || region === 'us' || region === 'ca' || region === 'global' ?
                (<>
                  <StructuredTextParser
                  color="#EB972F"
                  fontFamily="FedraSansStd-medium"
                  fontSize={{ base: '22px', md: '28px' }}
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="28px"
                  mt={pNameAlias ? '33px' : '20px'}
                  str={render(sectionContent?.date)}
                />
                </>) :
                (<>
                  <Box mt="30px">
                    <Text>{prgDate}</Text>
                    <Text textTransform="uppercase">{startTime}{endTime}</Text>
                  </Box>
                </>)
                }
              </Box>
            )}

            {/* location of the banner */}
            {sectionContent?.location && (
              <Box>
                <StructuredTextParser
                  color={region === 'us' || region === 'ca' ? "#EB972F" : "#FFF"}
                  fontFamily="FedraSansStd-book, sans-serif"
                  fontSize={{ base: pNameAlias ? '14px' : '18px', md: pNameAlias ? '18px' : '22px' }}
                  fontStyle={region === 'us' || region === 'ca' ? "italic" : "normal"}
                  fontWeight="400"
                  lineHeight="28px"
                  mt="5px"
                  str={render(sectionContent?.location)}
                />
              </Box>
            )}
            {render(sectionContent?.closedMessage) &&
              render(sectionContent?.closedMessage) !== '<p></p>' && (
                <Box
                  fontSize={{ base: '16px', md: '20px' }}
                  fontFamily="FedraSansStd-book"
                  color={'#ffffff'}
                  mt="10px"
                >
                  {StructuredTextContent(sectionContent.closedMessage)}
                </Box>
            )}
            {sectionContent?.button[0] && (
              <>
              <Box w="auto" minW={'216px'} mt={{ base: '0', md: !isPbkOnlinePage ? '0' : '40px' }}>
              <NextLink href={refineUrlDomain(sectionContent?.button[0]?.linkUrl,region, language)} passHref legacyBehavior>
                <Link
                  m="16px 0 0 0"
                  color='#28231E'
                  fontFamily='FedraSansStd-medium'
                  fontSize='20px'
                  fontStyle='normal'
                  fontbold='500'
                  lineHeight='24px'
                  minH="58px"
                  padding={4}
                  width={{base:'216px', md:'216px'}}
                  mb={pNameAlias ? '30px' : '16px' }
                  mt={pNameAlias ? '0px' : '30px' }
                  display="block"
                  fontWeight={'500'}
                  bgColor={disabled ? 'rgb(207 69 32 / 41%)' : 'rgb(255, 174, 39)'}
                  cursor={disabled ? 'not-allowed' : 'pointer'}
                  textAlign={'center'}
                  borderRadius="5px"
                  textDecoration="none"
                  _hover={{
                    bg: disabled ? 'rgb(207 69 32 / 41%)' : 'rgb(207, 69, 32)',
                    color: '#fff'
                  }}
                  target={isOpenInNewTab(currentUrl) ? '_blank' : ''}
                >
                  {sectionContent?.button[0]?.buttonText}
                </Link>
              </NextLink>
              </Box>
              </>
            )}

            {sectionContent?.bottomText && (
              <Box>
                <StructuredTextParser
                  color="#FFF"
                  fontFamily="FedraSansStd-book-i"
                  fontSize={{ base: '16px', md: '18px' }}
                  fontStyle="italic"
                  fontWeight="400"
                  lineHeight="24px"
                  mt={{ base: pNameAlias ? '30px' : '0px', md: pNameAlias ? '30px' : '0px' }}
                  str={render(sectionContent?.bottomText)}
                />
              </Box>
            )}

          </Box>
        </Box>
      </Box>
      <style>
        {`
        // .bannerimage img {
        //   height: auto !important;
        // }
        // @media only screen and (min-width: 1340px) {
        //   .bg_banner {
        //     clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 89%) !important;
        //   }
        // }
        `}
      </style>
    </>
  );
};

export default LandingPageTopBanner;
