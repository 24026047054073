/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import DonationEnquiryCard from '@components/Card/DonationEnquiryCard';
import { useDonateFormContext } from '@store/donateFormContext';

/**
 * Renders the Donation Page Offline Donation Ui Section component
 *
 * @param {object} section - Data for section
 * @param {string} region - Region for the section
 * @param {string} lang - language for the section
 * @returns {ReactElement} Donation Page Offline Donation Ui component
 */

const DonationPageOfflineDonationUi = ({ section, region, lang }) => {
  const { toggleFormActive } = useDonateFormContext();

  return (
    <Box m="60px">
      <Box
        maxW={'770px'}
        mx="auto"
        fontFamily="FedraSansStd-book"
        fontSize={{ base: '24px', md: '30px' }}
        lineHeight="1.1"
        color="#1F1F1F"
        textAlign="center"
        my={{ base: '30px', md: '30px' }}
      >
        <Box mb={1} fontWeight="600"
          textAlign="center">
          {section?.sectionContent[0]?.titleText}
          <Box
            fontSize="16px"
            fontFamily="FedraSansStd-book"
            fontWeight="400"
            m="10px auto"
            maxW="550px"
            lineHeight="1.5rem"
          >
            {section?.sectionContent[0]?.titleSubtext}
          </Box>
        </Box>
      </Box>
      <DonationEnquiryCard
        sectionContent={section.sectionContent[1]}
        region={region}
        lang={lang}
        section={section}
      />

      <Box display="flex" justifyContent="center" pt={5}>
        <SimpleButton
          style={section.sectionContent[2]}
          region={region}
          lang={lang}
          clickHandler={toggleFormActive}
        />
      </Box>
    </Box>
  );
};

export default DonationPageOfflineDonationUi;
