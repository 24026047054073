/* Built In Imports */
import React from 'react';
/* External Imports */
import { Box } from '@chakra-ui/react';
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import Testimonial from '@Utility/Testimonial';

/* Internal Imports */

/**
 * Renders SP Alumni Components
 * @param {string} id - id
 * @returns {ReactElement} SP Alumni Component
 */
export default function SPAlumni(section, region, lang, isLeftMenu) {
  const secCon = section.section.sectionContent;
  return (
    <Box
      p={{ base: '20px', md: '80px' }}
      bgColor="transparent"
      float="left"
      position="relative"
      width="100%"
      bg="#fff"
      textAlign="center"
    >
      <SectionTitle titleObj={secCon[0]?.title[0]} />
      <Box
        width={{ base: '100%', md: '100%', lg: '75%' }}
        textAlign="center"
        m="0 auto"
        pt={{
          md: '0',
          lg: '80px',
          base: '40px',
        }}
      >
        <Testimonial
          testimonialsObj={secCon[0]}
          region={region}
          lang={lang}
          width="215px"
          height="auto"
          readmoreObj={secCon[1]}
        />
      </Box>
    </Box>
  );
}
