/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Center, Link, SimpleGrid } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
/* Services */

/**
 * Renders the Processes And Offerings Card component
 *
 * @param section.section
 * @param {object} section - Data for section
 * @param {string} region - Region for the section
 * @param {string} lang - Language for the section
 * @param section.region
 * @param section.lang
 * @returns {ReactElement} Processes And Offerings Card component
 */
const ProcessesAndOfferings = ({ section, region, lang }) => {
  section.sectionContent[0].color = '#eae3d4'; // assigning heading color
  section.sectionContent[0].fontSize = '24px';
  return (
    <Box
      w={{ xl: '100%', sm: '90%' }}
      m="0 auto"
      // bg={'linear-gradient(to bottom, #983200 0%, #cf4520 100%);'}
      h={'100%'}
      p="0 5vw"
    >
      <Box
        // paddingTop="60px"
        fontFamily="FedraSansStd-A-medium"
        fontSize={'24px'}
        lineHeight="1.1"
        textAlign="center"
      >
        <SectionTitle titleObj={section.sectionContent[0]} />
      </Box>
      <Box
        fontSize={{ base: '18px', md: '20px' }}
        color="#ffffff"
        lineHeight="1.5"
        textAlign="Left"
        w="100%"
        fontFamily="FedraSansStd-book"
      >
        <StructuredTextParser
          str={render(section.sectionContent[1]?.body)}
          region={region}
          lang={lang}
        />
      </Box>
      {section?.sectionContent[3]?._modelApiKey === 'simple_button' && (
        <Box display={'flex'} alignItems="center" justifyContent={'center'}>
          <NextLink
            href={refineUrlDomain(section?.sectionContent[3]?.linkUrl, region, lang)}
            passHref
            legacyBehavior>
            <Link
              mt="30px"
              mb="40px"
              w={'172px'} 
              display="block"
              fontWeight={500}
              fontFamily="FedraSansStd-medium"
              fontSize={'18px'}
              bgColor="#faf7f0"
              h={'auto'}
              textAlign="center"
              padding="10px 36px"
              borderRadius="3px"
              color="#cf4520"
              textDecoration="none"
              _hover={{
                bg: '#cf4520',
                color: '#faf7f0',
              }}
              target={
                section?.sectionContent[3]?.linkUrl.indexOf('http') > -1 &&
                section?.sectionContent[3]?.linkUrl.indexOf(config.cdnPath) < 0
                  ? '_blank'
                  : ''
              }
            >
              {section.sectionContent[3]?.buttonText}
            </Link>
          </NextLink>
        </Box>
      )}
      <SimpleGrid
        columns={{ xl: 2 }}
        spacing={'30px'}
        p="30px 0"

        // _after={{ content: '""', display: "table", clear: "both" }}
      >
        {section.sectionContent[2]?.cards?.map(secCon => {
          return <>
            <Center
              key={nanoid()}
              p="3% 0 0 3%"
              as="flex"
              flexDir={'column'}
              justifyContent="flex-start"
              // w={{ xl: "45%", lg: "90%", md: "90%", sm: "90%" }}
              //   w={"45%"}
              // minH={{xl:"550px"}}
              h="auto"
              // m="0 15px"
              // float={"left"}
            >
              <LazyLoadImageComponent
                src={secCon.thumbnail?.url}
                alt={secCon.thumbnail?.alt}
                title={secCon.thumbnail?.title}
                minH="220px"
              />
              <Box
                as="h5"
                fontFamily="FedraSansStd-A-medium"
                fontSize="20px"
                textAlign="center"
                color="#f5ac3e"
                marginTop="10px"
                // marginBottom="10px"
              >
                {secCon.title}
              </Box>
              <Box
                as="h6"
                fontSize="18px"
                lineHeight="1.5"
                textAlign="center"
                color="#ffffff"
                marginBottom="40px"
                w="100%"
                fontFamily="FedraSansStd-book"
              >
                <StructuredTextParser
                  str={render(secCon.description)}
                  region={region}
                  lang={lang}
                  color="#ffffff"
                />
              </Box>

              {secCon?.secondCtaLabel && (
                <NextLink
                  href={refineUrlDomain(secCon?.secondCtaLink, region, lang)}
                  passHref
                  legacyBehavior>
                  <Link
                    mb="40px"
                    display="block"
                    fontFamily="FedraSerifAStdBook"
                    fontSize={'16px'}
                    h={'auto'}
                    textAlign="left"
                    color="white"
                    textDecoration="underline"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                    target={
                      secCon?.secondCtaLink.indexOf('http') > -1 &&
                      secCon?.secondCtaLink.indexOf(config.cdnPath) < 0
                        ? '_blank'
                        : ''
                    }
                  >
                    {secCon?.secondCtaLabel}
                  </Link>
                </NextLink>
              )}

              {secCon?.buttonText && (
                <NextLink
                  href={refineUrlDomain(secCon?.buttonLink, region, lang)}
                  passHref
                  legacyBehavior>
                  <Link
                    mb="40px"
                    display="block"
                    fontWeight={500}
                    fontFamily="FedraSansStd-medium"
                    fontSize={'18px'}
                    bgColor="#faf7f0"
                    h={'auto'}
                    textAlign="left"
                    padding="10px 36px"
                    borderRadius="3px"
                    color="#cf4520"
                    textDecoration="none"
                    _hover={{
                      bg: '#cf4520',
                      color: '#faf7f0',
                    }}
                    target={
                      secCon?.buttonLink.indexOf('http') > -1 &&
                      secCon?.buttonLink.indexOf(config.cdnPath) < 0
                        ? '_blank'
                        : ''
                    }
                  >
                    {secCon?.buttonText}
                  </Link>
                </NextLink>
              )}
            </Center>
          </>;
        })}
      </SimpleGrid>
      {section?.sectionContent[4]?._modelApiKey === 'simple_button' && (
        <Box display={'flex'} alignItems="center" justifyContent={'center'}>
          <NextLink
            href={refineUrlDomain(section?.sectionContent[4]?.linkUrl, region, lang)}
            passHref
            legacyBehavior>
            <Link
              mt="30px"
              mb="40px"
              w={'172px'} 
              display="block"
              fontWeight={500}
              fontFamily="FedraSansStd-medium"
              fontSize={'18px'}
              bgColor="#faf7f0"
              h={'auto'}
              textAlign="center"
              padding="10px 36px"
              borderRadius="3px"
              color="#cf4520"
              textDecoration="none"
              _hover={{
                bg: '#cf4520',
                color: '#faf7f0',
              }}
              target={
                section?.sectionContent[4]?.linkUrl.indexOf('http') > -1 &&
                section?.sectionContent[4]?.linkUrl.indexOf(config.cdnPath) < 0
                  ? '_blank'
                  : ''
              }
            >
              {section.sectionContent[4]?.buttonText}
            </Link>
          </NextLink>
        </Box>
      )}
      <style global jsx>
        {`
          #processes-and-offerings a[href^='mailto'] {
            color: #ffffff !important;
            cursor: pointer;
            border-bottom: 2px solid #ffffff;
          }
          #processes-and-offerings a[href^='mailto']:hover {
            border-bottom: none;
          }
        `}
      </style>
    </Box>
  );
};

export default ProcessesAndOfferings;
