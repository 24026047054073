const fontFamily = 'FedraSansStd-medium';
import config from '@config';
import merge from 'lodash/merge';

const commonLangStyle = ({ lang, ...props }) => {
  const isIndian = config.indianLang.indexOf(lang) > -1;
  const styleObj = {
    stMainText: {
      color: '#6D6358',
      fontSize: { base: '18px', md: '26px' },
      lineHeight: { base: '23.4px', md: '33.8px' },
      fontWeight: isIndian ? 600 : 'normal',
      fontFamily: isIndian ? config.langFont[lang] : 'FedraSansStd-medium',
    },
    stSubQText: {
      fontFamily: isIndian ? config.langFont[lang] : 'FedraSansStd-medium',
      fontSize: { base: '16px', md: '18px' },
      lineHeight: { base: '20.16px', md: '23.4px' },
      color: '#6D6358',
      fontWeight: isIndian ? 600 : 'normal',
    },
    stIcon: {
      color: '#6D6358',
      fontSize: '2.5rem',
    },
    p: {
      fontSize: '16px',
      fontWeight: isIndian ? '400' : 'normal',
      lineHeight: '22.4px',
      color: '#3A3A3A',
      fontFamily: lang ? config.langFont[lang] : fontFamily,
    },
    FaqQ: {
      fontFamily: isIndian ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 600 : 'normal',
    },
    FaqAns: {
      fontFamily: lang ? config.langFont[lang] : fontFamily,
      fontWeight: isIndian ? 400 : 'normal',
    },
  };
  const finalObj = merge(styleObj, { ...props });
  return finalObj;
};

const RenderContentSt = {
  en: commonLangStyle({ lang: 'en' }),
  bn: commonLangStyle({ lang: 'bn' }),
  hi: commonLangStyle({ lang: 'hi' }),
  te: commonLangStyle({ lang: 'te' }),
  kn: commonLangStyle({ lang: 'kn' }),
  gu: commonLangStyle({ lang: 'gu' }),
  mr: commonLangStyle({ lang: 'mr' }),
  ml: commonLangStyle({ lang: 'ml' }),
  'zh-tw': commonLangStyle({ lang: 'zh-tw' }),
  zh: commonLangStyle({ lang: 'zh' }),
  it: commonLangStyle({ lang: 'it' }),
  fr: commonLangStyle({ lang: 'fr' }),
  de: commonLangStyle({ lang: 'de' }),
  es: commonLangStyle({ lang: 'es' }),
  ru: commonLangStyle({ lang: 'ru' }),
  ta: commonLangStyle({ lang: 'ta' }),
  cn: commonLangStyle({ lang: 'cn' }),
  ar: commonLangStyle({
    lang: 'ar',
    stMainText: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    stSubQText: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    stIcon: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    p: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    FaqQ: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
    FaqAns: {
      fontFamily: "'Almarai', sans-serif",
      textAlign: 'right',
    },
  }),
  id: commonLangStyle({ lang: 'id' }),
};

export default RenderContentSt;
