/* Built In Imports */
/* External Imports */
import { Input, Textarea } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders the Input UI component.
 * @param {string} field - field details.
 * @param {string} id - id.
 * @param {string} placeholder - placeholder.
 * @param {string} wd - width.
 * @param {Boolean} textArea - if the input is an textarea.
 * @param {Object} props - more styling properties
 * @returns {ReactElement} InputUI component.
 */

const InputUI = ({ field, id, placeholder, wd, textArea, ...props }) => {
  return textArea ? (
    <>
      <Textarea
        {...field}
        id={id} // this can be a nanoid rather than props passing
        variant="flushed"
        placeholder={placeholder}
        fontFamily="'FedraSansStd-book', sans-serif"
        fontSize="16px"
        color="#28231e"
        // height={{ base: '50px', lg: '40px' }}
        border="1px solid #aea493"
        borderBottom="1px solid #a1a1a1"
        borderTop="none"
        borderRight="none"
        borderLeft="none"
        borderRadius="0"
        p="10px 0"
        mr="20px"
        width={wd || '100%'}
        backgroundColor="#fff"
        _placeholder={{
          color: '#94918f',
          fontSize: '12px',
        }}
        {...props}
      />
    </>
  ) : (
    <Input
      {...field}
      id={id} // this can be a nanoid rather than props passing
      variant="flushed"
      placeholder={placeholder}
      fontFamily="'FedraSansStd-book', sans-serif"
      fontSize="16px"
      color="#8a8173"
      // height={{ base: '50px', lg: '40px' }}
      // padding="10px"
      border="1px solid #a1a1a1"
      borderBottom={props.borderwidth ? props.borderwidth : '1px solid #a1a1a1'}
      borderTop="none"
      borderRight="none"
      borderLeft="none"
      borderRadius="0"
      // p="10px 0"
      // mr="20px"
      w={wd}
      backgroundColor="#fff"
      // textTransform={id === 'email' ? 'lowercase' : 'capitalize'}
      _placeholder={{
        color: 'grey',
        fontSize: `${props.fontSize ? props.fontSize : '12px'}`,
      }}
      {...props}
    />
  );
};

export default InputUI;
