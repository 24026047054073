/* Built In Imports */
/* External Imports */
import { Box, Flex, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
// import MidContainer from '@Layout/MidContainer';

/**
 * Renders Wide Sadhguru Box Component
 *
 * @param section.section
 * @param {object} section - data for the section
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 * @param section.region
 * @param section.lang
 * @returns {ReactElement} - Wide Sadhguru Box Component
 */
const WideSadhguruBox = ({ section, region, lang }) => {
  return (
    <Box
      height={{ lg: '520px' }}
      display="flex"
      flexDir={{ base: 'column', md: 'row' }}
      justifyContent="flex-end"
      alignItems="center"
      // backgroundImage="https://images.sadhguru.org/sites/default/files/inline-images/Ishanga-dt.jpg"
      backgroundImage={{
        lg: section?.sectionContent[2]?.image?.url,
        base: '#fff',
      }}
      backgroundPosition={['center', 'left']}
    >
      <Box
        display={{ base: 'flex', lg: 'none' }}
        w="100%"
        flexDir="column"
        px="10px"
      >
        <LazyLoadImageComponent src={section?.sectionContent[2]?.mobileImage?.url} alt="Ishanga" />
      </Box>
      <Flex
        width={{ base: 'full', lg: '50%' }}
        p={4}
        fontFamily="FedraSansStd-book"
      >
        <Box
          width={{ base: 'full', lg: '70%' }}
          mx="auto"
          textAlign={{ base: 'center', md: 'left' }}
        >
          <Box as="p" fontSize="lg" lineHeight="1.6" mb={10}>
            <StructuredTextParser
              str={render(section?.sectionContent[0]?.body)}
              region={region}
              lang={lang}
              textAlign={{ base: 'center', md: 'left' }}
            />
          </Box>
          <Link
            href={section?.sectionContent[1]?.linkUrl}
            mb="40px"
            fontWeight={500}
            fontFamily="FedraSansStd-medium"
            fontSize={'18px'}
            bgColor="#cf4520"
            h={'auto'}
            textAlign="left"
            padding="10px 36px"
            borderRadius="3px"
            color="#faf7f0"
            textDecoration="none"
            _hover={{
              bg: '#000054',
            }}
          >
            {section?.sectionContent[1]?.buttonText}
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default WideSadhguruBox;
