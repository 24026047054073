/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the Card Info 3 Column Image Less Group Card component
 * @param {Object} sectionContent - Data for section
 * @returns {ReactElement} CardInfo3ColumnImageLessGroup Card component
 */

const CardInfo3ColumnImageLessGroup = ({ sectionContent, toggle }) => {
  // const bgColors = Styles.bgColors;
  return (
    <>
      <Box
        as="span"
        display="inline-block"
        w="100%"
        h="3px"
        m={{ base: '10px 0', md: '30px 0' }}
        fontFamily={'FedraSansStd-medium'}
      ></Box>
      <Box
        p={'15px 0 35px 0'}
        fontFamily={'FedraSansStd-A-medium'}
        fontSize="24px"
        fontWeight="500"
        textAlign={'center'}
        color="#28231e"
        as={'h2'}
        onClick={toggle}
      >
        {sectionContent?.title}
      </Box>
      <Box pos={'relative'}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
          gridGap="32px"
          width={'95%'}
          textAlign={'left'}
          margin="0 auto"
          placeItems={'center'}
          className="Container"
        >
          {sectionContent?.cards?.map((secCon, index) => {
            return (
              <Box
                display="grid"
                // alignContent="flex-start"
                // justifyContent="center"
                // position="static"
                // float="left"
                className="centerD"
                key={nanoid()}
                w="100%"
                // pb={{ md: '100px', base: '0' }}
              >
                <style global jsx>
                  {`
                    .centerD strong {
                      font-size: 16px;
                      text-align: left;
                      color: rgb(40, 35, 30);
                      fontFamily="FedraSansStd-book"
                    }
                    .centerD span.setting:after {
                      font-size: 16px;
                      fontFamily="FedraSansStd-book"
                      
                      text-align: left;
                      color: rgb(40, 35, 30);
                    }  
                  `}
                </style>
                <Box
                  as="h3"
                  fontFamily="FedraSansStd-medium"
                  fontWeight="normal"
                  fontStyle="normal"
                  // fontSmoothing="antialiased"
                  // fontSize="24px"
                  // fontWeight={'500'}
                  lineHeight="1.5"
                  // color="#28231e"
                  // pt={'12px'}
                  fontSize="20px"
                  textAlign="left"
                  color="rgb(40, 35, 30)"
                  // pb="10px"
                >
                  {secCon?.title}
                </Box>
                <Box
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  color={'gray.200'}
                  // pt={'12px'}
                >
                  <StructuredTextParser str={render(secCon.description)} />
                </Box>
                <Box
                  as="span"
                  display="inline-block"
                  w="58px"
                  h="3px"
                  m="30px 0"
                  borderBottom="1px solid #dfd6c4"
                ></Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default CardInfo3ColumnImageLessGroup;
