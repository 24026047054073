/* Built In Imports */
import React from 'react';
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the Benefits Card component.
 *
 * @returns {ReactElement} Benefits Card component.
 */

const BenefitsCard = ({ card }) => {
  return (
    <Box maxW={368}>
      <Flex
        flexDir="column"
        // alignContent="center"
        justifyContent="center"
        w={{ md: "200px" }}
        alignItems="center" s
        my="15px"
        mx="10px"
        minH={{ base: 216, md: 230 }}
      >
        <Flex
          justifyContent="space-between"
          maxW={{ base: '108px', md: '118px' }}
          maxH={{ base: '108px', md: '116px' }}
          mt={{base:'0', md:'20px'}}
          overflow="hidden"
        >
          <LazyLoadImageComponent
            alt="benefits image"
            width={{ base: 106, md: 118 }}
            maxH={{ base: 106, md: 118 }}
            height={{ base: '106px', md: '116px' }}
            src={card?.image?.image?.url}
          />
        </Flex>
        <Box
          maxW={300}
          w={{ md: "174px", base: '299px' }}
          px='0'
          h={{base:'auto', md:'90px'}}
          mt="25px"
          mb="5px"
        >
          <StructuredTextParser
            mt="0"
            color='#FFF9E9'
            textAlign='center'
            fontFamily='FedraSansStd-Book, sans-serif'
            fontSize='16px'
            fontStyle='normal'
            fontWeight='400'
            lineHeight='150%'
            p="0"
            str={render(card?.description)}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default BenefitsCard;
