/* Built In Imports */
import { Field, Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { createRef, useEffect, useState } from 'react';

/* External Imports */
import { Box, Button, Flex, FormControl, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Radio } from "@chakra-ui/react";
import config from '@config';

/* Internal Imports */

const ProgramCenterPopup = ({ isOpen, onClose, formElement, lang, region }) => {
    const router = useRouter();
    const [err, setErr] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [locationhref, setLocationhref] = useState('');
    const recaptchaRef = createRef(null);

    const [initialValues, setInitialValues] = useState({
        name: '',
        company_name: '',
        email: '',
        whatsapp_number: '',
        interested: ''
    });
    const changeUrl = (e) => {
        let locationhref = '';
        setErr('');
        setSelectedOption(e.target.value);
        if(e.target.value === 'iyc_coimbatore') {
            locationhref = `${config.YOGA_SANDHYA_IYC_LINK}`;
        }
        else {
            locationhref = `${config.YOGA_SANDHYA_SSB_LINK}`;
        }
        setLocationhref(locationhref);
        return;
      };
    const onCloseModal = () => {
        setErr('');
        setSelectedOption('');
        onClose();
    }

    const handleSubmit = (e, lang, region) => {
        e.preventDefault();
        if (selectedOption != '') {
        // let pathurl = `${config.BASE_PATH}${region}/${lang}/${locationhref}`;
        window.open(`${locationhref}`);
        setSelectedOption('');
        onClose();
        // router.push(`${locationhref}`);
        } else {
            setErr('Please select location option');
        }
    };
    return (
        <Modal isOpen={isOpen} 
            onClose={
                onClose => {
                onCloseModal();
                }
            }>
            <ModalOverlay />
            <ModalContent maxW={{base:'90%', md:'321px'}} height={'246px'} m="auto">
                <ModalCloseButton color={'#000000'}/>
            <ModalBody padding="0px">
                        <Flex
                            flexDirection={"column"}
                            mx={{ base: 'auto' }}
                            alignItems="center"
                            justifyContent="center"
                            backgroundImage={formElement?.bgImage?.desktop}
                            backgroundSize={'cover'}
                            backgroundRepeat={'no-repeat'}
                            padding={{ base: "20px 23px" }}
                            m="0"
                        >
                            <>
                                <Box lineHeight={'40px'} fontFamily="FedraSansStd-book" fontWeight={'400'} color={'#000000'} fontSize={{ base: '16px', md: '16px' }} >
                                    <Box mb="10px" textAlign={'left'} fontFamily="FedraSansStd-book" fontWeight={'bold'} color={'#000000'} fontSize={{ base: '16px', md: '16px' }}>{formElement.title}</Box>
                                    <Formik
                                        onSubmit={handleSubmit}
                                        enableReinitialize
                                    >
                                        {props => (
                                            <Form onSubmit={props.handleSubmit} className='formAlign'>
                                                <Field name="program">
                                                    {({ field, form }) => {
                                                        return (
                                                        <FormControl>
                                                            <Radio
                                                                field={field}
                                                                id="iyc_coimbatore"
                                                                value="iyc_coimbatore"
                                                                checked={selectedOption === 'iyc_coimbatore'}
                                                                onChange={changeUrl}
                                                                bgColor={'#fff !important'}
                                                                required={true}
                                                                _focus={{
                                                                    background: '#D04723',
                                                                    color: '#D04723',
                                                                    }}
                                                                    _active={{
                                                                    background: '#D04723',
                                                                    color: '#D04723',
                                                                    }}
                                                                borderColor={'#D04723 !important'}
                                                                p={3}
                                                                /> <label for="iyc_coimbatore">Isha Yoga Center, Coimbatore</label>
                                                        </FormControl>
                                                    )}}
                                                </Field>
                                                <Field name="program">
                                                    {({ field, form }) => {
                                                        return (
                                                        <FormControl>
                                                            <Radio
                                                                field={field}
                                                                id="iyc_bangalore"
                                                                value="iyc_bangalore"
                                                                checked={selectedOption === 'iyc_bangalore'}
                                                                onChange={changeUrl}
                                                                bgColor={'#fff !important'}
                                                                required={true}
                                                                _focus={{
                                                                    background: '#D04723',
                                                                    color: '#D04723',
                                                                    }}
                                                                    _active={{
                                                                    background: '#D04723',
                                                                    color: '#D04723',
                                                                    }}
                                                                borderColor={'#D04723 !important'}
                                                                p={3}
                                                                /> <label for="iyc_bangalore">Sadhguru Sannidhi, Bengaluru</label>
                                                            {err && (
                                                            <Box
                                                                justifyContent="center"
                                                                color="#E73E3E"
                                                                fontSize="12px"
                                                                fontFamily="'FedraSansStd-book', sans-serif"
                                                                marginLeft={'0px'}
                                                                marginTop={'0px'}
                                                                height={'27px'}
                                                                textAlign={'center'}
                                                            >
                                                                {err}
                                                            </Box>
                                                            )}
                                                        </FormControl>
                                                    )}}
                                                </Field>
                                                <Box marginTop="0px" width={{ base: '100%' }}>
                                                    <Box as="div" marginTop="10px" textAlign="center">
                                                        <Button
                                                            type="submit"
                                                            display="inline-block"
                                                            mb="0px"
                                                            w={'136px'}
                                                            h={'45px'}onClick={handleSubmit}
                                                            fontFamily="'FedraSansStd-medium', sans-serif"
                                                            borderRadius="8px"
                                                            bgColor="#FFAE27"
                                                            color="#000"
                                                            fontSize="16px"
                                                            padding="10px 35px"
                                                            fontWeight={'normal'}
                                                            _hover={{
                                                                textDecoration: 'none',
                                                                bgColor: '#cf4520',
                                                                color: '#fff',
                                                            }}                                              
                                                            _focus={{
                                                                background: '#cf4520',
                                                                border: '1px solid #F5E3B5',
                                                                color: '#fff',
                                                                }}
                                                                _active={{
                                                                background: '#cf4520',
                                                                border: '1px solid #F5E3B5',
                                                                color: '#fff',
                                                                }}
                                                            border={"none"}
                                                        // disabled={!props.isValid ? (loading ? true : false) : true}
                                                        >
                                                            {formElement.submitButton}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                            </>
                        </Flex>
                        <style jsx global>
                            {`
                    .formAlign{
                        margin-left:-15px;
                    }
                    .bottom-border-fields {
                        border-radius: 5px;
                        padding-left: 30px !important;
                        font-family: "FedraSansStd-book";
                        height: 68px !important;
                        margin-top: 10px;
                        color: #6A6565;
                    }
                    `}
                        </style>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ProgramCenterPopup