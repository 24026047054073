/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/**
 * Renders the ImageBannerAd Card component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @param sectionContent.region
 * @param sectionContent.lang
 * @param sectionContent.width
 * @param sectionContent.height
 * @param sectionContent.my
 * @param sectionContent.display
 * @param sectionContent.maxW
 * @returns {ReactElement} ImageBannerAd component
 */
const ImageBannerAd = ({
  sectionContent,
  region,
  lang,
  width,
  height,
  my,
  display,
  maxW,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box maxW="1100px" mx="10px" marginInline={'auto'}>
      <Box
        textAlign="center"
        position="relative"
        mx="auto"
        my={my || 10}
        width={width || '100%'}
        height={height || 'auto'}
      >
        <NextLink
          href={
            sectionContent?.linkUrl
              ? refineUrlDomain(sectionContent?.linkUrl, region, lang)
              : 'javascript:;'
          }
          passHref
          legacyBehavior>
          <Link
            target={
              sectionContent?.linkUrl?.indexOf('http') > -1 &&
              sectionContent?.linkUrl.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
          >
            <LazyLoadImageComponent
              alt={sectionContent?.image?.title}
              title={sectionContent?.image?.title}  
              textAlign="center"
              src={ isMobile ? (sectionContent?.mobileImage?.url || sectionContent?.image?.url) : sectionContent?.image?.url}
              w="100%"
              // display={{
              //   base: display ? display : 'none !important',
              //   lg: 'block !important'
              // }}
            />
          </Link>
        </NextLink>
      </Box>
    </Box>
  );
};

export default ImageBannerAd;
