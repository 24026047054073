/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import RenderContent from '@ieo/Sections/RenderContent';

/* Services */

/**
 * Renders the FAQ content component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for the section
 * @param {string} lang - Language for the section
 * @returns {ReactElement} FAQ content component
 */

const FaqContents = ({ sectionContent, region, lang }) => {

  return (
    <Box
      as="div"
      marginTop="30px"
      mb={{ base: '60px', md: '100px' }}
      mx="auto"
      px={{ base: '10px', lg: '0px', md: '0px', '2xl': '0px' }}
      maxWidth="1036px"
      id="faq-content"
    >
      <Box as="div" px={{ base: '0px', md: '15px', lg: '0px' }} pb="20px">
        <SectionTitle
          titleObj={sectionContent[0]}
          maxW="500px"
        />
      </Box>
      <Box
        // mt="30px"
        m={{
          base: '30px 0px 0 0px',
          md: '0 15px 0 15px',
          lg: '30px 0px 0 0px',
        }}
      >
        {sectionContent[1]?.faqSections?.map(dataChild => {
          return (
            <RenderContent
              key={nanoid(2)}
              lang={lang}
              dataChild={dataChild}
            />
          );
        })}
      </Box>
      <style>
        {
          `#faq-content ul {
            padding-left: 25px;
          }
          .pAlink a {
            color: #e86f34;
            text-decoration: underline;
          }
          `
        }
      </style>
    </Box>
  );
};

export default FaqContents;
