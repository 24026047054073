/* Built In Imports */
import React from 'react';
/* External Imports */
import { Box, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import Faq from '@components/Accordions/FaqQuestionier';
import SectionTitle from '@components/Headings/SectionTitle';

/**
 * Renders SP Program Details Component
 * @returns {ReactElement} - SP Program Details Component
 */
export default function SPProgramDetails({
  section,
  region,
  lang,
  isLeftMenu,
}) {
  const secCon = section?.sectionContent;
  const renderAccordian = (count, conBlock) => {
    return (
      <Box
        className="sadhnapadaFaq"
        mt={{ base: '30px', lg: '20px', md: '30px' }}
        ml={{ base: '10px', lg: '45px', md: '45px' }}
        p={{ base: '25px 15px 20px 15px', lg: '45px 70px' }}
        border="1px solid #dfdfdf"
        float="left"
        pos="relative"
        w="100%"
      >
        <Box
          fontFamily={lang === 'ta' ? 'MuktaMalar' : 'FedraSansStd-A-medium'}
          float="left"
          pos={{ base: 'relative', lg: 'absolute' }}
          w={{ base: '30px', lg: '46px' }}
          h={{ base: '30px', lg: '46px' }}
          fontSize={{ base: '16px', lg: '25px' }}
          fontWeight={lang === 'ta' ? 'bold' : 'normal'}
          color="#ffffff"
          bgColor="#cf4520"
          borderRadius="100%"
          textAlign="center"
          left={{ base: '-8px', lg: '-25px' }}
          top={{ base: '-4px', lg: '35px' }}
          lineHeight={{ base: '30px', lg: '45px' }}
        >
          {count}
        </Box>
        <Box
          lineHeight="1.04"
          color="#28231e"
          fontFamily={lang === 'ta' ? 'MuktaMalar' : 'FedraSansStd-A-medium'}
          fontSize={{ base: '20px', lg: '27px' }}
          mb="10px"
        >
          {secCon[`${conBlock}`]?.text}
        </Box>
        <Text
          color="#816f5c"
          lineHeight="1.94"
          fontFamily={
            lang === 'ta' ? 'MuktaMalar' : '"FedraSansStd-medium", sans-serif'
          }
          fontWeight={lang === 'ta' ? 'bold' : 'normal'}
          fontSize={{ base: '14px', lg: '18px' }}
        >
          {secCon[`${conBlock + 1}`]?.text}
        </Text>
        <Box>
          <Faq
            sectionContent={secCon[`${conBlock + 2}`]}
            isLeftMenu={isLeftMenu}
            isPlusIcon="true"
            borderBottom="0"
          />
        </Box>
      </Box>
    );
  };
  return (
    <Box
      p={{ base: '30px 0px 20px 20px', md: '30px', lg: '80px 10px 20px 10px' }}
      bgColor="#fff"
      float="left"
      position="relative"
      width="92%"
    >
      <Box textAlign="center" p="0px 15px">
        <SectionTitle titleObj={secCon[0]} />
      </Box>
      {renderAccordian(1, 1)}
      {renderAccordian(2, 4)}
      {renderAccordian(3, 7)}
      <style global jsx>
        {`
          #program-details h2 button {
            color: #cf4520;
          }
          .sadhnapadaFaq iframe {
            height: 400px;
          }
          .chakra-accordion__item .content {
            padding: 5px 0;
          }
          @media only screen and (max-width: 767px) {
            .sadhnapadaFaq iframe {
              height: 100%;
              width: 100%;
            }
          }
        `}
      </style>
    </Box>
  );
}
