/* Built In Imports */
import { useRef, useState } from 'react';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders Testimonial Component
 *
 * @param {object} props
 * @param props.testimonialsObj
 * @param props.region
 * @param props.lang
 * @param props.width
 * @param props.height
 * @param props.readmoreObj
 * @returns {ReactElement} Testimonial - Testimonial Component
 */
const Testimonial = ({
  testimonialsObj,
  region,
  lang,
  width,
  height,
  readmoreObj,
}) => {
  const titleRef = useRef();
  const [showMore, setShowMore] = useState(false);

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="50%"
        zIndex="5"
        cursor="pointer"
        bgSize="10px"
        bgColor="#fff"
        border="0"
        w="50px"
        h="50px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/left-arrow.svg)`}
        left={{ base: '-19px', md: '-70px', lg: '-70px' }}
        borderRadius={{ base: '50%', md: '0', lg: '0' }}
        onClick={onClick}
      />
    );
  };
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="50%"
        zIndex="5"
        cursor="pointer"
        bgSize="10px"
        bgColor="#fff"
        border="0"
        w="50px"
        h="50px"
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.staticPath}/assets/images/right-arrow.svg)`}
        right={{ base: '-19px', md: '-70px', lg: '-70px' }}
        borderRadius={{ base: '50%', md: '0', lg: '0' }}
        onClick={onClick}
      ></Box>
    );
  };
  const settings = {
    dots: false,
    autoplay: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const readMore = () => {
    setShowMore(!showMore);
    titleRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box boxShadow="0 0 30px rgb(0 0 0 / 20%)" m="0 auto">
      <Slider {...settings}>
        {testimonialsObj?.testimonials?.map((data, index) => {
          return (
            <Box w="100%" p="50px 65px" key={index}>
              {data?.photo?.url && (
                <LazyLoadImageComponent
                  src={data?.photo?.url}
                  alt={data?.photo?.alt}
                  title={data?.photo?.title}
                  w={width ? width : '215px'}
                  h={height ? height : 'auto'}
                  borderRadius="50%"
                  verticalAlign="middle"
                  pb="1vw"
                />
              )}
              {data?.userName && (
                <Box marginTop="15px" textAlign="center">
                  <Text
                    w="100%"
                    fontFamily="'FedraSansStd-book', sans-serif"
                    fontSize="24px"
                    textAlign="center"
                    color="#28231e"
                    mt="25px"
                  >
                    {data?.userName}
                  </Text>
                </Box>
              )}
              <Box mt="15px">
                <LazyLoadImageComponent
                  src={`${config.imgPath}/d/46272/1663651290-quotes.svg`}
                  alt="quotes"
                  title="Quotes"
                  display={!showMore ? 'block' : 'none !important'}
                />
                <Text
                  w="100%"
                  fontFamily="'FedraSansStd-book', sans-serif"
                  fontSize="18px"
                  textAlign="center"
                  color="#28231e"
                  mt="15px"
                  lineHeight="1.61"
                  mb="!0px"
                  fontWeight={showMore ? 'normal' : 'bold'}
                  className="content"
                >
                  <Text display={!showMore ? 'block' : 'none'}>
                    <StructuredTextParser
                      str={render(data.testimonialText)}
                      region={region}
                      lang={lang}
                      textAlign="center"
                      mt="0"
                    />
                  </Text>
                  <Text
                    display={showMore ? 'block' : 'none'}
                    ref={titleRef}
                    as="p"
                    className={index}
                  >
                    <StructuredTextParser
                      str={render(data.userDescription)}
                      region={region}
                      lang={lang}
                      textAlign="center"
                      mt="0"
                    />
                  </Text>
                </Text>
                <Link
                  fontFamily={
                    showMore
                      ? '"FedraSansStd-A-medium", sans-serif !important'
                      : "'FedraSansStd-book', sans-serif !important"
                  }
                  fontSize="18px"
                  color="#cf4520"
                  textDecoration="none"
                  _hover={{ textDecoration: 'none' }}
                  onClick={() => readMore()}
                  display={!showMore ? 'block' : 'none'}
                >
                  {readmoreObj.text} &gt;
                </Link>
              </Box>
            </Box>
          );
        })}
      </Slider>
      <style>
        {`
        .content p{
          font-size:18px !important;
        }
        `}
      </style>
    </Box>
  );
};

export default Testimonial;
