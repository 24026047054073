/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import moment from 'moment-timezone';

/* Internal Imports */
import MidContainer from '@Layout/MidContainer';
import SectionTitle from '@components/Headings/SectionTitle';
import BrownSpiralBullets from '@components/Text/BrownSpiralBullets';
import SingleLineText from '@components/UI/SingleLineText';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import {
  searchByFieldId,
  searchByModelApiKey,
} from '@components/Utility/Shared/SharedService';
import { refineUrlDomain } from '@components/Utility/utils';

import styles from '@components/Text/brownSpiral.module.css';
import {getCurrentIpRegion} from 'services/commonService';

/**
 *
 * @param section
 * @param region
 * @param lang
 */

const ProgDetailPBK = ({ section, region, lang, csrData, currentRegion }) => {
  let reg_URL = '';
  let prgPrice = '';
  let currency_code = '';
  let pg_notes = '';
  let seatAvailability = '';
  let supportEmail = '';
  let queryCountry = '';
  if (csrData) {
    queryCountry = csrData?.result[0]?.queried_country_code;
    if (currentRegion != 'IN' && region != 'us' && region != 'ca' && currentRegion != 'US' && currentRegion != 'CA' ) {
      reg_URL = csrData?.result[0]?.url_registration+"&country_code="+currentRegion;
    } else {
      reg_URL = csrData?.result[0]?.url_registration;
    }
    prgPrice = csrData?.result[0]?.tickets[0]?.price;
    currency_code = csrData?.result[0]?.tickets[0]?.currency;
    pg_notes = csrData?.result[0]?.regional_notes;
    seatAvailability = csrData?.result[0]?.tickets[0]?.seats_available;
    supportEmail = csrData?.result[0]?.regional_support_email;
  }
  if (region == 'in') {
    reg_URL = section?.sectionContent[4]?.linkUrl;
  }
  const router = useRouter();
  const { others } = router.query;
  let staticPrice = 'false';

  const usRegionContent = router.asPath.includes('us/en/');
  const caRegionContent = router.asPath.includes('ca/en/');
  const programNote = section?.sectionContent?.find(
    e => e.fieldId === 'prgm_note'
  );

  if (usRegionContent || caRegionContent) {
    staticPrice = 'true';
  }
  //Check if page is PBK online
  const isPbkOnlinePage =
    others === 'pancha-bhuta-kriya-online-with-sadhguru-mahashivratri';

  const [timezone, setTimezone] = useState(null);
  // PBK timing based on user device timezone
  let deferHours = '';
  let deferMins = '';
  const prgTiming = section;
  const prgTimingData = searchByModelApiKey(
    section.sectionContent,
    'json_table'
  );

  const updateTimes = el => {
    const time = el.split('.');
    setDeferHours(time[0]);
    setDeferMins(time[1] === '5' ? '30' : '');
  };

  const getDeferredTime = (date, deferredHours, deferredMins) => {
    let prgDate = new Date(date);
    prgDate = prgDate.toLocaleString('en-US');

    prgDate = new Date(prgDate);
    if (deferredHours) {
      prgDate.setHours(prgDate.getHours() + parseInt(deferredHours));
    }
    if (deferredMins) {
      prgDate.setMinutes(prgDate.getMinutes() + parseInt(deferredMins));
    }
    return prgDate;
  };

  let startTime = getDeferredTime(
    prgTimingData?.data[0]?.StartTime,
    deferHours,
    deferMins
  );
  let endTime = getDeferredTime(
    prgTimingData?.data[0]?.EndTime,
    deferHours,
    deferMins
  );
  let month = startTime.toLocaleString('default', {
    month: 'short',
  });
  let day = startTime.toLocaleString('default', {
    day: 'numeric',
  });
  startTime =
    startTime.toString() !== 'Invalid Date'
      ? `${startTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })} - `
      : '';
  endTime =
    endTime.toString() !== 'Invalid Date'
      ? endTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
      : '';

  useEffect(() => {
    const userTimezone = moment.tz(moment.tz.guess()).zoneAbbr();
    setTimezone(userTimezone);
  }, []);

  const noteContent = section => {
    return (
      <>
        <StructuredTextParser
          str={render(section?.sectionContent[2]?.body)}
          region={region}
          lang={lang}
          color="#28231E"
          lineHeight="30px"
          fontFamily="'FedraSansStd-book', sans-serif"
          fontSize={{ base: '16px', md: '16px' }}
          mt="10px"
          mb="10px"
        />
      </>
    );
  };

  const contentUSRegion = section => {
    return (
      <Box
        w="100%"
        background="linear-gradient(180deg, #FFF7E2 0%, #FFFCF4 100%)"
        pt={{ md: isPbkOnlinePage ? '0' : '23.44px', base: '21px' }}
        mt={{ base: isPbkOnlinePage ? '50px' : '0' }}
      >
        <SectionTitle titleObj={section.sectionContent[0]} />
        <Box color="#28231E" w={{ base: '90%', md: '255px' }} m="35px auto">
          <SingleLineText
            sectionContent={section?.sectionContent[1]}
            fontFamily="FedraSansStd-book"
            fontSize="16px"
            textAlign="center"
            color="#28231E"
            my="0"
            mb="0px"
          />
          <Box display={'flex'} m="0 auto" justifyContent={'center'}>
            <Text
              fontFamily="FedraSansStd-book"
              fontSize="16px"
              textAlign="center"
              color="#28231E"
              my="0"
              mb="0px"
              pr="10px"
            >
              {noteContent(section)}{' '}
            </Text>
            <Text fontWeight={'bold'}>
              {prgPrice} {currency_code}
            </Text>
          </Box>
        </Box>
        {section?.sectionContent[3]?._modelApiKey === 'simple_button' && section?.sectionContent[3]?.buttonText != '' && (
        <Box
          pt={{ md: '0', base: '0' }}
          pb={{ md: '35px', base: '30px' }}
          d="flex"
          placeContent="center"
        >
          <Flex placeContent="center" w="100%" mt="10px">
            <Box d="flex" placeContent="center" m="0">
              <Link
                href={
                  // currentRegion == 'IN'
                  //   ? 
                    refineUrlDomain(reg_URL, region, lang)
                    // : refineUrlDomain(
                    //     section?.sectionContent[2]?.linkUrl,
                    //     region,
                    //     lang
                    //   )
                }
                mb="0"
                display="block"
                fontWeight={'500'}
                fontFamily={'FedraSansStd-medium'}
                fontSize={'20px'}
                bgColor={reg_URL != '' ? '#FFAE27' : '#909090'}
                lineHeight="24px"
                padding={4}
                width={{ base: '100%', md: '216px' }}
                textAlign="center"
                borderRadius="5px"
                color={reg_URL != '' ? '#000' : '#fff'}
                textDecoration="none"
                minW="127px"
                minH="58px"
                cursor={reg_URL ? 'pointer' : 'not-allowed'}
                _hover={{
                  textDecoration: 'none',
                  bg: reg_URL != '' ? '#cf4520' : '#909090',
                  color: reg_URL != '' ? '#fff' : '#000',
                }}
                _focus={{
                  background: '#cf4520',
                  border: '1px solid #F5E3B5',
                  color: '#000000',
                }}
                _active={{
                  background: '#cf4520',
                  border: '1px solid #F5E3B5',
                  color: '#000000',
                }}
              >
                {section?.sectionContent[3]?.buttonText}
              </Link>
            </Box>
          </Flex>
        </Box>
        )}
        {section?.sectionContent[3]?._modelApiKey === 'single_line_text' && (
        <>
          <SingleLineText
            sectionContent={section?.sectionContent[3]}
            fontFamily="FedraSansStd-medium"
            fontSize="20px"
            textAlign="center"
            color="#28231E"
            my="0"
            mb="0px"
          />
        </>
        )}
        <Box
          mb="10px"
          color="#28231E"
          w={{ base: '90%', md: '466px' }}
          m="0 auto"
        >
          <StructuredTextParser
            str={render(section?.sectionContent[4]?.body)}
            region={region}
            lang={lang}
            color="#28231E"
            lineHeight="30px"
            fontFamily="'FedraSansStd-book', sans-serif"
            fontSize={{ base: '16px', md: '16px' }}
            mt="30px"
            mb="30px"
            textAlign="center"
          />
        </Box>

        <MidContainer minW="300px" w="100%" mt={{ base: '20px', md: '55px' }}>
          <Box display={'flex'} m="0 auto" justifyContent={'center'}>
            <Box>
              <SingleLineText
                sectionContent={section?.sectionContent[5]}
                fontFamily="FedraSansStd-book"
                fontSize="16px"
                textAlign="center"
                fontWeight="bold"
                my="0"
                mb="0px"
              />
            </Box>
            {section?.sectionContent[13]?.text != '' && (<>
            <Box pl="5px">
              <Text fontWeight={'bold'}>
                {section?.sectionContent[13]?.text}{' '}
              </Text>
            </Box>
            </>)}
          </Box>
          {section?.sectionContent[6]?.text != '' && (
            <SingleLineText
              sectionContent={section?.sectionContent[6]}
              fontFamily="FedraSansStd-book"
              fontSize="16px"
              fontWeight="bold"
              textAlign="center"
              my="0"
              mb={{ base: '20px', md: '20px' }}
            />
          )}
          {section?.sectionContent[7]?.buttonText != '' && (
            <Box
              pt={{ md: '0', base: '0' }}
              pb={{ md: '15px', base: '15px' }}
              d="flex"
              placeContent="center"
            >
              <Flex placeContent="center" w="100%" mt="10px">
                <Box d="flex" placeContent="center" m="0">
                  <Link
                    href={refineUrlDomain(
                      section?.sectionContent[7]?.linkUrl,
                      region,
                      lang
                    )}
                    mb="0"
                    display="block"
                    fontWeight={'500'}
                    fontFamily={'FedraSansStd-medium'}
                    fontSize={'16px'}
                    bgColor={`linear-gradient(0deg, #E79D41, #E79D41) rgba(231, 157, 65, 0.1)`}
                    h={'auto'}
                    textAlign="center"
                    padding={'6px 40px'}
                    border={'1px solid #E79D41'}
                    borderRadius="5px"
                    color={
                      section?.sectionContent[7]?.linkUrl != ''
                        ? '#E79D41'
                        : '#000'
                    }
                    textDecoration="none"
                    width={'auto'}
                    minW="127px"
                    minH="38px"
                    cursor={
                      section?.sectionContent[7]?.linkUrl
                        ? 'pointer'
                        : 'not-allowed'
                    }
                    _hover={{
                      textDecoration: 'none',
                      bg:
                        section?.sectionContent[7]?.linkUrl != ''
                          ? '#E79D41'
                          : '#909090',
                      color:
                        section?.sectionContent[7]?.linkUrl != ''
                          ? '#fff'
                          : '#000',
                    }}
                    _focus={{
                      background: '#cf4520',
                      border: '1px solid #E79D41',
                      color: '#000000',
                    }}
                    _active={{
                      background: '#cf4520',
                      border: '1px solid #E79D41',
                      color: '#000000',
                    }}
                    target="_blank"
                  >
                    {section?.sectionContent[7]?.buttonText}
                  </Link>
                </Box>
              </Flex>
            </Box>
          )}
        </MidContainer>
        <Box
          mb="10px"
          className="event-text"
          color="#28231E"
          w={{ base: '90%', md: '580px' }}
          m="15px auto 0 auto"
        >
          <StructuredTextParser
            str={render(section?.sectionContent[14]?.body)}
            region={region}
            lang={lang}
            color="#28231E"
            lineHeight="26px"
            fontFamily="'FedraSansStd-book', sans-serif"
            fontSize={{ base: '16px', md: '16px' }}
            mt="0px"
            mb="30px"
            textAlign="center"
          />
        </Box>
        <Box
          mb="10px"
          className="event-text"
          color="#28231E"
          w={{ base: '90%', md: '466px' }}
          m="15px auto 0 auto"
        >
          <StructuredTextParser
            str={render(section?.sectionContent[8]?.body)}
            region={region}
            lang={lang}
            color="#28231E"
            lineHeight="30px"
            fontFamily="'FedraSansStd-book', sans-serif"
            fontSize={{ base: '16px', md: '16px' }}
            mt="0px"
            mb="30px"
            textAlign="center"
          />
        </Box>
        {pg_notes != '' && region != 'us' && region != 'ca' && (
          <>
            <MidContainer
              w={{ base: '90%', md: '666px' }}
              pt={{ lg: '10px', base: '15px' }}
              pl={{ base: '16px' }}
              pr={{ base: '15px' }}
              color="#28231E"
              fontFamily="FedraSansStd-book"
              fontSize={{ base: '16px', md: '16px' }}
              m="0 auto 50px auto"
            >
              <Box bgColor={'#FFEAC1'} p="16px">
                <Text as="span" fontWeight={'bold'}>
                  {programNote && (
                    <>
                      {
                        searchByFieldId(section.sectionContent, 'prgm_note')
                          .text
                      }
                    </>
                  )}
                </Text>{' '}
                {pg_notes}
              </Box>
            </MidContainer>
          </>
        )}
        {section?.sectionContent[9]?.titleText != '' && (
          <SectionTitle titleObj={section?.sectionContent[9]} />
        )}
        {section?.sectionContent[10]?.body[0]?.value != '' && (
          <Box pb="30px">
            <BrownSpiralBullets
              sectionContent={section?.sectionContent[10]}
              pt="0"
            />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      {region != 'us' && region != 'ca' && (
        <Box
          w="100%"
          background="linear-gradient(180deg, #FFF7E2 0%, #FFFCF4 100%)"
          pt={{ md: isPbkOnlinePage ? '0' : '23.44px', base: '21px' }}
          mt={{ base: isPbkOnlinePage ? '50px' : '0' }}
          // https://www.datocms-assets.com/46272/1689699735-brown-flower-bullet.png
        >
          <SectionTitle titleObj={section.sectionContent[0]} />
          <Box mb="10px" color="#28231E">
            <BrownSpiralBullets sectionContent={section.sectionContent[1]} />
          </Box>
          <MidContainer
            w="568px"
            pt={{ lg: '10px', base: '15px' }}
            pl={{ base: '16px' }}
            pr={{ base: '15px' }}
            color="#28231E"
            fontFamily="FedraSansStd-book"
            fontSize={{ base: '16px', md: '16px' }}
            mb="30px"
            fontStyle="italic"
          >
            {/* {region != 'in' &&
              // pg_notes != '' &&
              section?.sectionContent[2]?.body?.value?.document?.children[0]
                ?.children[0]?.value == '' && (
                <>
                  {programNote && (
                    <>
                      {
                        searchByFieldId(section?.sectionContent, 'prgm_note')
                          ?.text
                      }
                    </>
                  )}{' '}
                  {pg_notes}
                </>
              )} */}
            {/* {region === 'in' && <>{noteContent(section)}</>} */}
            {/* {region === 'global' && (
              <>
                {programNote.text} {pg_notes}
              </>
            )} */}
            {/* {section?.sectionContent[2]?.body?.value?.document?.children[0]
                ?.children[0]?.value != '' ? noteContent(section) :  */}
            {region !== 'global' ? noteContent(section) : 
                (<>
                    {
                      searchByFieldId(section?.sectionContent, 'prgm_note')
                        ?.text
                    }
                  
                {' '}
                {pg_notes}</>)
              }
          </MidContainer>
          <MidContainer minW="300px" w="100%">
            <Box
              fontFamily="FedraSansStd-book"
              fontWeight="bold"
              textAlign="center"
              fontSize="22px"
              pb="10px"
            >
              {prgPrice != null && currentRegion != 'IN' ? (
                <>
                  {csrData && <>Fee :</>} {currency_code} {prgPrice}
                </>
              ) : (
                <>
                <SingleLineText
                  sectionContent={section?.sectionContent[3]}
                  mb={{ base: '10px', md: '10px' }}
                  textAlign="center"
                  pb="0"
                  my="0"
                /></>
              )}
            </Box>
            {section?.sectionContent[4]?._modelApiKey === 'simple_button' && section?.sectionContent[4]?.buttonText != '' && (
            <Box
              pt={{ md: '0', base: '0' }}
              pb={{ md: '35px', base: '30px' }}
              d="flex"
              placeContent="center"
            >
              <Flex placeContent="center" w="100%" mt="10px">
                <Box d="flex" placeContent="center" m="0">
                  <Link
                    href={refineUrlDomain(reg_URL, region, lang)}
                    mb="0"
                    display="block"
                    fontWeight={'500'}
                    fontFamily={'FedraSansStd-medium'}
                    fontSize={'20px'}
                    bgColor={reg_URL != '' ? '#FFAE27' : '#909090'}
                    lineHeight="24px"
                    padding={4}
                    width={{ base: '100%', md: '216px' }}
                    textAlign="center"
                    borderRadius="5px"
                    color={reg_URL != '' ? '#000' : '#fff'}
                    textDecoration="none"
                    minW="127px"
                    minH="58px"
                    cursor={reg_URL ? 'pointer' : 'not-allowed'}
                    _hover={{
                      textDecoration: 'none',
                      bg: reg_URL != '' ? '#cf4520' : '#909090',
                      color: reg_URL != '' ? '#fff' : '#000',
                    }}
                    _focus={{
                      background: '#cf4520',
                      border: '1px solid #F5E3B5',
                      color: '#000000',
                    }}
                    _active={{
                      background: '#cf4520',
                      border: '1px solid #F5E3B5',
                      color: '#000000',
                    }}
                  >
                    {section?.sectionContent[4]?.buttonText}
                  </Link>
                </Box>
              </Flex>
            </Box>
            )}
            {section?.sectionContent[4]?._modelApiKey === 'single_line_text' && (
              <>
                <SingleLineText
                  sectionContent={section?.sectionContent[4]}
                  fontFamily="FedraSansStd-medium"
                  fontSize="20px"
                  textAlign="center"
                  color="#28231E"
                  my="0"
                  mb="0px"
                />
            </>)}
          </MidContainer>

          {section?.sectionContent[5]?.titleText != '' && (
            <SectionTitle titleObj={section?.sectionContent[5]} />
          )}
          {section?.sectionContent[6]?.body?.[0]?.value != '' && (
            <Box pb="30px">
              <BrownSpiralBullets sectionContent={section?.sectionContent[6]} />
              <MidContainer
                w="568px"
                pt={'0'}
                pl={{ base: '35px' }}
                pr={{ base: '15px' }}
              >
                <Box
                  className={styles.BrownSpiralBullets}
                  color="#28231E"
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="28px"
                >
                  <Box as="p">
                    {section?.sectionContent[7]?.text}{' '}
                    {section?.sectionContent[8]?.text}{' '}
                    {section?.sectionContent[9]?.text}
                  </Box>
                </Box>
              </MidContainer>
              <BrownSpiralBullets
                sectionContent={section?.sectionContent[10]}
                pt="0"
              />
            </Box>
          )}
          <style jsx>
            {`
              .pbk_link a {
                border-bottom: 2px solid #cf4824;
              }
            `}
          </style>
        </Box>
      )}
      {/* US  Region */}
      {region === 'us' && <>{contentUSRegion(section)}</>}
      {region === 'ca' && <>{contentUSRegion(section)}</>}
    </>
  );
};

export default ProgDetailPBK;
