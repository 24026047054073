/* Built In Imports */
/* External Imports */
import { Box, SimpleGrid } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import TopBannerSimple from '@components/HeaderBanner/TopBannerSimple';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';
import { Styles } from '../../constants';

/* Services */

/**
 * Renders the Main Centers Default Image Section component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} Main Centers Default Image Section component
 */
const MainCentersDefaultImage = ({ sectionContent }) => {
  const bgColors = Styles.bgColors;

  return (
    <Box
      id="card3in"
      // mx="auto"
      width="100%"
      maxW={{
        base: '100%',
        // sm: '480px',
        // md: '690px',
        // lg: '770px',
        xl: '100%',
      }}
    >
      <TopBannerSimple sectionContent={sectionContent[0]} />
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, xl: 3 }}
        spacing="30px"
        // marginTop={{ base: '60px', md: '80px' }}
        mx="auto"
      >
        {sectionContent[1]?.cards?.map((secCon, index) => {
          return (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              mx="auto"
              marginTop="30px"
              w={{
                base: '302px',
                sm: '330px',
                lg: '370px',
              }}
              position="relative"
            >
              <LazyLoadImageComponent
                src={`${config.imgPath}/d/46272/1663580969-isha-center_17.png`}
                alt={secCon.thumbnail?.alt}
                title={secCon.thumbnail?.title}
                objectFit="cover"
                w="100%"
                minH={{ base: 'auto', lg: 214 }}
                maxH={{ base: 'auto', lg: 214 }}
              />
              <Box
                w="90%"
                h={{ base: '235px', sm: '250px', lg: '235px' }}
                objectFit="contain"
                transform="rotate(-178.76deg)"
                position="absolute"
                left="13.5px"
                background={bgColors[index % bgColors.length]}
                outline="1px solid transparent"
                zIndex="-1"
                top="-10px"
              ></Box>
              <Box m={{ base: '0 29px' }}>
                {/* <Box height="20px" bgColor="#a03319"></Box> */}
                <Box
                  lineHeight={1.1}
                  fontFamily="FedraSerifAStdBook"
                  fontSize={{ base: '24px', lg: '27px' }}
                  mt="35px"
                  color="#28231e"
                >
                  {secCon.title}
                </Box>
                <Box
                  fontFamily="FedraSansStd-book"
                  fontSize="16px"
                  color={'gray.200'}
                  pt={15}
                >
                  <StructuredTextParser str={render(secCon.description)} />
                </Box>
              </Box>
              <style global jsx>
                {`
                  #card3in a {
                    color: #cf4824 !important;
                  }
                `}
              </style>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default MainCentersDefaultImage;
