/* Built In Imports */

/* External Imports */
import { Box, Image } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import SectionUI from '@components/UI/Common/SectionUI';

/**
 * Renders the Celebrate the weaves Ui component
 *
 * @param {object} section - Data for section
 * @param {string} region - Region for the section
 * @param {string} lang - language for the section
 * @returns {ReactElement} Celebrate the weaves sectionID UI component
 */

const EducationalInitiatives = ({ section, region, lang }) => {
  const sectionContent = section?.sectionContent;
  return (
    <Box
      bgImage={section.styleConfig?.bgImage?.url}
      w="full"
      py="50px"
      bgSize="cover"
    >
      {sectionContent?.map((item, index) => {
        return (
          <Box pos="relative" key={index}>
            {item._modelApiKey === 'single_image' && (
              <Box
                pos="absolute"
                left="180px"
                top="-230px"
                zIndex={2}
                display={{ base: 'none', md: 'block' }}
              >
                <Image src={item?.image?.url} alt={item?.image?.alt} title={item?.image?.title} />
              </Box>
            )}

            <Box mt="30px">
              <SectionUI
                key={nanoid()}
                sectionContent={!item.image?.url ? item : ''}
                region={region}
                language={lang}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default EducationalInitiatives;
