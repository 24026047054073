/* Built In Imports  */
import React from 'react';
/* External Imports */
import config from '@config';

import { Box, Image } from '@chakra-ui/react';

/**
 * Renders SP Section Header
 * @param {string} HeaderText - Text in Header Section
 * @param {string} Color - Font color 
 * @param {string} img - Image Source 
 * @returns {ReactElement} - SP Section Header 
 */
export default function SPSectionHeader({
  HeaderText,
  color = '#28231e',
  img = `${config.imgPath}/d/46272/1663649860-divider4.svg`,
  // img = 'https://isha.sadhguru.org/profiles/isha/themes/ishatheme/sadhanapada-v2/images/divider4.svg',
}) {
  return (
    <Box zIndex="1" w="100%" pos="relative">
      <Box
        fontFamily='"FedraSansStd-A-medium", sans-serif!important'
        fontSize={{ base: '20px', md: '30px', lg: '30px' }}
        lineHeight="1.13"
        color={color}
      >
        {HeaderText}
      </Box>
      <Image src={img} alt="divider" m="20px 0" title="divider"></Image>
    </Box>
  );
}
