/* Built In Imports */
import { useRouter } from 'next/router';
import { useState } from 'react';

/* External Imports */
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Link,
  Text
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

/* Internal Imports */
/* Components */
import InputUI from '@components/Elements/InputUI';
import SectionTitle from '@components/Headings/SectionTitle';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */
import { pushIdyInitiatives } from '@Services/othersService';
import { searchByFieldId } from '@components/Utility/Shared/SharedService';

/**
 * Renders ChallengeForm Component
 * @param {Object} sectionContent - data from api for the section
 * @param {string} region - Region of the Section
 * @param {string} lang - Language of the Section
 * @returns {ReactElement} - ChallengeForm
 */
const ChallengeForm = ({ sectionContent, region, lang }) => {
  const router = useRouter();
  const [formResponse, setFormResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phoneNo, setPhoneNo] = useState('');
  const [otherLangText, setOtherLangText] = useState(-1);
  // console.log('ChallengeForm', sectionContent);

  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^([a-zA-Z ])+$/u, {
        message: 'First Name is invalid',
      })
      .max(100, 'First Name is invalid')
      .required('First Name is required'),
    last_name: Yup.string()
      .matches(/^([a-zA-Z ])+$/u, {
        message: 'Last Name is invalid',
      })
      .max(100, 'Last Name is invalid')
      .required('Last Name is required'),
    email: Yup.string()
      .matches(/^[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z]+\.[a-zA-Z.]{2,15}$/, {
        message: 'Email is invalid',
      })
      .required('Email is required'),
    // phoneCode: Yup.string().required('Phone Code is required'),
    tcCheck: Yup.boolean()
      .oneOf([true], 'Please select')
      .required('Please select'),
    privacyCheck: Yup.boolean()
      .oneOf([true], 'Please select')
      .required('Please select'),
    // understandCheck: Yup.boolean()
    //   .oneOf([true], 'Please select')
    //   .required('Please select'),
    // whatsapp: Yup.string()
    //   .max(20, 'Invalid phone number')
    //   .required('Phone number is required')
    //   .matches(/^([0-9]{10,15})+$/u, 'Invalid phone number'),
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoading(true);
    const formValues = {
      "message": {
        "system_id": "110",
        "initiative_name": "21 Day Challenge",
        "first_name": values.first_name,
        "last_name": values.last_name,
        "email": values.email,
        "whatsapp": "",
        "ip_country": region?.toUpperCase(),
        "reg_status": router.asPath.indexOf('refer-21-') > -1 ? "REFERRER" : "REGISTERED",
        "utm_campaign": router.query.utm_campaign || null,
        "utm_medium": router.query.utm_medium || null,
        "utm_source": router.query.utm_source || null,
        "utm_term": router.query.utm_term || null,
        "utm_content": router.query.utm_content || null,
        "referrer_id": router.query.referrer_id || '',
      }
    };
    setSubmitting(true);
    // console.log('formValues', formValues);
    setSubmitting(false);
    let formResponse = await pushIdyInitiatives(formValues);
    // console.log('formResponse', formResponse);
    setFormResponse(formResponse);
    // console.log('downloadNowLink', sectionContent[0]?.data?.downloadNowLink)
    window.location.href = sectionContent[0]?.data?.downloadNowLink;
    setLoading(false);
    resetForm();
  };

  return router.query.referrer_id && (router.query.region !== 'in') && (
    <Box
      pos="relative"
      w="100%"
      textAlign="center"
      py="50px"
      bgColor="#F5F0E5"
      // maxW="1011px"
      m="100px auto 0"
    >
      <Box
        p={{ base: '0 0 0 0', lg: '0 10px 0 0' }}
        pos="relative"
        left={{ base: '0' }}
        width="100%"
      >
        <Flex
          flexDir="column"
          w={{ base: '95%', md: '458px' }}
          pt="20px"
          mx="auto"
        >
          {(
            <>
              <Box textAlign="center" mb="1.5rem">
                <SectionTitle
                  titleObj={{
                    titleText: sectionContent[0]?.data?.title,
                    titleDecoration: 'idy-subtext',
                  }}
                  maxW="500px"
                />
                {sectionContent[0]?.data?.subTitle && (
                  <Text
                    fontSize="16px"
                    maxW={{ base: '80%', md: '372px' }}
                    fontFamily="FedraSansStd-book"
                    m="10px auto"
                  >
                    {sectionContent[0]?.data?.subTitle}
                  </Text>
                )}
              </Box>

              <Formik
                initialValues={{
                  first_name: '',
                  last_name: '',
                  email: '',
                  tcCheck: '',
                  privacyCheck: '',
                  // understandCheck: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {props => (
                  <Form
                    onSubmit={props.handleSubmit}
                    style={{
                      display: 'grid',
                      rowGap: '20px',
                      boxSizing: 'border-box',
                    }}
                  >
                    {/* <Box
                      as="h3"
                      fontSize="16px"
                      fontFamily="FedraSansStd-book"
                      maxW="500px"
                      m={{ base: '0', md: '0 auto' }}
                      textAlign={{ base: 'left', md: 'center' }}
                    >
                      {sectionContent[0]?.data?.enterDetails}
                    </Box> */}
                    <Flex gap={{ base: '20px', md: '15px' }} flexDirection={{ base: 'column', md: 'row' }}>
                      <Field name="first_name">
                        {({ field, form }) => (
                          <FormControl
                            isRequired
                            isInvalid={form.errors.first_name && form.touched.first_name}
                            display="flex"
                            flexDir="column"
                            fontFamily="FedraSansStd-book"
                          >
                            <InputUI
                              // wd={{ md: '215px', sm: '460px', base: '300px' }}
                              // m={{ md: '0 0 0 15px', sm: '0', base: '0' }}
                              fontFamily="'FedraSansStd-book', sans-serif"
                              color="#605D5D"
                              field={field}
                              id="firstName"
                              height={{ base: "62px", md: "56px" }}
                              placeholder={sectionContent[0]?.data?.firstName}
                              onChange={props.handleChange}
                              padding="20px"
                              borderRadius="6px"
                              _placeholder={{
                                color: '#605D5D',
                                fontSize: '16px',
                              }}
                            />
                            <FormErrorMessage justifyContent="left">
                              {form.errors.first_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="last_name">
                        {({ field, form }) => (
                          <FormControl
                            isRequired
                            isInvalid={form.errors.last_name && form.touched.last_name}
                            display="flex"
                            flexDir="column"
                            fontFamily="FedraSansStd-book"
                          >
                            <InputUI
                              // wd={{ md: '215px', sm: '460px', base: '300px' }}
                              // m={{ md: '0 0 0 15px', sm: '0', base: '0' }}
                              fontFamily="'FedraSansStd-book', sans-serif"
                              color="#605D5D"
                              field={field}
                              id="lastName"
                              height={{ base: "62px", md: "56px" }}
                              placeholder={sectionContent[0]?.data?.lastName}
                              onChange={props.handleChange}
                              padding="20px"
                              borderRadius="6px"
                              _placeholder={{
                                color: '#605D5D',
                                fontSize: '16px',
                              }}
                            />
                            <FormErrorMessage justifyContent="left">
                              {form.errors.last_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Flex>
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isRequired
                          isInvalid={form.errors.email && form.touched.email}
                          display="flex"
                          flexDir="column"
                          fontFamily="FedraSansStd-book"
                        >
                          <InputUI
                            // wd={{ md: '215px', sm: '460px', base: '300px' }}
                            // m={{ md: '0 0 0 15px', sm: '0', base: '0' }}
                            fontFamily="'FedraSansStd-book', sans-serif"
                            color="#605D5D"
                            field={field}
                            id="email"
                            type="email"
                            height={{ base: "62px", md: "56px" }}
                            placeholder={sectionContent[0]?.data?.email}
                            onChange={props.handleChange}
                            padding="20px"
                            borderRadius="6px"
                            _placeholder={{
                              color: '#605D5D',
                              fontSize: '16px',
                            }}
                          />
                          <FormErrorMessage justifyContent="left">
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    {/* <Flex gap={{ base: '5px', md: '8px' }}>
                      <Field name="phoneCode" p={{ base: '0', md: '0 5px' }}>
                        {({ field, form }) => (
                          <FormControl
                            isRequired
                            isInvalid={form.errors.country}
                            maxWidth="100%"
                            p={{ base: '0', md: '0 10px' }}
                            maxW="144px"
                            w="25%"
                          >
                            <Box>
                              <Select
                                background="#FFFFFF"
                                borderRadius="6px"
                                border="0"
                                maxW="145px"
                                height={{ base: '63px', md: '63px' }}
                                fontFamily="FedraSansStd-Book"
                                {...field}
                                isRequired={true}
                                onChange={e => {
                                  // console.log('eee', e.target.selectedOptions[0].value);
                                  if (
                                    e.target.selectedOptions[0].value !== '+91'
                                  ) {
                                    setOtherLangText(1);
                                  } else {
                                    setOtherLangText(0);
                                  }
                                }}
                              >
                                <option value={''} key={''}>
                                  Select
                                </option>
                                ,
                                <option value={'+91'} key={'in'}>
                                  +91 India
                                </option>
                                <option value={'+1'} key={'us-ca'}>
                                  +1 US and Canada
                                </option>
                                {phoneWithoutChinaCode.map((country, index) => (
                                  <option
                                    value={country.phone_code}
                                    key={index}
                                  >
                                    {country.phone_code} {country.name}
                                  </option>
                                ))}
                              </Select>
                              <FormErrorMessage>
                                {form.errors.country}
                              </FormErrorMessage>
                            </Box>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="whatsapp" w="80%">
                        {({ field, form }) => (
                          <FormControl
                            isRequired
                            isInvalid={
                              form.errors.whatsapp && form.touched.whatsapp
                            }
                          >
                            <InputUI
                              fontFamily="'FedraSansStd-book', sans-serif"
                              color="#605D5D"
                              border="none"
                              field={field}
                              id="whatsapp"
                              height={{ base: '63px', md: '63px' }}
                              placeholder={
                                sectionContent[0]?.data?.whatsAppNumber
                              }
                              borderRadius="6px"
                              bgColor={
                                otherLangText < 0 ? '#E3E3E3' : '#FFFFFF'
                              }
                              onChange={props.handleChange}
                              disabled={otherLangText < 0}
                              type="number"
                              padding="20px"
                              onKeyUp={e => {
                                // console.log('e.targe', e.target.value)
                                setPhoneNo(e.target.value);
                              }}
                              _placeholder={{
                                color: '#605D5D',
                                fontSize: '16px',
                              }}
                            />
                            <FormErrorMessage justifyContent="left">
                              {form.errors.whatsapp}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Flex> */}

                    <Box maxW="468px" m="0 auto" textAlign="center">
                      {searchByFieldId(sectionContent, 't-c-check') && (
                        <Flex mb="30px">
                          <Field name="tcCheck">
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.tcCheck && form.touched.tcCheck
                                }
                                isRequired
                              >
                                <Box
                                  display={{ base: '-webkit-box', md: 'flex' }}
                                  fontSize="14px"
                                >
                                  <Checkbox
                                    colorScheme="orange"
                                    onChange={props.handleChange}
                                    checked={props.values.tcCheck}
                                    alignItems="flex-start"
                                    size="md"
                                    align="top"
                                    {...field}
                                  >
                                    <StructuredTextParser
                                      str={render(
                                        searchByFieldId(
                                          sectionContent,
                                          't-c-check'
                                        ).body
                                      )}
                                      region={region}
                                      lang={lang}
                                      mt="-5px"
                                      className="reg-check"
                                    />
                                  </Checkbox>
                                </Box>
                                <FormErrorMessage justifyContent="left">
                                  {form.errors.tcCheck}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Flex>
                      )}

                      {searchByFieldId(sectionContent, 'privacy-check') && (
                        <Flex mb="30px">
                          <Field name="privacyCheck">
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.privacyCheck &&
                                  form.touched.privacyCheck
                                }
                                isRequired
                              >
                                <Box
                                  display={{ base: '-webkit-box', md: 'flex' }}
                                  fontSize="14px"
                                >
                                  <Checkbox
                                    colorScheme="orange"
                                    onChange={props.handleChange}
                                    checked={props.values.privacyCheck}
                                    alignItems="flex-start"
                                    align="top"
                                    size="md"
                                    {...field}
                                  >
                                    <StructuredTextParser
                                      str={render(
                                        searchByFieldId(
                                          sectionContent,
                                          'privacy-check'
                                        ).body
                                      )}
                                      region={region}
                                      lang={lang}
                                      mt="-5px"
                                      className="reg-check"
                                    />
                                  </Checkbox>
                                </Box>
                                <FormErrorMessage justifyContent="left">
                                  {form.errors.privacyCheck}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Flex>
                      )}

                      {/* {searchByFieldId(sectionContent, 'understand-check') && (
                        <Flex mb="30px">
                          <Field name="understandCheck">
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.understandCheck &&
                                  form.touched.understandCheck
                                }
                                isRequired
                              >
                                <Box
                                  display={{ base: '-webkit-box', md: 'flex' }}
                                  fontSize="14px"
                                >
                                  <Checkbox
                                    colorScheme="orange"
                                    onChange={props.handleChange}
                                    checked={props.values.understandCheck}
                                    alignItems="flex-start"
                                    align="top"
                                    size="md"
                                    {...field}
                                  >
                                    <StructuredTextParser
                                      str={render(
                                        searchByFieldId(
                                          sectionContent,
                                          'understand-check'
                                        ).body
                                      )}
                                      region={region}
                                      lang={lang}
                                      mt="-5px"
                                      className="reg-check"
                                    />
                                  </Checkbox>
                                </Box>
                                <FormErrorMessage justifyContent="left">
                                  {form.errors.understandCheck}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Flex>
                      )} */}
                    </Box>

                    {otherLangText && otherLangText > -1 ? (
                      <Flex
                        fontSize="16px"
                        fontFamily="'FedraSansStd-book', sans-serif"
                        maxW="500px"
                        m="20px auto 20px"
                      >
                        {sectionContent[0]?.data?.outsideIndiaText}
                      </Flex>
                    ) : null}

                    <Box
                      display={'grid'}
                      // visibility={
                      //   otherLangText > -1 && phoneNo ? 'visible' : 'hidden'
                      // }
                      placeContent="center"
                      mt="10px"
                    >
                      <Button
                        type="submit"
                        className="orange-button"
                        mb={'45px'}
                        display="block"
                        fontWeight={'500'}
                        fontFamily={'FedraSansStd-medium'}
                        fontSize={'16px'}
                        bgColor="#CF4520"
                        h={'50px'}
                        minH="50px"
                        border="none"
                        textAlign="center"
                        padding={'13px 50px'}
                        borderRadius="5px"
                        color="white"
                        textDecoration="none"
                        width={'auto'}
                        disabled={
                          !props.isValid || !props.dirty || loading
                            ? true
                            : false
                        }
                        target="_blank"
                      // onClick={(e) => { window.location.href = sectionContent[0]?.data?.downloadNowLink }}
                      >
                        <Flex justifyContent="center" alignItems="center">
                          <Text pr={'5px'}>
                            <span>
                              {sectionContent[0]?.data?.downloadNow}
                            </span>
                          </Text>
                        </Flex>
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </>
          )}

          {sectionContent[0]?.data?.formHidden && (
            <>
              <Box textAlign="center" mb="1.5rem">
                <SectionTitle
                  titleObj={{
                    titleText: sectionContent[0]?.data?.title,
                    titleDecoration: 'no-decoration',
                  }}
                  maxW="500px"
                />
              </Box>
              <Text fontFamily="FedraSansStd-book" fontSize="18px">
                {sectionContent[0]?.data?.text}
              </Text>
              <Box display="grid" placeContent="center" mt="40px">
                <Button
                  type="submit"
                  className="orange-button"
                  mb={'39px'}
                  display="block"
                  fontWeight={'500'}
                  fontFamily={'FedraSansStd-medium'}
                  fontSize={'16px'}
                  bgColor="#CF4520"
                  h={'50px'}
                  minH="50px"
                  border="none"
                  textAlign="center"
                  padding={'18px 20px'}
                  borderRadius="5px"
                  color="white"
                  textDecoration="none"
                  width={'auto'}
                >
                  <Flex justifyContent="center" alignItems="center">
                    <Link
                      _hover={{
                        textDecoration: 'none',
                      }}
                      href={sectionContent[0]?.data?.downloadNowLink}
                      target="_blank"
                    >
                      {sectionContent[0]?.data?.downloadNow}
                    </Link>
                  </Flex>
                </Button>
              </Box>
            </>
          )}

          {/* {formResponse?.status === 200 && (
            <Box textAlign="center" maxW="428px" m="20px auto">
              <Image
                src="https://static.sadhguru.org/d/46272/1716485490-icon.png"
                w="70px"
                h="70px"
                m="0 auto"
              />
              <Box display="grid" placeContent="center" mt="40px">
                {searchByFieldId(sectionContent, 'reg-succes') && (
                  <StructuredTextParser
                    str={render(
                      searchByFieldId(sectionContent, 'reg-succes').body
                    )}
                    region={region}
                    lang={lang}
                    mt="0"
                    className="reg-success"
                  />
                )}
                {sectionContent[0]?.data?.downloadNow && (
                  <Button
                    type="submit"
                    className="orange-button"
                    display="block"
                    fontWeight={'500'}
                    fontFamily={'FedraSansStd-medium'}
                    fontSize={'16px'}
                    bgColor="#CF4520"
                    h={'50px'}
                    minH="50px"
                    border="none"
                    textAlign="center"
                    padding={'18px 20px'}
                    borderRadius="5px"
                    color="white"
                    textDecoration="none"
                    width={'222px'}
                    m="50px auto"
                  >
                    <Flex justifyContent="center" alignItems="center">
                      <Link
                        _hover={{
                          textDecoration: 'none',
                        }}
                        href={sectionContent[0]?.data?.downloadNowLink}
                        target="_blank"
                      >
                        {sectionContent[0]?.data?.downloadNow}
                      </Link>
                    </Flex>
                  </Button>
                )}
              </Box>
            </Box>
          )} */}

          {/* {formResponse?.status > 200 && (
            <Box textAlign="center" maxW="428px" m="20px auto">
              <Image
                src="https://static.sadhguru.org/d/46272/1716485465-group-3682.png"
                w="70px"
                h="70px"
                m="0 auto"
              />
              <Box display="grid" placeContent="center" mt="40px">
                {searchByFieldId(sectionContent, 'reg-failed') && (
                  <StructuredTextParser
                    str={render(
                      searchByFieldId(sectionContent, 'reg-failed').body
                    )}
                    region={region}
                    lang={lang}
                    mt="0"
                    className="reg-success"
                  />
                )}
                <Button
                  type="submit"
                  className="orange-button"
                  display="block"
                  fontWeight={'500'}
                  fontFamily={'FedraSansStd-medium'}
                  fontSize={'16px'}
                  bgColor="#CF4520"
                  h={'50px'}
                  minH="50px"
                  border="none"
                  textAlign="center"
                  padding={'18px 20px'}
                  borderRadius="5px"
                  color="white"
                  textDecoration="none"
                  width={'177px'}
                  m="50px auto"
                  onClick={() => {
                    reset();
                  }}
                >
                  <Flex justifyContent="center" alignItems="center">
                    {sectionContent[0]?.data?.tryAgain}
                  </Flex>
                </Button>
              </Box>
            </Box>
          )} */}
        </Flex>
      </Box>
      <style>
        {`
          .reg-success p {
            text-align: center;
            font-family: FedraSansStd-book;
            font-size: 18px;
            padding-bottom: 20px;
          }
          .reg-success strong {
            font-size: 34px;
            text-align: center;
            font-family: FedraSansStd-medium;
          }
          .reg-check {
            text-align: left;
            font-family: FedraSansStd-book;
            font-size: 16px;
          }
          .reg-check a {
            color: #CF4520;
          }
          `}
      </style>
    </Box>
  );
};

export default ChallengeForm;
