/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Image,
  Text,
  chakra,
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import StructuredTextContent from '@components/UI/StructuredTextContent';
import config from '@config';
import RenderContentSt from '@ieo/Common/StyleData/RenderContentSt';

/**
 *  @param {Object} dataChild
 *  @param {String} lang
 *  @returns {ReactElement} RenderContent
 */

const RenderContent = ({ dataChild, lang }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <Box
        background="#F1EBDF"
        h={{ base: '63px', md: '70px' }}
        mt="30px"
        display="flex"
        flexDir="row"
        justifyContent="space-between"
        onClick={() => setShowMore(!showMore)}
        cursor="pointer"
      >
        {lang === 'ar' ? (
          <>
            <Box
              pl={{ lg: '55px', md: '45px', base: '22px', sm: '15px' }}
              pt={{ base: '20px', md: '25px' }}
            >
              <Image
                loading="lazy"
                alt="arrow down image"
                title="Down Arrow"
                src={`${config.imgPath}/d/46272/1663651480-chevron-down.svg`}
                transform={showMore ? 'rotate(-180deg)' : 'rotate(0deg)'}
                w={{ base: '20px', md: '20px' }}
                h={{ base: '20px' }}
                alignItems="right"
                fill="#C5BFB1"
              />
            </Box>
            <Box>
              <Text
                pt="20px"
                pr={{ base: '10px', md: '25.32px', lg: '40px' }}
                {...RenderContentSt[lang].stMainText}
                dir={lang == 'ar' ? 'rtl' : 'ltr'}
              >
                {dataChild?.title}
              </Text>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Text
                pt="20px"
                pl={{ base: '10px', md: '25.32px' }}
                {...RenderContentSt[lang].stMainText}
              >
                {dataChild?.title}
              </Text>
            </Box>
            <Box
              pr={{ lg: '55px', md: '45px', base: '22px', sm: '15px' }}
              pt={{ base: '20px', md: '25px' }}
            >
              <Image
                loading="lazy"
                alt="arrow down image"
                title="Down Arrow"
                src={`${config.imgPath}/d/46272/1663651480-chevron-down.svg`}
                transform={showMore ? 'rotate(-180deg)' : 'rotate(0deg)'}
                w={{ base: '20px', md: '20px' }}
                h={{ base: '20px' }}
                alignItems="right"
                fill="#C5BFB1"
              />
            </Box>
          </>
        )}
      </Box>

      {dataChild?.faqs?.map((child, i) => {
        return (
          <>
            <Box mx="auto" key={i}>
              {showMore ? (
                <Accordion
                  allowToggle
                  w={{
                    base: '98.5%',
                    md: '98.5%',
                    lg: '97.5%',
                    xl: '98%',
                    '2xl': '98%',
                  }}
                  pl={{ md: '25px', lg: '25px', xl: '25px', '2xl': '25px' }}
                >
                  <AccordionItem color="#28231E" borderTop="none">
                    <AccordionButton
                      paddingTop={{ base: '15px', md: '30px' }}
                      px={lang === 'ar' ? '20px' : '-20px'}
                      pr={{ base: '10px', md: '27px' }}
                      paddingBottom={{ base: '20px', md: '30px' }}
                      borderRadius="1px"
                      _focus={{ outline: '0' }}
                      _hover={{
                        background: 'transparent',
                      }}
                      {...RenderContentSt[lang].FaqQ}
                    >
                      {lang === 'ar' ? (
                        <>
                          <AccordionIcon color="#6D6358" fontSize="2rem" />
                          <chakra.div
                            flex="1"
                            textAlign="left"
                            fontSize={{ base: '16px', md: '18px' }}
                            lineHeight={{ base: '20.16px', md: '23.4px' }}
                            color="#6D6358"
                            {...RenderContentSt[lang].FaqQ}
                          >
                            {child?.question}
                          </chakra.div>
                        </>
                      ) : (
                        <>
                          <chakra.div
                            flex="1"
                            textAlign="left"
                            fontSize={{ base: '16px', md: '18px' }}
                            lineHeight={{ base: '20.16px', md: '23.4px' }}
                            color="#6D6358"
                            {...RenderContentSt[lang].FaqQ}
                          >
                            {child?.question}
                          </chakra.div>
                          <AccordionIcon color="#6D6358" fontSize="2rem" />
                        </>
                      )}
                    </AccordionButton>
                    <AccordionPanel
                      pb={4}
                      borderTop="none"
                      p={{
                        base:
                          lang === 'ar'
                            ? '0px 10px 20px 10px'
                            : '0px 0px 20px 0px',
                        md:
                          lang === 'ar'
                            ? '0px 30px 30px 20px'
                            : '0px 20px 30px 0px',
                      }}
                      textAlign="left"
                      fontFamily="FedraSansStd-book"
                      fontSize="16px"
                      fontWeight="400"
                      lineHeight="22.4px"
                      color="#3A3A3A"
                      {...RenderContentSt[lang].FaqAns}
                    >
                      <Box>
                        <Text
                          p="0"
                          fontFamily="FedraSansStd-book"
                          className="pAlink"
                          {...RenderContentSt[lang].FaqAns}
                          dir={lang == 'ar' ? 'rtl' : 'ltr'}
                        >
                          {StructuredTextContent(child?.answer)}
                        </Text>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              ) : null}
            </Box>
            <style jsx global>
              {`
                .pAlink a {
                  color: #e86f34;
                }
              `}
            </style>
          </>
        );
      })}
    </>
  );
};

export default RenderContent;
