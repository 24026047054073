/* Built In Imports */

/* External Imports */
import { Box, Flex, SimpleGrid, useTheme } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the Artwork Text 3 Group Column component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} Artwork Text 3 Group Column component
 */
const ArtworkText3GroupColumn = ({ sectionContent }) => {
  const theme = useTheme();
  const spacing = theme.space[5];
  return (
    <Box
      fontFamily="FedraSansStd-book"
      mx="auto"
      maxW={{
        base: '280px',
        sm: '280px',
        md: '530px',
        lg: '530px',
        xl: '810px',
      }}
      mb="50px"
    >
      {/* <SimpleGrid
        columns={{ base: 1, md: 3, lg: 2, xl: 3 }}
        spacing={{ base:'30px', md:'10px', lg:'15px'}}
        mx="auto" 
        justifyContent={{ base: 'center', md:"center", xl: 'center' }}
      > */}
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          marginX={`calc(${spacing} / -2)`}
        >
        {sectionContent?.cards?.length &&
          sectionContent?.cards?.map(data => {
            return (
              // <Box
              //   display="flex"
              //   flexDirection="column"
              //   mx="auto"
              //   w={{
              //     base: '250px',
              //     sm: '250px',
              //     lg: '250px',
              //   }}
              //   position="relative"
              //   alignItems={'center'}
              //   justifyContent="center"
              //   minH="210px"
              // >
              <Flex
              flexGrow="0"
              flexDirection="column"
              flexShrink="0"
              flexBasis={`calc(33% - ${spacing})`}
              marginBottom={spacing}
              _even={{
                marginX: spacing
              }}
            >
                <Flex
                  flexDir={'column'}
                  justifyContent="space-between"
                  maxW="100%"
                  maxH="153px"
                >
                  <LazyLoadImageComponent
                    src={data?.image?.image?.url}
                    alt={data?.image?.image?.alt}
                    title={data?.image?.image?.title}
                    // height="auto"
                    maxW="210px"
                    maxH="153px"
                    // // pb="16px"
                    // // width="210px"
                    verticalAlign="middle"
                  />
                </Flex>

                <Box
                  // ml={{ sm: 'auto', lg: '0', base: '0', md: '0' }}
                  // width={{ sm: '80%', base: '80%' }}
                  display={{
                    base: 'inline-block',
                    lg: 'block',
                    md: 'block',
                  }}
                  p={0}
                  m="0"
                  textAlign={{ base: 'left', lg: 'center', md: 'center' }}
                  // pl={{ base: '24px', lg: '0', md: '0' }}
                  fontSize={{base:'14px', md:'14px', lg:'16px'}}
                  fontFamily="FedraSansStd-book"
                >
                  <StructuredTextParser
                    str={render(data?.description)}
                    textAlign="center"
                    fontSize={{base:'14px', md:'14px', lg:'16px'}}
                  />
                </Box>
                </Flex>
            );
          })}
        </Flex>
      {/* </SimpleGrid> */}
    </Box>
  );
};

export default ArtworkText3GroupColumn;
