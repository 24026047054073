/* Built In Imports */
/* External Imports */
import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box,
  chakra
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import TopKolam from '@components/Headings/TopKolam';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the Faq component.
 * @param {Array} faqSection - Main FAQ data.
 * @param {Array} region - region.
 * @param {Array} lang - language.
 * @returns {ReactElement} Faq component.
 */
export default function Faq({ pageConfig, faqSection, region, lang }) {
  const pName = pageConfig?.pageName
  const renderFaqSection = section => {

    // const faqs = Object.entries(section);
    let count = 1;
    return section.map((faqObj, i) => {
      // console.log('section in faq is', faqObj)

      const getChildren = (faqItems) => {
        const children = faqItems?.map((faq, j) => {
          let c = ++count;
          return (
            <AccordionItem
              key={j}
              borderBottomWidth="1px"
              borderBottomColor="gray.400"
              borderTop="none"
              lineHeight="1.61"
            >
              <AccordionButton
                pt="0.8rem"
                pb="0.8rem"
                fontFamily="FedraSansStd-medium"
                fontSize="1.1rem"
                borderTop="none"
                _hover={{ background: 'none' }}
                _focus={{ shadow: 'transparent' }}
              >
                <chakra.div flex="1" textAlign="left" key={c}>
                  {faq.question}
                </chakra.div>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel
                pb={2}
                fontSize="1.1rem"
                fontFamily="FedraSansStd-book"
                lineHeight="1.61"
              >
                <Box
                  display="block"
                  width="100%"
                  lineHeight="1.61"
                  fontSize={{ lg: '18px', sm: '16px' }}
                  color="#28231e"
                >
                  <Box className="event-text">
                    <StructuredTextParser
                      str={render(faq.answer)}
                      region={region}
                      lang={lang}
                    />
                  </Box>
                </Box>
              </AccordionPanel>
              <style global jsx>
                {`
                  .faq a {
                    color: #cf4520;
                  }
                  .faq span.setting:after {
                    font-family: fontawesome;
                    font-size: 1.6rem;
                    content: '\f013';
                    color: #fff;
                    background: rgba(31, 41, 55, var(--tw-bg-opacity));
                    border-radius: 18px;
                    padding: 7px 8px 6px;
                    --tw-bg-opacity: 1;
                    vertical-align: middle;
                  }
                  .faq ul {
                    padding-left: 1.5em;
                  }
                  .faq p {
                    padding-bottom: 20px;
                  }
                `}
              </style>
            </AccordionItem>
          );
        });
        ++count;
        return children;
      };

      return (pName === "hytt" ? i > 1 : i > 0) && (
        <Accordion
          key={i}
          allowMultiple
          m={{ base: '0 auto' }}
          mx={pName === "hytt" && { base: "20px", sm: "20px", md: "" }}
          width={pName === "hytt" ? "" : { base: "100%" }}
        >
          <AccordionItem borderBottom="none">
            <AccordionButton
              bgColor={pName == "hytt" ? "#243E4E" : "#000054"}
              borderRadius="3px"
              mb="2px"
              fontFamily="FedraSansStd-medium"
              fontSize={pName == "hytt" ? { base: "16px", sm: "16px", md: "20px" } : "20px"}
              borderColor="#ffffff"
              padding="10px 13px 10px 13px"
              color="white"
              _hover={pName == "hytt" ? { backgroundColor: "#243E4E", color: '#ffffff' } : { backgroundColor: "#000054", color: '#ffffff' }}
              // _hover={{ bgColor: '#000054', color: '#ffffff' }}
              _focus={{ shadow: 'transparent' }}
              minHeight="55px"
              lineHeight="1.5"
            >
              <chakra.div flex="1" textAlign="left">
                {faqObj.title}
              </chakra.div>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={1}>{getChildren(faqObj.faqs)}</AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    });
  };

  return (
    <Box
      fontFamily="FedraSansStd-book"
      fontSize="1.1rem"
      lineHeight="1.61"
      width="100%"
      m="0 auto 3rem auto"
      id={faqSection?.sectionId}
      position={pName === "hytt" && "relative"}
    >
      <Box textAlign="center" mb="1.5rem">
        <SectionTitle
          lineHeight={pName === "hytt" && "37.8px"}
          fontSize={pName === "hytt" && "30px"} color={pName == "hytt" && "black"} titleObj={faqSection?.sectionContent[0]} />
      </Box>
      {
        pName === "hytt" ? <>
          <Box width={"100%"} position={"absolute"} top={{ base: "90px", sm: "90px", md: "50px" }} mx={"auto"} >
            <TopKolam
              marginBottom="40px"
              sectionContent={faqSection?.sectionContent[1]}
            />
          </Box>
          <Box marginTop={"80px"}>
            {renderFaqSection(faqSection.sectionContent)}
          </Box>
        </> : <>
          {renderFaqSection(faqSection.sectionContent)}</>
      }


    </Box >
  );
}
