/* Built In Imports */

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Services */

/**
 * Renders the Social Media Icons Latest component
 *
 * @param {object} section - Data for section
 * @returns {ReactElement} Social Media Icons Latest  component
 */

const SocialMediaIconsLatest = ({ section }) => {
  return (
    <>
      <SectionTitle titleObj={section.sectionContent?.[0]} />

      <Box
        display="flex"
        justifyContent="center"
        gridGap={{ base: '5px', md: '50px' }}
        my={10}
      >
        {section?.sectionContent
          .map((item, index) => {
            return (
              <Link href={item?.linkUrl} key={index} target="_blank">
                <LazyLoadImageComponent
                  src={item?.image?.url}
                  alt={item?.image?.alt}
                  title={item?.image?.alt}
                  width="64"
                  height="64"
                />
              </Link>
            );
          })
          .splice(1, 5)}
      </Box>
      {section.sectionContent[1]?.cards?.length > 0 &&
        section?.sectionContent[0] && (
          <Box
            fontFamily="FedraSansStd-medium"
            fontSize={{ base: '18px' }}
            marginTop="45px"
            marginBottom="45px"
            as="div"
            display="block"
            className="event-text"
          >
            <Link
              fontFamily="FedraSansStd-medium"
              fontSize="18px"
              textAlign="center"
            >
              <StructuredTextParser
                textAlign={section.sectionContent[0]?.style}
                str={render(section.sectionContent[0]?.body)}
              />
            </Link>
          </Box>
        )}
    </>
  );
};

export default SocialMediaIconsLatest;
