/* Built In Imports */
import { useRouter } from 'next/router';
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import SectionUI from '@components/UI/Common/SectionUI';
import config from '@config';
import Image from 'next/image';
import BenefitsCard from './BenefitsCard';
import SimpleButton from '@components/Buttons/SimpleButton';

/* Services */

/**
 * Renders the BenefitsGroup Card component.
 *
 * @returns {ReactElement} BenefitsGroup Card component.
 */

const BenefitsGroup = ({ section, region, lang }) => {
  
  const router = useRouter();
  const others = router.asPath;
  //Check if page is PBK online
  const isPbkOnlinePage = router.asPath.includes('pancha-bhuta-kriya-online-with-sadhguru-mahashivratri')

  return (
    <Flex
      justifyContent="center"
      maxW={1330}
      mx="auto"
      w="100%"
      minH="660px"
      height="auto"
      bgSize="cover"
      alignItems="center"
      flexDir="column"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      // bgImage={`${config.staticPath}/d/46272/1689756920-benefits-bg.jpg`}
      pb={{ base: '20px', md: '0px' }}
      position="relative"
    >
      {!isPbkOnlinePage ?
        <Flex pos="absolute" top={0} zIndex={0} bottom={0} left={0} w="100%" height='100%' clipPath={{ base: "polygon(0 0, 100% 2%, 100% 100%, 0 100%)", md: 'polygon(0 0, 100% 11%, 100% 100%, 0 100%)' }}>
          <Image fill placeholder='blur' priority={false} src={`${config.staticPath}/d/46272/1689756920-benefits-bg.jpg`} blurDataURL={`${config.staticPath}/d/46272/1689756920-benefits-bg.jpg?q=50`} loading='lazy' />
        </Flex> :
        <Flex pos="absolute" top={0} zIndex={0} bottom={0} left={0} w="100%" height='100%' clipPath={{ base: "polygon(0 0, 100% 2%, 100% 100%, 0 100%)", md: 'polygon(0 0, 100% 11%, 100% 100%, 0 100%)' }} background={'linear-gradient(180deg, #131313 50%, #000000 100%)'}>
        </Flex>}

      <Box position="relative" zIndex="2">
        {section?.sectionContent && section?.sectionContent?.length
          ? section?.sectionContent?.map((secCon, i) => {
            if (secCon._modelApiKey !== 'artwork_text3_group_column' && secCon._modelApiKey !== 'simple_button') {
              return (
                <SectionUI
                  key={i}
                  sectionContent={secCon}
                  section={section}
                  region={region}
                  language={lang}
                />
              );
            }
          })
          : null}
        <Box maxW={1100} mx="auto" w="100%">
          <Flex alignItems="center" justifyContent="space-around" flexWrap="wrap">
            {section?.sectionContent[2]?.cards?.map(card => {
              return <BenefitsCard key={nanoid()} card={card} />;
            })}
          </Flex>
        </Box>
        <Box maxW={'188px'} m="0 auto">
            <SimpleButton
              style={section?.sectionContent[3]}
              bType={section?.sectionContent[3]?.sectionId}
              region={region}
              lang={lang}
            />
        </Box>
      </Box>
    </Flex>
  );
};

export default BenefitsGroup;
