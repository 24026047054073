/* Built In Imports */
import NextLink from 'next/link';
import React from 'react';

/* External Imports */
import { Box, Image, Link, Text } from '@chakra-ui/react';
import config from '@config';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

import SimpleButton from '@components/Buttons/SimpleButton';
import SectionTitle from '@components/Headings/SectionTitle';
import ContentArea from '@components/Layout/ContentArea';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import { refineUrlDomain } from '@components/Utility/utils';
import SPSectionHeader from '@landing_pages/Sadhanapada/SPSectionHeader';

/**
 * Renders SP Apply Component
 *
 * @param root0
 * @param root0.section
 * @param root0.region
 * @param root0.lang
 * @returns {ReactElement} SP Apply Component
 */
export default function SPApply({ section, region, lang }) {
  const secCon = section;
  return (
    <Box
      bgImage={{
        base: `${config.imgPath}/d/46272/1663650254-ready-apply-banner-mob-2x.jpg`,
        lg: `${config.imgPath}/d/46272/1663650335-ready-apply-banner-2x.jpg`,
      }}
      bgRepeat="no-repeat"
      bgSize="cover"
      pt="50px"
    >
      <Box textAlign="center" pos="relative" display="flex">
        {/* <Box top={{ base: '50px', lg: '80px' }}> */}
        <Box
          pos="relative"
          w={{ base: '100%', md: '63%' }}
          m="0 auto"
          p="0px 15px"
        >
          <SPSectionHeader
            HeaderText={secCon.sectionContent[0].titleText}
            color={'#ffffff'}
            img={`${config.imgPath}/d/46272/1663650401-divider5.svg`}
          />
          <Box
            color="#fff"
            fontFamily="'FedraSansStd-book', sans-serif"
            className="blockContent"
            fontSize={{ base: '18px', md: '18px' }}
          >
            <Text
              mt={{ base: 0, md: '30px' }}
              // textAlign="left"
            >
              {StructuredTextContent(secCon.sectionContent[1].body)}
            </Text>

            <Box
              display="flex"
              placeContent="center"
              justifyContent="center"
              pt={2}
              border="none"
            >
              <SimpleButton
                // padding="auto"
                style={secCon?.sectionContent[2]}
                region={region}
                lang={lang}
                width="auto"
                fontstyle={lang === 'ta' ? 'MuktaMalar' : 'FedraSansStd-medium'}
                fontbold={lang === 'ta' ? 'bold' : 'normal'}
              />
            </Box>
          </Box>
        </Box>
        {/* </Box> */}
      </Box>
      <Box
        w="100%"
        pt="80px"
        pb="20px"
        pos="relative"
        float="left"
        bgColor="#f1eee4"
        className="findoutmore"
      >
        <Box
          position="relative"
          w={{ base: '100%', lg: '88%', md: '100%' }}
          m="0 auto"
          textAlign="center"
          p={{ base: '0', md: '0px 15px' }}
        >
          <SectionTitle titleObj={secCon.sectionContent[3]} />
          <Box
            mt={{ base: '30px', lg: '40px' }}
            mb={{ base: '30px', lg: '60px' }}
            textAlign="left"
            fontSize="18px"
            lineHeight="1.61"
            className="description"
            fontFamily="'FedraSansStd-book', sans-serif"
          >
            <ContentArea
              mainContent={secCon.sectionContent[4]?.body}
              region={region}
              lang={lang}
              font={'18px'}
            />
          </Box>
          <Box marginTop="50px" m="50px auto 80px auto" textAlign="center">
            <Box
              id="outmore"
              display="flex"
              // w="full"
              flexWrap="wrap"
              gridGap="30px"
              gridRowGap="30px"
              justifyContent="center"
              m="auto"
              // placeItems={'center'}
            >
              {secCon.sectionContent[5]?.cards?.map((section, index) => {
                return (
                  <Box
                    justifyContent="center"
                    mx="auto"
                    display="inline-flex"
                    m={{ base: '20px auto', md: '10px' }}
                    background={'white !important'}
                    key={nanoid(2)}
                    maxW={{ base: '302px', sm: '330px', lg: '370px' }}
                    w={{ base: '302px', sm: '330px', lg: '370px' }}
                  >
                    <NextLink
                      href={refineUrlDomain(section?.buttonLink, region, lang)}
                      passHref
                      legacyBehavior>
                      <Link>
                        <Box mb={{ base: 4, md: 6 }}>
                          {' '}
                          <Image
                            src={section.thumbnail?.url}
                            title={section.thumbnail?.title}
                            alt={section.thumbnail?.alt}
                            loading="lazy"
                          />
                        </Box>
                        <Box
                          as="h2"
                          fontFamily="'FedraSansStd-book', sans-serif"
                          fontSize={{ base: '20px', md: '20px' }}
                          color="#28231e"
                          lineHeight={'50px'}
                          mb={4}
                        >
                          {section.title}
                        </Box>
                        <Box
                          as="p"
                          fontFamily="FedraSansStd-book"
                          fontSize="16px"
                          color="#28231e"
                        >
                          <StructuredTextParser
                            str={render(section.description)}
                            region={region}
                            lang={lang}
                            textAlign="center"
                            mt="0"
                          />
                        </Box>
                      </Link>
                    </NextLink>
                  </Box>
                );
              })}
            </Box>
          </Box>
          {secCon?.sectionContent[6] ? (
            <>
              <Box
                as="p"
                fontSize="20px"
                lineHeight="1.4"
                fontFamily="FedraSansStd-medium"
                mb="10px"
                mt="60px"
              >
                {secCon.sectionContent[6]?.text}
              </Box>
              <Box p={{ base: '0 30px' }}>
                <Link
                  href={
                    secCon.sectionContent[7]?.linkUrl
                      ? refineUrlDomain(
                          secCon.sectionContent[7]?.linkUrl,
                          region,
                          lang
                        )
                      : 'javascript:;'
                  }
                  target={'_blank'}
                  m="0 auto"
                >
                  <Image
                    loading="lazy"
                    alt={secCon?.sectionContent[7]?.image?.title}
                    title={secCon?.sectionContent[7]?.image?.title}
                    textAlign="center"
                    src={secCon?.sectionContent[7]?.image?.url}
                    w="100%"
                    maxW="720px"
                    mb="80px"
                    mt="10px"
                    boxShadow="1px 35px 23px -15px rgb(40 39 38 / 49%)"
                  />
                </Link>
                {/* <MidContainer w={'768'}> */}
                {/* <ImageBannerAd
                sectionContent={secCon?.sectionContent[7]}
                region={region}
                lang={lang}
                maxW="500px"
                width="100%"
                display="block"
                my="0"
              /> */}
                {/* </MidContainer> */}
              </Box>
            </>
          ) : (
            ''
          )}
        </Box>
      </Box>
      <>
        <style global jsx>
          {`
            .watchyoutube {
              font-family: 'FedraSansStd-medium';
              color="#28231e"
              textAlign="center"
            }
            #apply-now .findoutmore .description p {
              font-family: 'FedraSansStd-book';
            }
            #apply-now .findoutmore p {
              padding: 0;
              margin-bottom: 10px;
              font-family: 'FedraSansStd-medium';
              font-size: 20px;
            }
            #apply-now p {
              padding: 15px 0;
            }
            // .blockContent p {
            //   text-align: left;
            // }
            .blockContent h3 {
              font-family:'FedraSansStd-A-medium';
              text-align: center !important;
              font-size: 27px;
              margin-top: 25px;
              margin-bottom: 25px;
            }
            .blockContent h4 {
              text-align: center;
            }
            @media only screen and (max-width: 767px) {
              #apply-now .findoutmore p {
                font-size: 16px;
              }
              .youtube_img img {
                width: 100% !important;
              }
              .blockContent h3 {
              text-align: center !important;
              font-size: 20px;
              margin-top: 25px;
              margin-bottom: 25px;
            }
            }
            .youtube_img::before {
              content: '';
              position: absolute;
              width: 95%;
              height: 85%;
              opacity: 0.54;
              -webkit-filter: blur(10px);
              filter: blur(10px);
              background-color: #b1b1b1;
              z-index: -1;
              top: 22%;
              left: 2.5%;
            }
          `}
        </style>
      </>
    </Box>
  );
}
