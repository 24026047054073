/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders the Style Config on sectionId
 * @param {Object} StyleConfig- Data for section
 * @param {Object} children - Child Elements
 * @param {string} id - id
 * @returns {ReactElement} Designs style config for children component
 */
const StyleConfig = ({
  id,
  children,
  style,
  section,
  bottomAngled,
  background,
  backgroundRepeat,
  backgroundSize,
  ...props
}) => {
  let pt = false;
  if (
    section?.sectionContent?.length > 0 && section?.sectionContent[0]?._modelApiKey === 'topic_based_wisdom_content'
  ) {
    pt = true;
    // consoleLog('pt', pt, section?.sectionContent[0]?._modelApiKey);
  }

  return style && style?._modelApiKey && Object.keys(style)?.length > 0 ? (
    <Box
      // ? implement image_repeat_type property
      id={id}
      clipPath={
        style?.angledSection
          ? `polygon(0 2%, 100% 0, 100% 100%, 0% 100%)`
          : bottomAngled
          ? `polygon(0 0, 100% 0, 100% 100%, 0% 98%)`
          : 'unset'
      }
      // padding="60px"
      pt={{
        base:
          !pt && style?.angledSection ? '104px' : pt ? '0' : props.pT || '30px',
        md:
          !pt && style?.angledSection ? '80px' : pt ? '0' : props.pT || '30px',
      }}
      pb={pt ? '0' : props.pB || '30px'}
      mb={style?.additionalBottomPadding ? props.mB || '60px' : '0'}
      bgColor={style?.bgColour?.hex || null}
      background={background}
      bgImage={style?.bgImage?.url || style?.backgroundCss || null}
      display="flex"
      flexDirection="column"
      backgroundRepeat={backgroundRepeat}
      backgroundSize={backgroundSize}
      {...props}
    >
      {children}
    </Box>
  ) : (
    <Box id={id}>{children}</Box>
  );
};

export default StyleConfig;

// {
//     "_modelApiKey": "background_style_for_section",
//     "administrativeName": "BG Option 1 - Video Section",
//     "angledSection": false,
//     "additionalBottomPadding": false,
//     "bgColour": {
//     "hex": "#F5F5F5"
//     },
//     "bgImage": null
// }
