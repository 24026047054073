/* Built In Imports */
import React from 'react';

/* External Imports */
import { Box } from '@chakra-ui/layout';

/* Internal Imports */
/* Components */
import SPSliderCommon from '@landing_pages/Sadhanapada/Sliders/SPSliderCommon';

/* Services */
/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Render Slider Gallery Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data from api
 * @param sectionContent.isLeftMenu
 * @returns {ReactElement} - Slider Gallery Component
 */
const SPSliderGallery = ({ sectionContent, isLeftMenu }) => {
  const bgColors = {
    grey: '#e4ded4',
  };

  return (
    <Box
      bgColor={bgColors[sectionContent?.style]}
      w="100%"
      p="10px 0"
      textAlign={'center'}
      marginBottom="1px"
    >
      <Box w="100%">
        <SPSliderCommon
          sectionContent={sectionContent}
          isLeftMenu={isLeftMenu}
        />
        <style global jsx>
          {`
            #slider-common .slick-list .slick-track {
              padding-left: 12.5%;
              height: 65px;
            }
            #slider-common .slick-slide {
              min-height: 85px;
            }
            @media (max-width: 480px) {
              #slider-common .slick-list .slick-track {
                padding-left: 0;
                height: 168px;
              }
            }
          `}
        </style>
      </Box>
    </Box>
  );
};

export default SPSliderGallery;
