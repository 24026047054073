/* Built In Imports */
/* External Imports */
import { Box, Center } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SingleImage from '@components/Card/SingleImage';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import MidContainer from '@Layout/MidContainer';

/**
 * Renders the QuoteSection Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @returns {ReactElement} QuoteSection component
 */

const QuoteSection = ({ section, region, lang }) => {
  return (
    <Center mb="-5" mt="-8">
      <MidContainer w={'768'}>
        <Box p={{ base: '40px' }}>
          <StructuredTextParser
            str={
              section.sectionContent[0]?.text ||
              render(section.sectionContent[0].body)
            }
            region={region}
            lang={lang}
            textAlign="center"
            fontWeight="400"
            fontSize={{ base: '16px', md: '20px' }}
            lineHeight={{ base: '26px', md: '28px' }}
            color="#28231E"
            maxW={{ base: '250px', md: '660px' }}
            fontFamily="FedraSansStd-book-i"
          />
          <SingleImage
            w={{ base: 'unset', md: '108.78px' }}
            h={{ base: 'unset', md: '51.99px' }}
            maxWidth={{ base: '85.39px', md: '100%' }}
            sectionContent={section.sectionContent[1]}
            my="15px"
            tAlign="center"
          />
        </Box>
      </MidContainer>
    </Center>
  );
};

export default QuoteSection;
