/* Built In Imports */
// import NextLink from 'next/link';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

import SPCardInfoWithImageDetail from '@landing_pages/Sadhanapada/Card/SPCardInfoWithImageDetail';
/* Services */
/* Styles */

/**
 * Renders the Card Zigzag Card component
 * @param {Object} sectionContent - Data for section
 * @param {string} lang - Language for page
 * @param {string} region - Region for page
 * @returns {ReactElement} CardZigzagGroup Card component
 */

const SPCardZigzagGroup = ({ sectionContent, region, lang }) => {
  return (
    <Box m="1.2vw 0 0.5vw 0" w={'100%'}>
      <Box
        width={'100%'}
        // bg="#f5f5f5"
        h="100%"
        mx="auto"
        // py={{ base: '4%' }}
      >
        {/* The title and the description of the component */}
        <Box mx={{ base: '1%', md: '2%', lg: '6%' }}>
          <Box textAlign={'center'} my="20px">
            {sectionContent?.title}
            <Box>{sectionContent?.structured_text}</Box>
          </Box>

          {/* Here comes the container of the gallery  */}
          {sectionContent?.cards?.map((data, index) => {
            return (
              <SPCardInfoWithImageDetail
                data={data}
                region={region}
                lang={lang}
                index={index + 1}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default SPCardZigzagGroup;
