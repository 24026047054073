/* Built In Imports */
import React from 'react';

/* External Imports */
import { Box, Image, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports*/

/* Components */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import config from '@config';

/* Services */

/**
 * Renders the Card Info Image Detail
 * @param {Object} data - data for section
 * @param {string} region - Region for section
 * @param {string} lang - Language for section
 * @param {Object} index - index for cards
 * @returns {ReactElement} - CardInfoWithImageDetail card component
 */
const SPCardInfoWithImageDetail = ({ data, region, lang, index }) => {
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,

    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="12px"
          h="12px"
          bgColor="#fff"
          borderRadius="50%"
        ></Text>
      );
    },
  };

  const flexDirection = index % 2 == 0 ? 'row-reverse' : 'row';
  const textAlign = index % 2 == 0 ? 'left' : 'right';

  return (
    <Box
      display="flex"
      justifyContent="center"
      key={nanoid()}
      mb={{ base: '1.5em', md: '4em' }}
      flexDir={{
        sm: 'column',
        md: flexDirection,
        base: 'column',
      }}
      alignItems="center"
    >
      <Box
        className="slider description"
        w={{ base: '100%', md: '50%' }}
        color={'black'}
        textAlign={{ base: 'left', md: 'right' }}
        px={{ base: '8px', sm: '4px' }}
        // pt={{ base: '0', md: '6%' }}
      >
        <Box
          as="h3"
          fontFamily={lang === 'ta' ? 'MuktaMalar' : 'FedraSansStd-A-medium'}
          fontWeight={lang === 'ta' ? 'bold' : 'normal'}
          fontSize={{ base: '18px', md: '24px' }}
          textAlign={textAlign}
          color="#28231e"
          lineHeight={'1.61'}
          mb={{ base: '0', md: '5%' }}
          position="relative"
        >
          {data.title}
        </Box>
        <Box
          as="h4"
          fontSize={{ base: '14px', lg: '18px' }}
          pb={2}
          fontFamily={'FedraSansStd-book'}
          textAlign={{ base: 'left', lg: 'right' }}
          className="structuredText"
          color="#464646"
        >
          <StructuredTextParser
            str={render(data.description)}
            region={region}
            lang={lang}
            textAlign={textAlign}
          />
        </Box>
        <style jsx global>
          {`
            .structuredText a {
              color: #cf4824;
            }
            .structuredText a:hover {
              border-bottom: 1px solid #cf4824;
            }
          `}
        </style>
      </Box>

      <Box
        w={{ base: '100%', md: '50%' }}
        px={{ base: '8px', sm: '16px' }}
        display={{ lg: 'block', md: 'block', base: 'none' }}
        flexShrink={{ lg: 2, md: 1 }}
        float="left"
        position="relative"
        _after={{
          content: `""`,
          pos: 'absolute',
          w: '75%',
          bgColor: 'rgba(120, 154, 181, 0.5)',
          h: '276px',
          top: '-15px',
          transform: 'rotate(2deg)',
          left: '13%',
        }}
      >
        {data.thumbnails.map((img, i) => {
          return (
            <Box
              key={nanoid()}
              float="left"
              w="100%"
              position="relative"
              zIndex="5"
              overflow="hidden"
            >
              <Image
                loading="lazy"
                alt={img?.alt}
                title={img?.title}
                width={{ base: '100%', md: '370px', lg: '370px' }}
                height={{ base: 'auto', md: '246px', lg: '246px' }}
                src={img?.url}
              />
            </Box>
          );
        })}
      </Box>
      <>
        <style global jsx>
          {`
          h4 .structuredText p {
            font-size: 18px
          }
            @media only screen and (max-width: 767px) {
              h3 {
                text-align: left !important;
              }
              h3:after {
                content: '';
                position: absolute;
                width: 23px;
                height: 25px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url(${config.imgPath}/d/46272/1663675818-triflow.svg);
                left: -32px;
                top: 0px
              }
              h4 * {
                text-align: left;
                font-size: 14px !important;
              }
          `}
        </style>
      </>
    </Box>
  );
};

export default SPCardInfoWithImageDetail;
