/* Built In Imports */
/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * Renders the Left Zigzag Section component
 *
 * @param section.section
 * @param {object} section - Data for section
 * @param {string} region - Region for the section
 * @param {string} lang - Language for the section
 * @param section.region
 * @param section.lang
 * @returns {ReactElement}  Left Zigzag Section component
 */
const LeftZigzag = ({ section, region, lang }) => {
  return (
    <MidContainer>
      <Box
        display="flex"
        gridGap="30px"
        my={{ base: '30px', lg: '100px' }}
        mx="15px"
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="center"
        alignItems={{ base: 'center', md: 'flex-start' }}
      >
        <Box maxW={{ base: '302px', md: '330px' }}>
          <Box
            _after={{
              content: '" "',
              display: 'block',
              height: '25px',
              backgroundColor: '#7C0514',
              position: 'relative',
              width: '80%',
              margin: '0 auto',
            }}
          >
            <LazyLoadImageComponent src={section.sectionContent[0]?.image?.url} alt="file" />
          </Box>
        </Box>
        <Box display="flex" flexDir="column" w="100%">
          <Box fontSize="22px" fontWeight="bold" mb="10px">
            {section.sectionContent.map(sectionContent => {
              return (
                <Box key={nanoid()}>
                  {sectionContent._modelApiKey === 'section_title' &&
                    sectionContent?.titleText}
                </Box>
              );
            })}
          </Box>
          <Box display="flex">
            {section.sectionContent.map(sectionContent => {
              return (
                <Box key={nanoid()}>
                  {sectionContent._modelApiKey === 'social_media_icon' && (
                    <Link href={sectionContent?.linkUrl} target="_blank">
                      <LazyLoadImageComponent
                        src={sectionContent?.image?.url}
                        alt={sectionContent?.image?.alt}
                        title={sectionContent?.image?.title}
                        width="25"
                      />
                    </Link>
                  )}
                </Box>
              );
            })}
          </Box>
          <Box display="flex">
            {section.sectionContent.map(sectionContent => {
              return (
                <Box key={nanoid()} maxW="450px">
                  {sectionContent._modelApiKey === 'structured_text' && (
                    <StructuredTextParser
                      str={render(sectionContent?.body)}
                      region={region}
                      lang={lang}
                      className="event-text"
                      style={{
                        fontFamily: 'FedraSansStd-book',
                        color: '#8487b3',
                        fontSize: '16px',
                      }}
                      // fontSize={{ lg: '22px', md: '16px' }} // let it be commented because this spoils the alignment of the collapsed text
                      textAlign="left"
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </MidContainer>
  );
};

export default LeftZigzag;
