/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';
// import MidContainer from '@Layout/MidContainer';

/**
 * Renders Exploring Spiritual American Poem Component
 *
 * @param section.section
 * @param {object} section - Data for the section
 * @param {string} region - Region for the Section 
 * @param {string} lang - Language for the Section
 * @param section.region
 * @param section.lang
 * @returns {ReactElement} - Exploring Spiritual American Poem Component
 */
const ExploringSpiritualAmericaPoem = ({ section, region, lang }) => {
  return (
    <>
      <Flex p={{ base: 0, md: 4 }} fontFamily="FedraSansStd-book">
        <Box
          width="100%"
          bgImage={{
            base: '',
            md: section?.sectionContent[1]?.image?.url,
          }}
          backgroundColor="#dcd4a9"
          bgPosition={{ base: 'bottom', md: 'center' }}
          bgRepeat="no-repeat"
          bgSize={{ base: 'contain', md: 'cover' }}
        >
          <Box
            as="p"
            lineHeight="1.6"
            mb={10}
            display={{ base: '', md: 'flex' }}
            p={{ base: '0', md: '70px' }}
            fontSize={{ base: '16px', md: '18px' }}
            className="america-poem"
          >
            <StructuredTextParser
              str={render(section?.sectionContent[0]?.body)}
              region={region}
              lang={lang}
              textAlign="center"
            />
            <Box display={{ base: 'block', md: 'none' }}>
              <LazyLoadImageComponent
                src={section?.sectionContent[1]?.mobileImage?.url}
                alt={section?.sectionContent[1]?.mobileImage?.alt}
                title={section?.sectionContent[1]?.mobileImage?.title}
              />
            </Box>
          </Box>
        </Box>
      </Flex>
      <style jsx global>
        {`
          .america-poem h2 mark,
          .america-poem p mark {
            background-color: transparent;
            font-family: 'FedraSansStd-A-medium';
            font-size: 30px;
            color: #701b08;
          }
          .america-poem p {
            padding-bottom: 25px;
          }
          @media (max-width: 767px) {
            .america-poem p {
              padding: 0 10px 20px 10px;
            }
            .america-poem p mark em {
              font-size: 16px;
            }
          }
        `}
      </style>
    </>
  );
};

export default ExploringSpiritualAmericaPoem;
