/* Built In Imports */
import NextLink from 'next/link';
/* External Imports */
import { Box, Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SectionTitle from '@components/Headings/SectionTitle';
import MidContainer from '@Layout/MidContainer';
import { refineUrlDomain } from '@components/Utility/utils';

/**
 *
 * @param section
 * @param region
 * @param lang
 */

const SupportEmail = ({
  section,
  region,
  lang,
  csrData
}) => {
let supportEmail = '';
if (csrData) {
    supportEmail = csrData?.result[0]?.regional_support_email;
}
  return (
    <Box w="100%">
      <SectionTitle titleObj={section.sectionContent[0]} />
      <MidContainer w="568px"
        pt={{ lg: '10px', base: '15px' }}
        pl={{ base: '0px' }}
        pr={{ base: '15px' }}
        fontFamily="FedraSansStd-book"
        fontSize={{base:'16px', md:'16px'}}
        mb="30px">
        <Box textAlign={'center'}>
          <NextLink  href={`mailto:${supportEmail}`} passHref legacyBehavior>
          <Link cursor='pointer'
                textDecor="none"
                _focus={{ outline: '0' }}
                _hover={{ textDecoration: 'none' }}
            >
            {supportEmail}
          </Link>
          </NextLink>
        </Box>
      </MidContainer>
    </Box>
  );
};

export default SupportEmail;