/* Built In Imports */

import { useState } from 'react';
/* External Imports */
import {
    Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text
} from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import parser from 'html-react-parser';
/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import SectionTitle from '@components/Headings/SectionTitle';
import ContentArea from '@components/Layout/ContentArea';
import SPCardZigzagGroup from '@landing_pages/Sadhanapada/Card/SPCardZigzagGroup';

/**
 * Renders SP Take Break Component
 * @param {string} id - id
 * @returns {ReactElement} SP Take Break Component
 */
export default function SPTakeBreak({ section, region, lang, id }) {
  const secCon = section?.sectionContent;
  const [activeTabId, setActiveTabId] = useState(0);

  const activeNav = id => {
    if (id === activeTabId) {
      return 'tab-active';
    } else {
      return '';
    }
  };
  return secCon && (
    <>
      <Box
        pt={{ base: '20px', md: '50px', lg: '50px' }}
        bgColor="transparent"
        float="left"
        position="relative"
        width="100%"
        id={id}
      >
        <Box textAlign="center" p="0px 15px">
          <SectionTitle titleObj={secCon[0]} />
          <Box
            mt={{ base: '30px', md: '60px', lg: '60px' }}
            mx="auto"
            width={{ base: '224px', md: '320px' }}
          >
            <LazyLoadImageComponent
              src={secCon[1]?.image.url}
              alt="take break"
              title={secCon[1]?.image.url}
              style={{ width: '100%' }}
            />
          </Box>
          <Tabs variant="unstyled" mt="60px">
            <TabList justifyContent="center" mb={{ base: '20px' }}>
              {secCon[2] &&
                secCon[2]?.data &&
                Object.keys(secCon[2]?.data)?.map((el, id) => {
                  return (
                    <Tab
                      position="relative"
                      mr="6px"
                      _selected={{ color: '#fff', bg: '#cf4520' }}
                      bg="#fff"
                      outline="none"
                      _focus="none"
                      lineHeight={{ base: '24px', md: '24px', lg: '24px' }}
                      fontFamily={
                        lang === 'ta' ? 'MuktaMalar' : 'FedraSansStd-book'
                      }
                      fontSize={{
                        base: '14px',
                        sm: '14px',
                        md: lang === 'ta' ? '15px' : '18px',
                      }}
                      color="#28231e"
                      w="auto"
                      onClick={() => setActiveTabId(id)}
                      className={activeNav(id)}
                      padding={{ base: '12px', md: '10px 38px' }}
                    >
                      <Box position="relative">
                        {parser(secCon[2]?.data[el])}
                      </Box>
                    </Tab>
                  );
                })}
            </TabList>
            <TabPanels
              fontFamily="'FedraSansStd-book', sans-serif"
              fontSize={{ base: '14px', lg: '18px' }}
              lineHeight="1.61"
              color="#28231e"
            >
              <TabPanel>
                <Box textAlign={'center'}>
                  <ContentArea
                    mainContent={section?.sectionContent[3]?.body}
                    region={region}
                    lang={lang}
                    tAlign={{ base: 'left', md: 'center' }}
                  />
                </Box>
                <SPCardZigzagGroup
                  sectionContent={section?.sectionContent[4]}
                  region={region}
                  lang={lang}
                />
              </TabPanel>
              <TabPanel>
                <ContentArea
                  mainContent={section?.sectionContent[5]?.body}
                  region={region}
                  lang={lang}
                  tAlign={{ base: 'left', md: 'center' }}
                />
                <SPCardZigzagGroup
                  sectionContent={section?.sectionContent[6]}
                  region={region}
                  lang={lang}
                />
              </TabPanel>
              <TabPanel>
                <ContentArea
                  mainContent={section?.sectionContent[7]?.body}
                  region={region}
                  lang={lang}
                  tAlign={{ base: 'left', md: 'center' }}
                />
                <SPCardZigzagGroup
                  sectionContent={section?.sectionContent[8]}
                  region={region}
                  lang={lang}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box mt={{ base: '0' }} textAlign="center">
          <Text
            as="div"
            w="100%"
            fontFamily={lang === 'ta' ? 'MuktaMalar' : 'FedraSansStd-book'}
            fontSize="18px"
            lineHeight="1.61"
            color="#464646"
            mt="10px"
          >
            <ContentArea
              mainContent={section?.sectionContent[9]?.body}
              region={region}
              font={{ base: '', md: '' }}
              lang={lang}
              tAlign={section?.sectionContent[9]?.style}
            />
          </Text>
          <Box as="div" display="flex" justifyContent="center">
            <SimpleButton
              style={section?.sectionContent[10]}
              region={region}
              lang={lang}
              // width="auto"
              mb="10px"
              fontstyle={lang === 'ta' ? 'MuktaMalar' : 'FedraSansStd-medium'}
              fontbold={lang === 'ta' ? 'bold' : 'normal'}
            />
          </Box>
        </Box>
      </Box>
      <style global jsx>{`
        .tab-active::after {
          content: ' ';
          width: 12px;
          height: 12px;
          position: absolute;
          background-color: #cf4520;
          left: 0;
          right: 0;
          bottom: -6px;
          margin: 0 auto;
          transform: rotate(45deg);
        }
        .structuredText p {
          font-size: 18px;
        }
      `}</style>
    </>
  );
}
