/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import SectionUI from '@components/UI/Common/SectionUI';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/**
 * Renders the Celebrate the weaves Ui component
 *
 * @param section.section
 * @param {object} section - Data for section
 * @param {string} region - Region for the section
 * @param {string} lang - language for the section
 * @param section.region
 * @param section.lang
 * @returns {ReactElement} Celebrate the weaves sectionID UI component
 */
const IshaOutreach = ({ section, region, lang }) => {
  const sectionContent = section?.sectionContent;
  return (
    <Box bg="#daded5" w="full" py="50px">
      {sectionContent?.map((item, index) => {
        return (
          <Box pos="relative" key={index}>
            {item._modelApiKey === 'single_image' && (
              <Box
                pos="absolute"
                right="80px"
                top="-415px"
                mixBlendMode="overlay"
                zIndex={2}
                display={{ base: 'none', md: 'block' }}
              >
                <LazyLoadImageComponent
                  src={item?.image?.url}
                  alt={item?.image?.alt}
                  title={item?.image?.title}
                />
              </Box>
            )}

            <Box mt="30px">
              <SectionUI
                key={nanoid()}
                sectionContent={!item.image?.url ? item : ''}
                region={region}
                language={lang}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default IshaOutreach;
