/* Built In Imports */

/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import CardCustomCardGroup from '@components/Card/CardCustomCardGroup';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/**
 * Renders the VariousVolunteerSection Card component
 *
 * @param {object} sectionContent - Data for section
 * @param sectionContent.section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} VariousVolunteerSection component
 */
const VariousVolunteerSection = ({ section, region, lang }) => {
  return (
    <Box
      w="100%"
      p={{ base: '10px 10px 5px', md: '15px 15px 30px 15px' }}
      // background={{
      //   base: 'linear-gradient(180deg, rgba(255, 239, 220, 0) 0%, #FEE0BD 56.03%)',
      //   md: 'linear-gradient(180deg, rgba(255, 239, 220, 0) 0%, #FEE0BD 92.72%)',
      // }}
    >
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        maxW="1050px"
        m={{ base: '20px auto 10px', md: '30px auto' }}
        width={{ base: '290px', sm: '330px', md: '100%' }}
        height={{ base: '100%', lg: '369px', md: '574px' }}
      >
        <Box
          d="flex"
          flexFlow="column-reverse"
          mr={{ base: '0', md: '10px' }}
          mb={{ base: '20px', md: '0' }}
          maxW={{ base: '100%', md: '300px' }}
        >
          <StructuredTextParser
            str={section.sectionContent[0].text}
            region={region}
            lang={lang}
            fontWeight="500"
            fontSize={{
              base: '22px',
              md: `${
                section?.sectionId === 'other-volunteer-section' || section?.sectionId === 'ssb-volunteer-section'
                  ? '42px'
                  : '36px'
              }`,
            }}
            lineHeight={{
              base: '28px',
              md: `${
                section?.sectionId === 'other-volunteer-section' || section?.sectionId === 'ssb-volunteer-section'
                  ? '54px'
                  : '49px'
              }`,
            }}
            color={
              section?.sectionId === 'other-volunteer-section' || section?.sectionId === 'ssb-volunteer-section'
                ? '#FFFFFF'
                : '#28231E'
            }
            fontFamily="FedraSansStd-A-medium"
          />
        </Box>
        <CardCustomCardGroup
          sectionContent={section.sectionContent[1]}
          region={region}
          lang={lang}
          section={section}
        />
      </Flex>
    </Box>
  );
};

export default VariousVolunteerSection;
