/* Built In Imports */
import { useRouter } from 'next/router';

/* External Imports */
import {
  Box,
  Flex
} from '@chakra-ui/react';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

/* Internal Imports */
/* Components */
import SingleImage from '@components/Card/SingleImage';
import config from '@config';

/* Service */

/**
 * Renders InviteFriends Component
 * @param {Object} sectionContent - data from api for the section
 * @param {string} region - Region of the Section
 * @param {string} lang - Language of the Section
 * @returns {ReactElement} - InviteFriends
 */
const InviteFriends = ({ sectionContent, region, lang }) => {
  // console.log('sss', sectionContent);
  const router = useRouter();
  const url = router.asPath;

  return (
    <Box
      pos="relative"
      w="100%"
      textAlign="center"
      py="50px"
      // bgColor="#F5F0E5"
      maxW="1330px"
      m="10px auto 0"
    >
      <Box
        p={{ base: '0 0 0 0', lg: '0 10px 0 0' }}
        pos="relative"
        left={{ base: '0' }}
        width="100%"
      >
        <Flex flexDir="column" w={{ base: '95%', md: '540px' }} pt="20px" mx="auto">

          <Box textAlign="center" mb="1.5rem">
            <Box as="h3"
              fontFamily="FedraSansStd-A-medium"
              fontSize={{ base: "24px", md: "24px" }}
              lineHeight="1.4em"
              maxW="450px"
              m="0 auto"
            >
              {sectionContent[0]?.text}
            </Box>
            <Box
              m="20px auto"
              textAlign="center"
              maxWidth="190px"
            >
              <SingleImage
                sectionContent={sectionContent[1]}
                region={region}
                lang={lang}
              />
            </Box>

            <Flex
              maxW={{ base: "auto", md: "340px" }}
              m="40px auto"
              justifyContent={"center"}
              alignItems={"center"}
              marginBottom={{ sm: "0px", base: "0px", lg: "40px" }}
              gap={{ base: "10px", md: "40px" }}
            >
              <WhatsappShareButton
                url={`${config.BASE_PATH}${url}`}
                quote={''}
                onClick={() => {
                  window.dataLayer.push({
                    event: 'IDY_Challenge_Shared',
                    IDY_Share_Platform: 'WA',
                  });
                }}
              >
                <SingleImage
                  sectionContent={{
                    image: sectionContent[2]?.cards[0].thumbnail
                  }}
                  region={region}
                  lang={lang}
                  m="0 auto"
                  w="40px"
                  marginRight={{ base: "0", lg: "22px" }}
                />
              </WhatsappShareButton>

              <TwitterShareButton
                url={`${config.BASE_PATH}${url}`}
                quote={''}
                onClick={() => {
                  window.dataLayer.push({
                    event: 'IDY_Challenge_Shared',
                    IDY_Share_Platform: 'X',
                  });
                }}
              >
                <SingleImage
                  sectionContent={{
                    image: sectionContent[2]?.cards[1].thumbnail
                  }}
                  region={region}
                  lang={lang}
                  m="0 auto"
                  w="40px"
                  marginRight={{ base: "0", lg: "22px" }}
                />

              </TwitterShareButton>
              <EmailShareButton
                url={`${config.BASE_PATH}${url}`}
                quote={''}
              >
                <SingleImage
                  sectionContent={{
                    image: sectionContent[2]?.cards[2].thumbnail
                  }}
                  region={region}
                  lang={lang}
                  m="0 auto"
                  w="40px"
                  marginRight={{ base: "0", lg: "22px" }}
                  onClick={() => {
                    window.dataLayer.push({
                      event: 'IDY_Challenge_Shared',
                      IDY_Share_Platform: 'Email',
                    });
                  }}
                />
              </EmailShareButton>
              <FacebookShareButton
                url={`${config.BASE_PATH}${url}`}
                quote={''}
                onClick={() => {
                  window.dataLayer.push({
                    event: 'IDY_Challenge_Shared',
                    IDY_Share_Platform: 'FB',
                  });
                }}
              >
                <SingleImage
                  sectionContent={{
                    image: sectionContent[2]?.cards[3].thumbnail
                  }}
                  region={region}
                  lang={lang}
                  m="0 auto"
                  w="40px"
                  marginRight={{ base: "0", lg: "22px" }}
                />

              </FacebookShareButton>

            </Flex>
          </Box>


        </Flex>
      </Box>
    </Box>
  );
};

export default InviteFriends;
