/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import SingleImage from '@components/Card/SingleImage';
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/* Service */
import { searchByFieldId } from '@components/Utility/Shared/SharedService';

/**
 * Returns the IdyInPerson.
 * @param {Object} sectionContent - Content from API
 * @param {string} language - Language of the page
 * @param {string} region - Region of the page
 * @returns {ReactElement} IdyInPerson component
 */
const IdyInPerson = ({ sectionContent, region, lang }) => {
  // console.log('IdyInPerson', sectionContent);
  return (
    // <Box display={'grid'} pos={'relative'} h={{ base: '100%', md: 'auto' }}>
    <Box
      as="div"
      fontSize="18px"
      m={{ base: '40px auto 0', md: '80px auto 0' }}
      width="100%"
      // mb={{ md: '40px', sm: '15px' }}
      bgColor="#F5F5F5"
      p={{ base: "0", md: "15px" }}
      py={{ base: "20px", md: "30px" }}
      w="100%"
    >

      <Box
        display="flex"
        // flexWrap="wrap"
        margin="0 auto"
        fontSize="18px"
        gap={{ base: "10px", md: "3%" }}
        maxW="1050px"
        flexDirection={{ base: "column", md: "row" }}
      // width={{ base: '100%', md: '737px', lg: '933px' }}
      >
        <Box
          margin={{ base: "0 auto", md: "40px auto" }}
          fontSize="18px"
          gap={{ base: "0", md: "10px" }}
          maxW="1050px"
        // width={{ base: '100%', md: '737px', lg: '933px' }}
        >
          <SingleImage
            sectionContent={sectionContent[0]}
            region={region}
            lang={lang}
          />

        </Box>
        <Box
          margin={{ base: "0 auto", md: "40px auto" }}
          fontSize="18px"
          gap={{ base: "0", md: "10px" }}
          maxW="1050px"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          p="0 15px "
        // width={{ base: '100%', md: '737px', lg: '933px' }}
        >
          {searchByFieldId(
            sectionContent,
            'join-session'
          ) && (
              <Box
                fontSize={{ base: "24px", md: "30px" }}
                color="#02026C"
                fontFamily="FedraSansStd-A-medium"
              >
                {searchByFieldId(
                  sectionContent,
                  'join-session'
                ).text}
              </Box>
            )}

          {searchByFieldId(
            sectionContent,
            'in-person-data'
          ) && (
              <StructuredTextParser
                str={render(
                  searchByFieldId(
                    sectionContent,
                    'in-person-data'
                  )?.body
                )}
                className="InP"
                fontFamily="FedraSansStd-book"
                fontSize="18px"
                color="#000000"
                region={region}
                lang={lang}
              />
            )}

        </Box>
        <style>
          {`
          .InP p {
            padding-bottom: 20px;
          }
          `}
        </style>

      </Box>

      {sectionContent[3]?._modelApiKey === 'simple_button' && (
        <Box w={'100%'} m="0 auto" display="flex" justifyContent="center">
          <SimpleButton
            style={sectionContent[3]}
            region={region}
            lang={lang}
            mb="20px"
          />
        </Box>
      )}
    </Box>
  );
};

export default IdyInPerson;
