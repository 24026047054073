/* Built In Imports */
import NextLink from 'next/link';
import React from 'react';
/* External Imports */
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import {
    Accordion, AccordionButton, AccordionItem, AccordionPanel, Box,
    Image, Link, Text
} from '@chakra-ui/react';
import SectionTitle from '@components/Headings/SectionTitle';
import StructuredTextContent from '@components/UI/StructuredTextContent';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import SPSliderGallery from '@landing_pages/Sadhanapada/Sliders/SPSliderGallery';
import SPSectionHeader from '@landing_pages/Sadhanapada/SPSectionHeader';

/**
 * Renders SP Path Component
 * @param {string} id - id
 * @returns {ReactElement} - SP Path Component
 */
export default function SPPath(section, region, lang, isLeftMenu) {
  const secCon = section?.section;
  const pathstep = sectionData => {
    return (
      <>
        <Text
          fontSize="16px"
          textAlign={{ base: 'left', md: 'center' }}
          color="#28231e"
        >
          {sectionData?.text}
        </Text>
      </>
    );
  };
  const pathstep_title = sectionData => {
    return (
      <>
        <Text
          fontSize="27px"
          fontFamily="FedraSansStd-book"
          mt="10px"
          mb="10px"
          color="#cf4520"
          textAlign={{ base: 'left', md: 'center' }}
        >
          {sectionData?.text}
        </Text>
      </>
    );
  };
  const pathstep_description = sectionData => {
    return (
      <>
        <Text
          fontSize="16px"
          textAlign={{ base: 'left', md: 'center' }}
          color="#28231e"
        >
          {sectionData?.text}
        </Text>
      </>
    );
  };
  const pathstep_content = sectionData => {
    return (
      <>
        <Box
          fontSize="0.8rem"
          fontFamily="FedraSansStd-book"
          className="sadhana-text"
        >
          <Accordion allowToggle>
            {sectionData?.faqs?.map((datachild, index) => {
              return (
                <AccordionItem border="0">
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton color="#cf4520">
                        {isExpanded ? (
                          <MinusIcon
                            fontSize="12px"
                            color="#CF4520"
                            mr="20px"
                          />
                        ) : (
                          <AddIcon fontSize="12px" color="#CF4520" mr="20px" />
                        )}
                        <Box
                          flex="1"
                          textAlign="left"
                          fontFamily='"FedraSansStd-book", sans-serif !important'
                          fontSize="16px !important"
                          color="#cf4520"
                        >
                          <Text
                            fontFamily='"FedraSansStd-book", sans-serif !important'
                            fontSize="16px !important"
                            color="#cf4520"
                            as="span"
                          >
                            {datachild?.question}
                          </Text>
                        </Box>
                      </AccordionButton>
                      <AccordionPanel
                        pb={4}
                        textAlign="left"
                        fontFamily='"FedraSansStd-book", sans-serif'
                        fontSize="14px"
                        lineHeight="1.64"
                        letterSpacing="normal"
                        marginTop="15px"
                        color="#28231e"
                      >
                        {StructuredTextContent(datachild?.answer)}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              );
            })}
          </Accordion>
        </Box>
      </>
    );
  };
  const pathstep_button = sectionData => {
    return <>
      <Box
        float="left"
        pos="relative"
        w="100%"
        bgColor="#f3f3f3"
        color="#cf4520"
        fontFamily="'FedraSansStd-A-medium', sans-serif"
        p="10px"
        fontSize="16px"
      >
        {/* {StructuredTextContent(sectionData.body)} */}
        <NextLink
          href={refineUrlDomain(sectionData?.linkUrl, region, lang)}
          passHref
          legacyBehavior>
          <Link>{sectionData?.buttonText}</Link>
        </NextLink>
      </Box>
    </>;
  };

  return (
    <Box>
      <Box
        pt={{ base: '40px', md: '80px' }}
        pb={{ base: '40px', md: '80px' }}
        bgColor="#540606"
        float="left"
        position="relative"
        width="100%"
        textAlign="center"
        mb={{ base: '40px', lg: '70px' }}
      >
        <Box float="left" w="100%" h="100%" pos="absolute" top="0">
          <Image
            float="left"
            w="100%"
            h="95%"
            alt="path"
            title="path"
            src={`${config.imgPath}/d/46272/1663655291-untitled-artwork-2-2x.png`}
          />
        </Box>
        <SPSectionHeader
          HeaderText={secCon.sectionContent[0]?.titleText}
          color={'#ffffff'}
          img={`${config.imgPath}/d/46272/1663650401-divider5.svg`}
        />
        <Box
          float="left"
          w="100%"
          pl={{
            md: '60px',
            lg: '60px',
            base: '15px',
          }}
          pr={{
            md: '60px',
            lg: '60px',
            base: '15px',
          }}
          mt={{
            base: '40px',
          }}
          pos="relative"
          zIndex="1"
        >
          <Box
            float="left"
            pos="relative"
            w={{
              md: 'calc(50% - 50px)',
              lg: 'calc(50% - 50px)',
              base: '100%',
            }}
            bgColor="rgba(255, 255, 255, 0.85)"
            boxShadow="0 0 34px 0 rgb(0 0 0 / 10%)"
          >
            <Box p="16px" fontFamily="FedraSansStd-book">
              {pathstep(secCon.sectionContent[2])}
              {pathstep_title(secCon.sectionContent[3])}
              {pathstep_description(secCon.sectionContent[4])}
              {pathstep_content(secCon.sectionContent[5])}
            </Box>
            {pathstep_button(secCon.sectionContent[6])}
          </Box>
          <Box
            mt={{
              md: '350px',
              lg: '350px',
              base: '40px',
            }}
            float="right"
            pos="relative"
            w={{
              md: 'calc(50% - 50px)',
              lg: 'calc(50% - 50px)',
              base: '100%',
            }}
            bgColor="rgba(255, 255, 255, 0.85)"
            boxShadow="0 0 34px 0 rgb(0 0 0 / 10%)"
          >
            <Box p="16px" fontFamily="FedraSansStd-book">
              {pathstep(secCon.sectionContent[7])}
              {pathstep_title(secCon.sectionContent[8])}
              {pathstep_description(secCon.sectionContent[9])}
              {pathstep_content(secCon.sectionContent[10])}
            </Box>
            {pathstep_button(secCon.sectionContent[11])}
          </Box>
          <Box
            mt={{
              md: '350px',
              lg: '350px',
              base: '40px',
            }}
            float="left"
            pos="relative"
            w={{
              md: 'calc(50% - 50px)',
              lg: 'calc(50% - 50px)',
              base: '100%',
            }}
            bgColor="rgba(255, 255, 255, 0.85)"
            boxShadow="0 0 34px 0 rgb(0 0 0 / 10%)"
            p="16px"
            fontFamily="FedraSansStd-book"
          >
            {pathstep(secCon.sectionContent[12])}
            {pathstep_title(secCon.sectionContent[13])}
            {pathstep_description(secCon.sectionContent[14])}
            {pathstep_content(secCon.sectionContent[15])}
          </Box>
          <Box
            mt={{
              md: '350px',
              lg: '350px',
              base: '40px',
            }}
            float="right"
            pos="relative"
            w={{
              md: 'calc(50% - 50px)',
              lg: 'calc(50% - 50px)',
              base: '100%',
            }}
            bgColor="rgba(255, 255, 255, 0.85)"
            boxShadow="0 0 34px 0 rgb(0 0 0 / 10%)"
            p="16px"
            fontFamily="FedraSansStd-book"
          >
            {pathstep(secCon.sectionContent[16])}
            {pathstep_title(secCon.sectionContent[17])}
            {pathstep_description(secCon.sectionContent[18])}
            {pathstep_content(secCon.sectionContent[19])}
          </Box>
        </Box>
      </Box>

      <Box id="program-photos" my="100px">
        <Box textAlign="center" mt="80px">
          <SectionTitle titleObj={secCon.sectionContent[20]} />
        </Box>
        <Box width="100%" m="0 auto">
          <SPSliderGallery
            sectionContent={secCon.sectionContent[21]}
            isLeftMenu={1}
          />
        </Box>
      </Box>
      <style global jsx>{`
        .sadhana-text ul {
          padding-left: 20px;
        }
      `}</style>
    </Box>
  );
}
