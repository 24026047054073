/* Built In Imports */

/* External Imports */
import { Box, Flex, Image, SimpleGrid } from '@chakra-ui/react';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */
// import { consoleLog } from '@components/Utility/Shared/SharedService';

/**
 * Renders the Three Column Group component
 *
 * @param {object} section - Data for section
 * @returns {ReactElement} Three Column Group component
 */

const ThreeColumnGroup = ({ section }) => {
  const sectionContent = section.sectionContent[0];
  return (
    <Box
      fontFamily="FedraSansStd-book"
      mx="auto"
      maxW={{
        base: '280px',
        sm: '280px',
        md: '530px',
        lg: '530px',
        xl: '810px',
      }}
    >
      <Box
        maxW="770px"
        mx="auto"
        fontFamily="FedraSansStd-A-medium"
        fontSize={{
          base: '24px',
          md: '30px',
        }}
        lineHeight="1.1"
        color="#02026c"
        textAlign="center"
        my={{
          base: '30px',
          md: '50px',
        }}
      >
        <Box as="h2" mb={1}>
          {sectionContent?.title[0]?.titleText}
        </Box>
        <Box textAlign="center" mb="1.5rem">
          <LazyLoadImageComponent
            alt=""
            title=""
            src={`${config.imgPath}/d/46272/1654103596-divider-yoga.svg`}
          />
        </Box>
      </Box>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, xl: 3 }}
        spacing="30px"
        mx="auto"
      >
        {section.sectionContent[0]?.benefits?.length &&
          section.sectionContent[0]?.benefits?.map((data, index) => {
            return (
              <Box position="relative" key={index}>
                <Flex justifyContent="center">
                  <Image
                    src={data?.image?.url}
                    alt={data?.image?.alt}
                    title={data?.image?.title}
                    objectFit="scale-down"
                    h="150px"
                  />
                </Flex>

                <Box
                  // ml={{ sm: 'auto', lg: '0', base: '0', md: '0' }}
                  // width={{ sm: '80%', base: '80%' }}
                  display={{
                    base: 'inline-block',
                    lg: 'block',
                    md: 'block',
                  }}
                  mt={4}
                  textAlign="center"
                  // pl={{ base: '24px', lg: '0', md: '0' }}
                  fontSize="16px"
                  fontFamily="FedraSansStd-book"
                >
                  {data?.text}
                </Box>
              </Box>
            );
          })}
      </SimpleGrid>
    </Box>
  );
};

export default ThreeColumnGroup;
