/* Built In Imports */
/* External Imports */
import { Box, Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/**
 * Renders the AppButton Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @returns {ReactElement} AppButton component
 */

const AppButton = ({ section, region, lang }) => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        gridGap={{ base: '5px', md: '50px' }}
        my={10}
        >
        {section?.sectionContent
                .map((item, index) => {
                    return (
                      <Link href={item?.linkUrl} key={index} target="_blank">
                        <LazyLoadImageComponent
                          src={item?.image?.url}
                          alt={item?.image?.alt}
                          height="auto"
                          width="auto"
                        />
                      </Link>
                    );
                })
            }
      </Box>
    )
};

export default AppButton;