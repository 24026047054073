/* Built In Imports */
/* External Imports */
import axios from 'axios';

/* Internal Imports */
/* Components */
import config from '@config';
import { tryGetPreviewData } from 'utils';

/* Services */
import { getLocale } from '@components/Utility/Shared/SharedService';
import { filterBuildUrls } from 'services/commonService';
import { IsoBackendHttpService } from 'utils/IsoBackendHttpService';
import { getLatestBuild } from './commonService';

let isCache = false;
const API_PREFIX = '/content/fetchiso';

export const getLandingPaths = async (level, url, notMatchText, section) => {
  const data = config.BUILD_URLS.YM?.enabled
    ? config.BUILD_URLS.YM
    : await getLatestBuild(
        process.env.NEXT_PUBLIC_BUILD_ID ||
          process.env.env_var_yoga_build_id ||
          process.env.env_var_build_id
      );
  let urlArr = filterBuildUrls(
    section || 'yoga&meditation',
    level,
    url,
    notMatchText,
    data
  );
  // consoleLog('mediation', urlArr);
  return urlArr ? urlArr : [];
};

export const fetchDetails = async (region, lang, slug, previewData) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'iso'));
  }
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        sitesection: 'others',
        slug: slug ? `yoga-meditation/${slug}` : `yoga-meditation`,
        region,
        lang,
      },
    }
  );
  return pageData;
};
// const results = [
//   {
//     id: 1166,
//     name: 'Margazhi Sadhana, 5 Day (27 - 31 Dec, 2024)',
//     program_type: 'Margazhi Sadhana',
//     url_registration:
//       'https://preprod-online.sadhguru.org/event-register?event=margazhi-sadhana-5-day-27-31-dec-2024-1166',
//     url_enquiry: null,
//     start_date: '2024-12-29 06:30:00',
//     end_date: '2024-12-31 07:15:00',
//     seats_available: 999999,
//     registration_enabled: 1,
//     max_per_booking: 1,
//     tickets: [
//       {
//         id: 4117,
//         name: 'Bhoomi 5Day',
//         seats_available: 99999,
//         start_date: false,
//         end_date: false,
//         price: 3750.0,
//         currency: 'INR',
//         person_count: 1,
//         is_expired: false,
//         description:
//           '<ul><li>Non-AC Twin sharing accommodation</li><li>Bhasktrika Kriya</li><li>15-Min Walk from Program Area</li></ul>',
//       },
//       {
//         id: 4118,
//         name: 'Agni 5Day',
//         seats_available: 99999,
//         start_date: false,
//         end_date: false,
//         price: 11000.0,
//         currency: 'INR',
//         person_count: 1,
//         is_expired: false,
//         description:
//           '<ul><li>Non-AC Twin sharing accommodation</li><li>Bhasktrika Kriya</li><li>15-Min Walk from Program Area</li></ul>',
//       },
//       {
//         id: 4119,
//         name: 'Jala 5Day',
//         seats_available: 99999,
//         start_date: false,
//         end_date: false,
//         price: 15000.0,
//         currency: 'INR',
//         person_count: 1,
//         is_expired: false,
//         description:
//           '<ul><li>Non-AC Twin sharing accommodation</li><li>Bhasktrika Kriya</li><li>15-Min Walk from Program Area</li></ul>',
//       },
//     ],
//     batches: [],
//     center: 'Isha Yoga Center - Coimbatore',
//     venue: {
//       name: 'IYC',
//       street: 'Velliangiri Foothills',
//       street_2: 'Ishana Vihar Post',
//       city: 'Coimbatore',
//       state: 'Tamil Nadu',
//       country: 'India',
//       country_code: 'IN',
//       zip: '641114',
//     },
//     is_free: 0,
//   },
//   {
//     id: 1169,
//     name: 'Margazhi Sadhana, 5 Day (04 - 08 Jan, 2025)',
//     program_type: 'Margazhi Sadhana',
//     url_registration:
//       'https://preprod-online.sadhguru.org/event-register?event=margazhi-sadhana-5-day-04-08-jan-2025-1169',
//     url_enquiry: null,
//     start_date: '2024-11-29 06:30:00',
//     end_date: '2024-12-1 07:15:00',
//     seats_available: 999999,
//     registration_enabled: 1,
//     max_per_booking: 1,
//     tickets: [
//       {
//         id: 4120,
//         name: 'Bhoomi 5Day',
//         seats_available: 99999,
//         start_date: false,
//         end_date: false,
//         price: 3750.0,
//         currency: 'INR',
//         person_count: 1,
//         is_expired: false,
//         description:
//           '<ul><li>Non-AC Twin sharing accommodation</li><li>Bhasktrika Kriya</li><li>15-Min Walk from Program Area</li></ul>',
//       },
//       {
//         id: 4121,
//         name: 'Jala 5Day',
//         seats_available: 99999,
//         start_date: false,
//         end_date: false,
//         price: 15000.0,
//         currency: 'INR',
//         person_count: 1,
//         is_expired: false,
//         description:
//           '<ul><li>Non-AC Twin sharing accommodation</li><li>Bhasktrika Kriya</li><li>15-Min Walk from Program Area</li></ul>',
//       },
//       {
//         id: 4122,
//         name: 'Agni 5Day',
//         seats_available: 99999,
//         start_date: false,
//         end_date: false,
//         price: 11000.0,
//         currency: 'INR',
//         person_count: 1,
//         is_expired: false,
//         description:
//           '<ul><li>Non-AC Twin sharing accommodation</li><li>Bhasktrika Kriya</li><li>15-Min Walk from Program Area</li></ul>',
//       },
//     ],
//     batches: [],
//     center: 'Isha Yoga Center - Coimbatore',
//     venue: {
//       name: 'IYC',
//       street: 'Velliangiri Foothills',
//       street_2: 'Ishana Vihar Post',
//       city: 'Coimbatore',
//       state: 'Tamil Nadu',
//       country: 'India',
//       country_code: 'IN',
//       zip: '641114',
//     },
//     is_free: 0,
//   },
// ];
// export const fetchCategoryCards = async ids => {
//   let categoryCardsResponse = [];
//   try {
//     categoryCardsResponse = await axios.post(
//       `${config.YOGA_MEDITATION_SAD_CAT}`,
//       {
//         method: 'POST',
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//           // Authorization: process.env.YOGA_MEDITATION_TOKEN,
//         },
//         body: JSON.stringify({
//           event_ids: [1166, 1169],
//         }),
//       }
//     );
//     console.log(categoryCardsResponse);
//   } catch (err) {
//     console.log(err);
//   }
//   return categoryCardsResponse?.results;
// };
export const fetchCategoryCards = async ids => {
  // let url = `${config.FACADE_BASE_PATH}/content/subscribe/add`;
  let data = await fetch(`${config.YOGA_MEDITATION_SAD_CAT}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      event_ids: ids,
    }),
  });
  data = await data.json();
  return data;
};
export const hathaYogaRegistration = async ({
  firstName,
  lastName,
  phoneNo,
  email,
  country,
  zone,
  password,
  confirmPassword,
}) => {
  // let url = `${config.FACADE_BASE_PATH}/content/subscribe/add`;
  let data = await fetch(
    `https://hys.isha.in/hys_portal/application/rest_api/user_register.php`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName,
        lastName,
        phoneNo,
        email,
        country,
        zone,
        password,
        confirmPassword,
      }),
    }
  );
  data = await data.json();
  return data;
};

export const findATeacherAPi = async () => {
  const request = await axios({
    method: 'GET',
    url: config.FIND_A_TEACHER_API_PATH,
  });
  return request;
};
