/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';

/* Internal Imports */
// Component
import ImageBannerAd from '@components/Card/ImageBannerAd';
import SectionTitle from '@components/Headings/SectionTitle';

/* Services */
/* Styles */

/**
 * Renders Expression Component
 * @param {Object} sectionContent - data for the section from api
 * @param {string} region - Region of the section
 * @param {string} lang - Language of the section
 */
const SocialSection = ({ section, lang, region }) => {
  return (
    <Box
      width={'100%'}
      height="auto"
      p="50px 0"
      background="linear-gradient(180deg, rgb(245 227 181 / 0%) 0%, rgb(245 227 181) 100%)"
      px={{
        xl: 'calc((100% - 1112px) / 2)',
        base: '18px'
      }}
    >
      <SectionTitle titleObj={section.sectionContent[0]} />
      <Flex
        justifyContent={'center'}
        alignItems="center"
        width={'180px'}
        m="auto"
      >
        {section?.sectionContent[1] && (
          <ImageBannerAd
            width="40px"
            height="40px"
            sectionContent={section?.sectionContent[1]}
            region={region}
            lang={lang}
            my="2"
            display={{ base: 'block' }}
          />
        )}
        {section?.sectionContent[2] && (
          <ImageBannerAd
            width="40px"
            height="40px"
            sectionContent={section?.sectionContent[2]}
            region={region}
            lang={lang}
            display={{ base: 'block' }}
            my="2"
          />
        )}
        {section?.sectionContent[3] && (
          <ImageBannerAd
            width="40px"
            height="40px"
            my="2"
            sectionContent={section?.sectionContent[3]}
            region={region}
            lang={lang}
            display={{ base: 'block' }}
          />
        )}
        {section?.sectionContent[4] && (
          <ImageBannerAd
            width="40px"
            height="40px"
            my="2"
            sectionContent={section?.sectionContent[4]}
            region={region}
            lang={lang}
            display={{ base: 'block' }}
          />
        )}
      </Flex>
    </Box>
  );
};

export default SocialSection;
