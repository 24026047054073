/* Built In Imports */
import { Box } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

/* External Imports */
import forEach from 'lodash/forEach';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import TeacherCard from '@components/Card/TeacherCard';
import SectionUI from '@components/UI/Common/SectionUI';
import { consoleLog } from '@components/Utility/Shared/SharedService';

/* Services */
import { findATeacherAPi } from 'services/yogaService';

/**
 * Renders the Find A Teacher Section component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for the section
 * @param {string} lang - Language for the section
 * @param {boolean} isLeftMenu - Responsive Navbar for the section 
 * @returns {ReactElement} Find A teacher component
 */

const FindTeacher = ({ sectionContent, region, lang, isLeftMenu }) => {
  const [data, setData] = useState();

  const fetchTeachers = useCallback(async () => {
    let response;
    try {
      response = await findATeacherAPi();
    } catch (e) {
      consoleLog(e);
    }
    return response;
  }, []);

  useEffect(() => {
    fetchTeachers()
      .then(res => {
        // setLoading(false);
        // setError();
        
        let teachers = [];
        
        forEach(res.data, function (teacher) {
          // consoleLog('teacher', teacher);
          forEach(teacher?.business_address, function (address) {            
            // if (teacher.teacher_name === 'Aadi Aryan') {
            const newTeacher = JSON.parse(JSON.stringify(teacher));
            // consoleLog('pushing address ', address, newTeacher.business_address);
            newTeacher.business_address = [address];
            // consoleLog('pushing teacher', teachers.length, newTeacher, address);
            teachers = teachers.concat([newTeacher]);
            // }
          });
        });
        setData(teachers);
        // consoleLog('teachers', teachers, data);
      })
      .catch(err => {
        // setLoading(false);
        // consoleLog('teachers card retrieved error ===>>>>> ', err.response);
        // setError('Error while retrieving data');
        setData();
      });
    return () => {
      // setLoading(false);
    };
  }, [fetchTeachers]);

  return (
    <Box p="30px 0 100px 0">
      <Box as="div" display="block" placeContent="center">
        {sectionContent?.length
          ? sectionContent?.map((secCon, i) => {
            return (
              <SectionUI
                key={nanoid()}
                sectionContent={secCon}
                region={region}
                language={lang}
                isLeftMenu={isLeftMenu}
              />
            );
          })
          : null}
      </Box>
      <Box>
        <TeacherCard
          cards={data}
          region={region}
          lang={lang}
          isLeftMenu={isLeftMenu}
        />
      </Box>
    </Box>
  );
};

export default FindTeacher;
