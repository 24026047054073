/* Built In Imports */
import dynamic from 'next/dynamic';

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';
import AccordionFaq from '@components/Accordions/AccordionFaq';
import Faq from '@components/Accordions/Faq';
import FaqLinkedContent from '@components/Accordions/FaqLinkedContent';
import SimpleButton from '@components/Buttons/SimpleButton';
import BenefitsGroup from '@components/Card/BenefitsGroup';
import TwoColumn from '@components/Card/TwoColumn';
import TopBannerYs from '@components/HeaderBanner/TopBannerYs';
import ContentArea from '@components/Layout/ContentArea';
import Testimonials3Card from '@components/Sliders/Testimonials3Card';
import CommunityDeclaration from '@components/UI/Center/CommunityDeclaration';
import GlobalCenter from '@components/UI/Center/GlobalCenter';
import SectionUI from '@components/UI/Common/SectionUI';
import StyleConfig from '@components/UI/Common/StyleConfig';
import LargeBrownBullets from '@components/UI/Health/LargeBrownBullets';
import SPAlumni from '@landing_pages/Sadhanapada/SPAlumni';
import SPApply from '@landing_pages/Sadhanapada/SPApply';
import SPFaq from '@landing_pages/Sadhanapada/SPFaq';
import SPPath from '@landing_pages/Sadhanapada/SPPath';
import SPProgramDetails from '@landing_pages/Sadhanapada/SPProgramDetails';
import SPTakeBreak from '@landing_pages/Sadhanapada/SPTakeBreak';
import SPWebinar from '@landing_pages/Sadhanapada/SPWebinar';
import WhySP from '@landing_pages/Sadhanapada/WhySP';
import ChallengeForm from '@landing_pages/idy/ChallengeForm';
import FaqContents from '@landing_pages/idy/FaqContents';
import IdyBenefits from '@landing_pages/idy/IdyBenefits';
import IdyImportantMessage from '@landing_pages/idy/IdyImportantMessage';
import IdyInPerson from '@landing_pages/idy/IdyInPerson';
import IdyRegisterStickyButton from '@landing_pages/idy/IdyRegisterStickyButton';
import IdyTestimonial from '@landing_pages/idy/IdyTestimonial';
import InviteFriends from '@landing_pages/idy/InviteFriends';
import TamilResearch from '@landing_pages/inner-engineering-online/TamilResearch';
import ThreeColumnGroup from '@landing_pages/inner-engineering-online/ThreeColumnGroup';
import WideSadhguruBox from '@landing_pages/ishanga7/WideSadhguruBox';
import OnlineSatsangBanner from '@landing_pages/online-satsang/OnlineSatsangBanner';
import AboutSadhguru from '@sections/AboutSadhguru';
import ArtworkText3GroupColumn from '@sections/ArtworkText3GroupColumn';
import SeatingRegSection from '@sections/SeatingRegSection';
import SocialMediaIconsLatest from '@sections/SocialMediaIconsLatest';
import AllCenters from '@sections/centers/AllCenters';
import MainCentersDefaultImage from '@sections/centers/MainCentersDefaultImage';
import ProcessesAndOfferings from '@sections/centers/ProcessesAndOfferings';
import DonationPageOfflineDonationUi from '@sections/donation/DonationPageOfflineDonationUi';
import DonationPageTopBanner from '@sections/donation/DonationPageTopBanner';
import EducationalInitiatives from '@sections/educational-initiatives/EducationalInitiatives';
import ExploringSpiritualAmericaPoem from '@sections/events/ExploringSpiritualAmericaPoem';
import LeftZigzag from '@sections/events/LeftZigzag';
import RightZigzag from '@sections/events/RightZigzag';
import IshaOutreach from '@sections/isha-outreach/IshaOutreach';
import AppButton from '@sections/others/AppButton';
import BuyNow from '@sections/others/BuyNow';
import MobileApp from '@sections/others/MobileApp';
import FeaturesPBK from '@sections/pbkonline/FeaturesPBK';
import HighlightsPBK from '@sections/pbkonline/HighlightsPBK';
import ProgDetailPBK from '@sections/pbkonline/ProgDetailsPBK';
import SupportEmail from '@sections/pbkonline/SupportEmail';
import SocialSection from '@sections/project-samskriti/SocialSection';
import QuoteSection from '@sections/volunteer/QuoteSection';
import VariousVolunteerSection from '@sections/volunteer/VariousVolunteerSection';
import FindTeacher from '@sections/yoga-meditation/FindTeacher';
import TwoColumnHathaTeachersBlock from '@sections/yoga-meditation/TwoColumnHathaTeachersBlock';
import ProgDetailYs from '@sections/yoga-sandhya/ProgDetailYs';
import { IsoApiConstants } from '../../../constants';

/**
 * Renders Render Simple Button Component
 *
 * @param {object} sectionContent - Data
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @returns {ReactElement} Render Simple Button Component
 */

const RenderSimpleButton = ({
  sectionContent,
  section,
  region,
  lang,
  pageConfig,
}) => {
  // const SimpleButton = dynamic(() => import('@components/Buttons/SimpleButton'));
  if (
    section.sectionId &&
    section.sectionId === IsoApiConstants.sectionId.healthButtonLoginPopup
  ) {
    return (
      <SimpleButton
        style={sectionContent}
        bType={section.sectionId}
        region={region}
        lang={lang}
        pageName={pageConfig?.pageName}
      />
    );
  } else {
    return (
      <SimpleButton
        style={sectionContent}
        region={region}
        lang={lang}
        pageConfig={pageConfig}
      />
    );
  }
};

const RenderStructuredText = ({ sectionContent, region, lang }) => {
  if (
    sectionContent.fieldId &&
    sectionContent.fieldId === IsoApiConstants.fieldId.largeBrownBulletsHealth
  ) {
    // const LargeBrownBullets = dynamic(() => import('@components/UI/Health/LargeBrownBullets'));
    return <LargeBrownBullets data={sectionContent.body} />;
  } else {
    return (
      <MidContainer>
        <Box id={sectionContent?.sectionId} className="event-text">
          <ContentArea
            mainContent={sectionContent.body}
            tAlign={sectionContent.style}
            region={region}
            lang={lang}
          />
        </Box>
      </MidContainer>
    );
  }
};

const RenderFAQs = ({ pageConfig, section, region, lang }) => {
  if (
    section.sectionContent &&
    section.sectionContent.length > 0 &&
    section.sectionContent[1]?._modelApiKey ===
    IsoApiConstants.sectionId.faqSectionLinkedContent
  ) {
    // const FaqLinkedContent = dynamic(() => import('@components/Accordions/FaqLinkedContent'));
    return (
      <MidContainer>
        <Box display="flex">
          <FaqLinkedContent
            mainSection={section}
            faqSection={section.sectionContent[1]?.faqs[0].faqs}
            region={region}
            lang={lang}
          />
        </Box>
      </MidContainer>
    );
  } else if (
    section.sectionId === IsoApiConstants.sectionId.frequentlyAskedQuestions ||
    section.sectionId === 'faq'
  ) {
    const SectionContent = section.sectionContent;
    return (
      <MidContainer w={'768'}>
        {SectionContent.map((section, idx) => {
          section._modelApiKey = section._modelApiKey
            ? section._modelApiKey
            : 'faq_section_v2';
          return <SectionUI sectionContent={section} pageConfig={pageConfig} />;
        })}
      </MidContainer>
    );
  } else if (section.sectionId === 'sadhguru-two-column') {
    // const TwoColumn = dynamic(() => import('@components/Card/TwoColumn'));
    return <TwoColumn section={section} />;
  } else {
    // const Faq = dynamic(() => import('@components/Accordions/Faq'));
    return (
      <MidContainer>
        <Box display="flex" width="full">
          <Faq pageConfig={pageConfig} faqSection={section} region={region} lang={lang} />
        </Box>
      </MidContainer>
    );
  }
};

const RenderSections = ({
  section,
  sectionContent,
  region,
  lang,
  pType,
  isLeftMenu,
  pageName,
  csrData,
  pageBody,
  pageConfig,
  disableButton,
  FYDdate,
  currentRegion,
}) => {
  // console.log("Sec is", section)
  if (
    section.sectionId === IsoApiConstants.sectionId.faqSectionStd ||
    section.sectionId?.toLocaleLowerCase() ===
    IsoApiConstants.sectionId?.faq.toLocaleLowerCase() ||
    section.sectionId === IsoApiConstants.sectionId.frequentlyAskedQuestions ||
    section.sectionId === IsoApiConstants.sectionId.faqSection ||
    section.sectionId === 'faq'
  ) {
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <RenderFAQs section={section} region={region} lang={lang} />
      </StyleConfig>
    );
  } else if (
    section.sectionId === IsoApiConstants.sectionId.processesAndOfferings ||
    section.sectionId === IsoApiConstants.sectionId.ritualsAndOfferings
  ) {
    // const ProcessesAndOfferings = dynamic(() => import('@sections/centers/ProcessesAndOfferings'));
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <ProcessesAndOfferings section={section} region={region} lang={lang} />
      </StyleConfig>
    );
  }
  //  else if (section.sectionId === 'banner') {
  //   // const AboutSadhguru = dynamic(() => import('@sections/AboutSadhguru'));
  //   return (
  //     <StyleConfig
  //       id={section?.sectionId}
  //       key={section.sectionId}
  //       style={section?.styleConfig}
  //     >
  //       <TopBanner
  //         section={section}
  //         region={region}
  //         language={lang}
  //         isLeftMenu={isLeftMenu}
  //         pType={pType}
  //         pageName={pageName}
  //         csrData={csrData}
  //         pageBody={pageBody}
  //       />
  //     </StyleConfig>
  //   );
  // }
  else if (section.sectionId === 'idy-testimonials') {
    return (
      <IdyTestimonial
        section={section.sectionContent}
        region={region}
        language={lang}
      />
    );
  }
  else if (section.sectionId === 'idy-in-person') {
    return (
      <IdyInPerson
        sectionContent={section.sectionContent}
        region={region}
        language={lang}
      />
    );
  }
  else if (section.sectionId === 'idy-faq-section') {
    return (
      <FaqContents
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  }
  else if (section.sectionId === 'idy-register-sticky-button') {
    return <IdyRegisterStickyButton sectionContent={section.sectionContent} region={region} lang={lang} />;
  }
  else if (section.sectionId === 'about-sadhguru') {
    // const AboutSadhguru = dynamic(() => import('@sections/AboutSadhguru'));
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <AboutSadhguru section={section} region={region} lang={lang} />
      </StyleConfig>
    );
  } else if (
    section.sectionId === 'right-zigzag-0' ||
    section.sectionId === 'right-zigzag-1'
  ) {
    // const RightZigzag = dynamic(() => import('@sections/events/RightZigzag'));
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <RightZigzag section={section} region={region} lang={lang} />
      </StyleConfig>
    );
  } else if (
    section.sectionId === 'left-zigzag-1' ||
    section.sectionId === 'left-zigzag-0'
  ) {
    // const LeftZigzag = dynamic(() => import('@sections/events/LeftZigzag'));
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <LeftZigzag section={section} region={region} lang={lang} />
      </StyleConfig>
    );
  } else if (section.sectionId === 'fashion-zigzag-cards-index') {
    return false;
  } else if (section.sectionId === 'naga-testimonials') {
    return <Testimonials3Card section={section} />;
  } else if (section.sectionId === 'ps-social-section') {
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <SocialSection
          key={nanoid(5)}
          section={section}
          region={region}
          lang={lang}
        />
      </StyleConfig>
    );
  }
  // else if (section.sectionId === 'pricing') {
  //   return <TamilPricing section={section} region={region} lang={lang} />;
  // } else if (section.sectionId === 'course-structure') {
  //   return <TamilCard section={section} region={region} lang={lang} />;
  // }
  else if (
    section._modelApiKey === 'page_section_left_nav' &&
    (section.sectionId === 'course-highlights' ||
      section.sectionId === 'benefits')
  ) {
    // const ThreeColumnGroup = dynamic(() => import('@landing_pages/inner-engineering-online/ThreeColumnGroup'));
    return <ThreeColumnGroup section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'research-findings') {
    // const TamilResearch = dynamic(() => import('@landing_pages/inner-engineering-online/TamilResearch'));
    return <TamilResearch section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'benefits') {
    // const ArtworkText3GroupColumn = dynamic(() => import('@sections/ArtworkText3GroupColumn'));
    return (
      <ArtworkText3GroupColumn
        sectionContent={section}
        region={region}
        lang={lang}
      />
    );
  } else if (section.sectionId === IsoApiConstants.sectionId.architecture) {
    const Architecture = dynamic(() =>
      import('@sections/centers/Architecture')
    );
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <Architecture
          sectionContent={section.sectionContent}
          region={region}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (section.sectionId === IsoApiConstants.sectionId.globalCenters) {
    // const GlobalCenter = dynamic(() => import('@components/UI/Center/GlobalCenter'));
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <GlobalCenter
          sectionContent={section.sectionContent}
          region={region}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (section.sectionId === 'main-centers-default-image') {
    // const MainCentersDefaultImage = dynamic(() => import('@sections/centers/MainCentersDefaultImage'));
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <MainCentersDefaultImage sectionContent={section.sectionContent} />
      </StyleConfig>
    );
  } else if (
    section.sectionId === 'view-all-centers' ||
    section.sectionId === 'view-all-center-section' ||
    section.sectionId === 'view-all-centers-section'
  ) {
    // const AllCenters = dynamic(() => import('@sections/centers/AllCenters'));
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <AllCenters section={section} />
      </StyleConfig>
    );
  } else if (
    section.sectionId === 'teachers-in-your-area' ||
    section.sectionId === 'Find a Teacher'
  ) {
    // const FindTeacher = dynamic(() => import('@sections/yoga-meditation/FindTeacher'));
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <FindTeacher
          sectionContent={section.sectionContent}
          region={region}
          lang={lang}
          isLeftMenu={isLeftMenu}
        />
      </StyleConfig>
    );
  } else if (section.sectionId === 'online-satsang-banner') {
    // /events/monthly/online-satsang
    // const OnlineSatsangBanner = dynamic(() => import('@landing_pages/online-satsang/OnlineSatsangBanner'));
    return (
      // <StyleConfig key={section.sectionId} style={section?.styleConfig}>
      <OnlineSatsangBanner
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
      // </StyleConfig>
    );
  } else if (section.sectionId === 'wide-sadhguru-box') {
    // ishanga 7 landing
    // const WideSadhguruBox = dynamic(() => import('@landing_pages/ishanga7/WideSadhguruBox'));
    return (
      // <StyleConfig key={section.sectionId} style={section?.styleConfig}>
      <WideSadhguruBox
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />

      // </StyleConfig>
    );
  } else if (section.sectionId === 'exploring-spiritual-america-poem') {
    // events/special-events/exploring-spiritual-america
    // const ExploringSpiritualAmericaPoem = dynamic(() => import('@sections/events/ExploringSpiritualAmericaPoem'));
    return (
      // <StyleConfig key={section.sectionId} style={section?.styleConfig}>
      <ExploringSpiritualAmericaPoem
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
      // </StyleConfig>
    );
  } else if (section.sectionId === '2-column-hatha-teachers-block') {
    // events/special-events/exploring-spiritual-america
    // const TwoColumnHathaTeachersBlock = dynamic(() => import('@sections/yoga-meditation/TwoColumnHathaTeachersBlock'));
    return (
      // <StyleConfig key={section.sectionId} style={section?.styleConfig}>
      <TwoColumnHathaTeachersBlock
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
      // </StyleConfig>
    );
  } else if (section.sectionId === '2-column-living-isha-block') {
    // center/isha-institute-inner-sciences/living-isha
    const LivingIsha = dynamic(() => import('@sections/centers/LivingIsha'));
    return <LivingIsha section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'upcoming-programs-hatha-backend') {
    // yoga-meditation/yoga-program-for-beginners/hatha-yoga/courses-by-certified-teachers
    const UpcomingProgramsV2 = dynamic(() =>
      import('@components/UI/UpcomingProgramsV2')
    );
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <UpcomingProgramsV2
          sectionContent={section?.sectionContent[0]}
          region={region}
          language={lang}
          isLeftMenu={isLeftMenu}
          apiType="macro"
        />
      </StyleConfig>
    );
  } else if (section.sectionId === 'marketo-form-upa-yoga-ishanga') {
    // yoga-meditation/yoga-program-for-beginners/hatha-yoga/courses-by-certified-teachers
    const MarketoFormSection = dynamic(() =>
      import('@sections/yoga-meditation/MarketoForm')
    );
    return (
      <MarketoFormSection
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
        pType={pType}
        pageName={pageName}
      />
    );
  } else if (section.sectionId === 'donation-page-offline-donation-ui') {
    // const DonationPageOfflineDonationUi = dynamic(() => import('@sections/donation/DonationPageOfflineDonationUi'));
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <DonationPageOfflineDonationUi
          section={section}
          region={region}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (section.sectionId === 'placeholder-donate-offline-form') {
    const PlaceholderDonateOfflineForm = dynamic(() =>
      import('@sections/donation/PlaceholderDonateOfflineForm')
    );
    return (
      <PlaceholderDonateOfflineForm
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
        pType={pType}
        pageName={pageName}
      />
    );
  } else if (section.sectionId === 'social-media-icons-latest') {
    // const SocialMediaIconsLatest = dynamic(() => import('@sections/SocialMediaIconsLatest'));
    return (
      <SocialMediaIconsLatest
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
        pType={pType}
        pageName={pageName}
      />
    );
  } else if (section.sectionId === 'us_donation_employer_match') {
    const UsDonationEmployerMatch = dynamic(() =>
      import('@sections/donation/UsDonationEmployerMatch')
    );
    return (
      <UsDonationEmployerMatch
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
        pType={pType}
        pageName={pageName}
        shouldLoad
      />
    );
  } else if (section.sectionId === 'donation-page-top-banner') {
    // const DonationPageTopBanner = dynamic(() => import('@sections/donation/DonationPageTopBanner'));
    return (
      <DonationPageTopBanner
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
        pType={pType}
        pageName={pageName}
      />
    );
  } else if (section.sectionId === 'seating-reg-section') {
    // const SeatingRegSection = dynamic(() => import('@sections/SeatingRegSection'));
    return (
      <SeatingRegSection
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
        pType={pType}
        pageName={pageName}
      />
    );
  } else if (section.sectionId === 'why-take-a-break') {
    // const SPTakeBreak = dynamic(() => import('@landing_pages/Sadhanapada/SPTakeBreak'));
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
      >
        <SPTakeBreak
          section={section}
          region={region}
          lang={lang}
          isLeftMenu={isLeftMenu}
          pType={pType}
          pageName={pageName}
        />
      </StyleConfig>
    );
  } else if (section.sectionId === 'sp-program-details') {
    // const SPProgramDetails = dynamic(() => import('@landing_pages/Sadhanapada/SPProgramDetails'));
    return (
      <SPProgramDetails
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
    );
  } else if (section.sectionId === 'path-to-sadhanapada') {
    // const SPPath = dynamic(() => import('@landing_pages/Sadhanapada/SPPath'));
    return (
      <SPPath
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
    );
  }
  // else if (section.sectionId === 'participant_stories') {
  //   return (
  //     <SPAlumni
  //       section={section}
  //       region={region}
  //       lang={lang}
  //       isLeftMenu={isLeftMenu}
  //     />
  //   );
  // }
  else if (section.sectionId === 'sp-faq') {
    // const SPFaq = dynamic(() => import('@landing_pages/Sadhanapada/SPFaq'));
    return (
      <SPFaq
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
    );
  } else if (section.sectionId === 'participant_stories') {
    // const SPAlumni = dynamic(() => import('@landing_pages/Sadhanapada/SPAlumni'));
    return (
      <SPAlumni
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
    );
  } else if (section.sectionId === 'why-sadhanapada') {
    // const WhySP = dynamic(() => import('@landing_pages/Sadhanapada/WhySP'));
    return (
      <WhySP
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
    );
  } else if (section.sectionId === 'join-webinar') {
    // const SPWebinar = dynamic(() => import('@landing_pages/Sadhanapada/SPWebinar'));
    return (
      <SPWebinar
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
    );
  } else if (section.sectionId === 'apply-now') {
    // const SPApply = dynamic(() => import('@landing_pages/Sadhanapada/SPApply'));
    return (
      <SPApply
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
    );
  } else if (section.sectionId === 'stay-in-touch-mobile-app-section') {
    // const MobileApp = dynamic(() => import('@sections/others/MobileApp'));
    return (
      <MobileApp
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
    );
    // http://localhost:3001/in/ta/maranambook
  } else if (section.sectionId === 'buy-now-section') {
    // const BuyNow = dynamic(() => import('@sections/others/BuyNow'));
    return (
      <BuyNow
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
    );
    // http://localhost:3001/in/en/stay-in-touch-with-sadhguru-and-isha
  } else if (section.sectionId === 'isha-outreach') {
    // http://localhost:3001/in/en/about-isha-foundation
    // const IshaOutreach = dynamic(() => import('@sections/isha-outreach/IshaOutreach'));
    return <IshaOutreach section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'educational-initiatives') {
    // const EducationalInitiatives = dynamic(() => import('@sections/educational-initiatives/EducationalInitiatives'));
    return (
      <EducationalInitiatives section={section} region={region} lang={lang} />
    );
  } else if (section.sectionId === 'sadhguru-app-button') {
    // const AppButton = dynamic(() => import('@sections/others/AppButton'));
    return (
      <AppButton
        section={section}
        region={region}
        lang={lang}
        isLeftMenu={isLeftMenu}
      />
    );
  } else if (section.sectionId === 'donation-thankyou') {
    const DonationThankYou = dynamic(() =>
      import('@sections/donation/DonationThankYou')
    );
    return <DonationThankYou section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'accordion-faq') {
    // const AccordionFaq = dynamic(() => import('@components/Accordions/AccordionFaq'));
    return <AccordionFaq section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'celebrate-the-weaves') {
    const CelebrateWeaves = dynamic(() =>
      import('@sections/outreach/CelebrateWeaves')
    );
    return <CelebrateWeaves region={region} lang={lang} section={section} />;
  } else if (section.sectionId === 'author-vd-section') {
    // /in/en/author
    const AuthorVdSection = dynamic(() =>
      import('@sections/others/AuthorVdSection')
    );
    return <AuthorVdSection region={region} lang={lang} section={section} />;
  } else if (section.sectionId === 'ishanga-stickybar') {
    // ishanga 7
    // let condition = true;
    // const IshangaStickyBar = condition && import('@sections/ishanga/IshangaStickyBar')
    const IshangaStickyBar = dynamic(() =>
      import('@sections/ishanga/IshangaStickyBar')
    );
    return (
      <IshangaStickyBar
        region={region}
        lang={lang}
        section={section}
        pageBody={pageBody}
      />
    );
  } else if (section.sectionId === 'ishanga-poem-section') {
    // ishanga 7
    const IshangaPoemSection = dynamic(() =>
      import('@sections/ishanga/IshangaPoemSection')
    );
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        pT="0"
        mT="90px"
        mB="30px"
      >
        <IshangaPoemSection region={region} lang={lang} section={section} />
      </StyleConfig>
    );
  } else if (section.sectionId === 'bog-register') {
    // BOG
    const BogRegister = dynamic(() => import('@sections/ishanga/BogRegister'));
    return <BogRegister region={region} lang={lang} section={section} />;
  } else if (section.sectionId === 'bog-header') {
    // BOG
    const BogHeader = dynamic(() => import('@sections/ishanga/BogHeader'));
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
        pb="20px"
        pt="30px"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        <BogHeader region={region} lang={lang} section={section} />
      </StyleConfig>
    );
  } else if (section.sectionId === 'fyd-register-sticky-button') {
    const FYDRegisterStickyButton = dynamic(() =>
      import('@components/Buttons/FYDRegisterStickyButton')
    );
    return (
      <FYDRegisterStickyButton
        disableButton={disableButton}
        FYDdate={FYDdate}
        lang={lang}
        region={region}
        sectionContent={section.sectionContent}
        pageName={pageName}
        csrData={csrData}
      />
    );
  } else if (section.sectionId === 'quote-section' || section.sectionId === 'idy-quote') {
    // Volunteer
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
        pb="10px"
        pt="10px"
        mb={{ base: '25px', md: '40px' }}
        mt="-5px"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        <QuoteSection region={region} lang={lang} section={section} />
      </StyleConfig>
    );
  } else if (
    section.sectionId === 'various-volunteer-section' ||
    section.sectionId === 'other-volunteer-section' || section.sectionId === 'ssb-volunteer-section'
  ) {
    // Volunteer
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
        pb="0px"
        pt="0px"
        backgroundRepeat="no-repeat"
        backgroundSize={{ base: '500px', sm: 'cover', md: 'cover' }}
      >
        <VariousVolunteerSection
          region={region}
          lang={lang}
          section={section}
        />
      </StyleConfig>
    );
  } else if (section.sectionId === 'event-program-details-section') {
    const ProgramCategory = dynamic(() =>
      import('@components/Layout/ProgramCategory')
    );
    return <ProgramCategory region={region} lang={lang} section={section} />;
  } else if (section.sectionId === 'event-programcard-section') {
    const EventProgramCard = dynamic(() =>
      import('@components/Layout/EventProgramCard')
    );
    return <EventProgramCard region={region} lang={lang} section={section} />;
  } else if (section.sectionId === 'mmm-eventbanner') {
    const TopBannerEventMmm = dynamic(() =>
      import('@components/HeaderBanner/TopBannerEventMMM')
    );
    return (
      <Box minH="500px" bgColor={section?.styleConfig?.bgColour?.hex || null}>
        <StyleConfig
          id={section?.sectionId}
          key={section.sectionId}
          style={section?.styleConfig}
          mb="0"
          pT="0"
          pB="0"
        >
          <TopBannerEventMmm
            section={section}
            region={region}
            language={lang}
          />
        </StyleConfig>
      </Box>
    );
  } else if (section.sectionId === 'event-program-tab-section') {
    const ProgramTabSection = dynamic(() =>
      import('@components/Layout/ProgramTabSection')
    );
    return (
      <ProgramTabSection section={section} region={region} language={lang} />
    );
  } else if (section.sectionId === 'how-to-get-there-ys') {
    const HowtoGetThere = dynamic(() =>
      import('@components/UI/LandingPages/Howtogetthere')
    );
    return <HowtoGetThere section={section} region={region} language={lang} />;
  } else if (section.sectionId === 'how-to-reach') {
    const HowtoReach = dynamic(() =>
      import('@components/UI/Events/mmm/HowtoReach')
    );
    return <HowtoReach section={section} region={region} language={lang} />;
  } else if (section.sectionId === 'mmmgeneral-seating') {
    const GeneralSeating = dynamic(() =>
      import('@components/UI/Events/mmm/GeneralSeating')
    );
    return (
      <GeneralSeating
        region={region}
        lang={lang}
        section={section}
        csrData={csrData}
      />
    );
  } else if (section.sectionId === 'event-programcategory') {
    const ProgramCategory = dynamic(() =>
      import('@components/UI/Events/EventProgramCategory')
    );
    return (
      <ProgramCategory
        region={region}
        lang={lang}
        section={section}
        csrData={csrData}
      />
    );
  } else if (section.sectionId === 'early-bird-block') {
    const EarlyBird = dynamic(() =>
      import('@components/UI/Events/mmm/EarlyBird')
    );
    return <EarlyBird section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'sadhana-detail-list') {
    const SadhanaDetailList = dynamic(() =>
      import('@components/Card/SadhanaDetailList')
    );
    return <SadhanaDetailList region={region} lang={lang} section={section} />;
  } else if (section.sectionId === 'ishanga7-desc-popup') {
    const PopupWindow = dynamic(() =>
      import('@components/UI/Events/PopupWindow')
    );
    return <PopupWindow region={region} lang={lang} section={section} />;
  } else if (section.sectionId === 'faq-plusminus') {
    const FaqPlusMinus = dynamic(() =>
      import('@components/Accordions/FaqPlusMinus')
    );
    return <FaqPlusMinus section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'contact-us-mmm') {
    const ContactMMM = dynamic(() => import('@landing_pages/MMM/Contactus'));
    return <ContactMMM section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'top-banner-ys') {
    // Yoga Sandhya page
    return (
      <TopBannerYs
        section={section}
        region={region}
        lang={lang}
        csrData={csrData}
        currentRegion={currentRegion}
      />
    );
  } else if (section.sectionId === 'benefits-ys') {
    // Yoga Sandhya page
    return <BenefitsGroup section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'program-details') {
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
        section={section}
        pt="0"
      >
        <ProgDetailYs section={section} region={region} lang={lang} />
      </StyleConfig>
    );
  } else if (section.sectionId === 'pbk-program-details') {
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
        section={section}
        pt="0"
      >
        <ProgDetailPBK
          section={section}
          region={region}
          lang={lang}
          csrData={csrData}
          currentRegion={currentRegion}
        />
      </StyleConfig>
    );
  } else if (section.sectionId === 'support-email') {
    return (
      <SupportEmail
        section={section}
        region={region}
        lang={lang}
        csrData={csrData}
      />
    );
  } else if (section.sectionId === 'adiyogi-community-declaration') {
    return (
      <CommunityDeclaration
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  }

  else if (section.sectionId === 'content-for-us1') {
    return <HighlightsPBK section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'content-for-us2') {
    return <FeaturesPBK section={section} region={region} lang={lang} />;
  } else if (section.sectionId === 'challenge-form') {
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
        section={section}
        pt="0"
      >
        <ChallengeForm
          sectionContent={section.sectionContent}
          region={region}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (section.sectionId === 'invite-friends') {
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
        section={section}
        pt="0"
      >
        <InviteFriends
          sectionContent={section.sectionContent}
          region={region}
          lang={lang}
        />
      </StyleConfig>
    );
  } else if (section.sectionId === 'idy-benefits') {
    return (
      <IdyBenefits
        sectionContent={section.sectionContent}
        region={region}
        lang={lang}
      />
    );
  } else if (section.sectionId === 'idy-imp-msg') {
    return (
      <IdyImportantMessage
        sectionContent={section.sectionContent[0]}
        region={region}
        lang={lang}
      />
    );
  } else if (section.sectionId === 'sahabhagi-steps-involved') {
    const SahabhagiSteps = dynamic(() =>
      import('@components/Card/SahabhagiStepsInvolved')
    );
    return (
      <StyleConfig
        id={section?.sectionId}
        key={section.sectionId}
        style={section?.styleConfig}
        section={section}
        pt="0"
      >
        <SahabhagiSteps
          sectionContent={section.sectionContent}
          region={region}
          lang={lang}
        />
      </StyleConfig>
    );
  } else {
    return (
      <Box as="div" display="block" placeContent="center">
        <StyleConfig
          id={section?.sectionId}
          key={section.sectionId}
          style={section?.styleConfig}
          section={section}
        >
          {section?.sectionContent?.length > 0
            ? section?.sectionContent?.map((secCon, i) => {
              return (
                <SectionUI
                  key={i}
                  sectionContent={secCon}
                  section={section}
                  region={region}
                  language={lang}
                  isLeftMenu={isLeftMenu}
                  pType={pType}
                  pageName={pageName}
                  csrData={csrData}
                  pageBody={pageBody}
                  FYDdate={FYDdate}
                  pageConfig={pageConfig}
                  disableButton={disableButton}
                />
              );
            })
            : null}
        </StyleConfig>
      </Box>
    );
  }
};

export { RenderFAQs, RenderSections, RenderSimpleButton, RenderStructuredText };

